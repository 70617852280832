import React,{useEffect, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faUnlockAlt, faLock } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form,Alert, Card, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { Routes } from "../../routes";
import { useForm } from "react-hook-form";
import { PasswordCheck, passRules} from "../../framework/utilities/utilities";

const ResetPassword = () => {
    const { token } = useParams();
    //console.log(token );
    const { register, handleSubmit, setValue  } = useForm();
    
    const [successMessage,setSuccessMessage] = useState('');
    const [errMessage,setErrorMessage] = useState('');    
    const [userEmail,setUserEmail] = useState('');    
    const [showForm,setShowForm] = useState(true);    
    const [showTermsCheck,setShowTermsCheck] = useState(0);    
    
    const API_URL = process.env.REACT_APP_API_URL;
    
    setValue("password","");
    setValue("confirmPassword","");
    setValue("optedIn","");

    useEffect(()=>{
        
        const API_URL = process.env.REACT_APP_API_URL;
        fetch(API_URL + '/v1/web/user/password/find/'+token, {
            method: 'GET'                      
        })
        .then(response => response.json())
        .then(jsondata => {
            console.log(jsondata); 
            if (jsondata.message === "success"){
                if (jsondata.email) setUserEmail(jsondata.email);
                if (jsondata.opted_in === 'N') setShowTermsCheck(1);
            }else{
                setErrorMessage(jsondata.message);       
                setShowForm(false);   
            }      
           
        }).catch((err) => {
            console.log("Login error: " + err);
            setErrorMessage("Please check the URL.");
        });
    },[token]);
    


    const requestPass = (token,postedData) => {
        setErrorMessage('');
        let formData = new FormData();
        formData.append("email", userEmail);
        formData.append("token", token);
        formData.append("password", postedData.password);
        formData.append("password_confirmation", postedData.confirmPassword);
        formData.append("optedIn", postedData.optedIn);
        
        fetch(API_URL + '/v1/web/user/password/reset', {
            method: 'POST',           
            body: formData
        })
        .then(response => response.json())
        .then(jsondata => {            
            if (jsondata.id){
                setSuccessMessage("Password updated successfully. Click on the signin link above to login to ConflictDB.");
                setShowForm(false); 
            }else{
                setErrorMessage("Password could not be updated.");                                      
            }
           
            
        }).catch((err) => {
            console.log("Login error: " + err);
            setErrorMessage("Password could not be set.");
            
        });
        
        
    }


    const onSubmit = postedData => {
        setErrorMessage('');
        
        // lets check if password and confirm password is same
        if (postedData.password !== postedData.confirmPassword){
            setErrorMessage("Password and confirm password should match."); 
             
            return false;
        }else if(!PasswordCheck(postedData.password))
        {
            setErrorMessage(passRules());
            return false;
        }
        else if(postedData.password !== postedData.confirmPassword)
        {
            setErrorMessage('Password and confirm password do not match');
            return false;
            
        }

        //console.log(postedData); return false;
       /* if (postedData.optedIn === ""){
            setErrorMessage('You have not accepted the opt-in check');
            return false;
        }*/
        
        fetch(API_URL + '/v1/web/user/password/find/'+token, {
            method: 'GET'                      
        })
        .then(response => response.json())
        .then(jsondata => {
            if (jsondata.message === "success"){
                requestPass(token,postedData);
            }else{
                setErrorMessage("Please check the URL.");
            }     
            
        }).catch((err) => {
            console.log("Login error: " + err);
            setErrorMessage("Please check the URL.");
        });
            
    
    }

    

            
    return (
        <main>
            <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
                <Container className="p-6">
                    <Row className="justify-content-center">
                        <p className="text-center">
                            <Card.Link as={Link} to={Routes.Signin.path} className="text-gray-700">
                                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to sign in
                            </Card.Link>
                        </p>
                        <Col xs={12} className="d-flex align-items-center justify-content-center">
                            <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                <h3 className="mb-4">Set your password</h3>

                                {successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}            
                                {errMessage && <Alert variant="danger">{errMessage}</Alert>}
                                {showForm && <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Control  type="hidden" value={userEmail} /> 
                                    <Row>
                                        <Col md={12} className="mb-3">
                                            <Form.Group id="password" className="mb-4">
                                                <Form.Label htmlFor="password" className="required">Your password</Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Text>
                                                        <FontAwesomeIcon icon={faUnlockAlt} />
                                                    </InputGroup.Text>
                                                    <Form.Control required autoFocus {...register("password")} type="password" placeholder="Password" />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="mb-3">
                                            <Form.Group id="confirmPassword" className="mb-4">
                                                <Form.Label htmlFor="confirmPassword" className="required">Confirm password</Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Text>
                                                        <FontAwesomeIcon icon={faUnlockAlt} />
                                                    </InputGroup.Text>
                                                    <Form.Control required {...register("confirmPassword")} type="password" placeholder="Confirm Password" />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} className="mb-3 password-rules-box"  >{passRules()}</Col>    
                                    </Row>
                                    {(showTermsCheck === 1) && 
                                        <Row>
                                            <Col>
                                                <Form.Group id="inst">
                                                    <div className="form-check">
                                                        <Form.Label className="form-check-label required" htmlFor="opt_in">I agree to become a member of ConfictDB. I consent to the use of cookies and receipt of automated mails.</Form.Label>
                                                        <input type="checkbox" name="opt_in" className="form-check-input" value="Y"
                                                        onChange={(e)=>{
                                                            if (e.target.checked){
                                                                setValue('optedIn','Y')
                                                            }else{
                                                                setValue('optedIn','N')
                                                            }
                                                        }}
                                                        required  />
                                                    </div>
                                                </Form.Group> 
                                            </Col>
                                        </Row>
                                    }
                                    
                                    <Button variant="primary" type="submit" className="animate-hover w-100">
                                        <FontAwesomeIcon icon={faLock} className="animate-left-3 me-3 ms-2" />
                                        Set password
                                    </Button>
                                </Form>}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
};

export default ResetPassword;
