import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { Routes } from "../routes";
import { Container, Row, Col } from '@themesberg/react-bootstrap';

// pages
import DashboardOverview from "./dashboard/DashboardOverview";
import Signin from "./userAdmission/Signin";
import GuestForgotPassword from "./userAdmission/ForgotPassword";
import GuestResetPassword from "./userAdmission/ResetPassword";
import Lock from "./userAdmission/Lock";
import NotFoundPage from "./userAdmission/NotFound";
import ServerError from "./userAdmission/ServerError";
import Registration from "./userAdmission/Registration";

// components
import Navbar from "../project/components/Navbar";
import NavbarLoggedOut from "../project/components/NavbarLoggedOut";
import Footer from "../project/components/Footer";
import Preloader from "../framework/components/Preloader";

//Password Reset
import UserEdit from "./users/Edit";
import PasswordReset from "./passwordReset/Form";
import ConflictList from "./relations/Conflicts";
import RelationListAll from "./relations/ListAll";
import RelationAdd from "./relations/Add";
import RelationEdit from "./relations/Edit";
import RelationPendingEmploymentView from "./relations/RelationPendingEmploymentEdit";
import RelationValidationNewUser from "./relations/ValidationNewUser";
import HelpPage from "./HelpPage";
import ContactUsPage from "./ContactUsPage";

import { RefreshToken } from "../framework/utilities/utilities";



const RouteWithLoader = ({ component: Component, ...rest }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setLoaded(true), 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Route {...rest} render={props => (<> <Preloader show={loaded ? false : true} /> <Component {...props} /> </>)} />
    );
};

const RouteWithNavbar = ({ component: Component, ...rest }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setLoaded(true), 1000);
        return () => clearTimeout(timer);
    }, []);

    const localStorageIsSettingsVisible = () => {
        return localStorage.getItem('settingsVisible') === 'false' ? false : true
    }

    const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

    const toggleSettings = () => {
        setShowSettings(!showSettings);
        localStorage.setItem('settingsVisible', !showSettings);
    }

    return (
        <Route {...rest} render={props => (
            <>
                <Preloader show={loaded ? false : true} />
                

                <main className="content d-flex flex-column h-100">
                    <div className="flex-shrink-0">
                        <Navbar />
                        <Container><Component {...props} /></Container>
                    </div>
                    <div className="mt-auto">
                        <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
                    </div>
                </main>
            </>
        )}
        />
    );
};

const RouteWithNavLoggedOut = ({ component: Component, ...rest }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setLoaded(true), 1000);
        return () => clearTimeout(timer);
    }, []);

    const localStorageIsSettingsVisible = () => {
        return localStorage.getItem('settingsVisible') === 'false' ? false : true
    }

    const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

    const toggleSettings = () => {
        setShowSettings(!showSettings);
        localStorage.setItem('settingsVisible', !showSettings);
    }

    return (
        <Route {...rest} render={props => (
            <>
                <Preloader show={loaded ? false : true} />
                

                <main className="content d-flex flex-column h-100">
                    <div className="flex-shrink-0">
                        <NavbarLoggedOut />
                        <Container><Component {...props} /></Container>
                    </div>
                    <div className="mt-auto">
                        <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
                    </div>
                </main>
            </>
        )}
        />
    );
};

const HomePage = () => {

    // lets check if the token is still valid
    const API_URL = process.env.REACT_APP_API_URL;
    const [showLogoutmessage, setShowLogoutmessage] = useState(false);
   
   
    if (localStorage.getItem("token")){
        fetch(API_URL + '/v1/web/user/profile', {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
        })
        .then(response => { return response.json(); })
        .then(jsondata => { 
           // console.log("inside homepage.js",jsondata);
            if (jsondata !== 'Unauthorized')  localStorage.setItem("profile", JSON.stringify(jsondata));
            setShowLogoutmessage(false);
        }).catch((err) => {
            //console.log("Login error: " + err);
           // console.log("Need to logout");
            // we need to clean up the local storage
            localStorage.clear();
            setShowLogoutmessage(true);
        });
    }
    

   
   

    const history = useHistory();

    //console.log(localStorage.getItem("authResponse"));

    //console.log(localStorage.getItem("expires_in"));
    const refreshToken = localStorage.getItem("expires_in");

    //useEffect(() => {
    if (refreshToken) {
        RefreshToken(refreshToken);
    }
    //}, []);


    let sessionUser = JSON.parse(localStorage.getItem("profile"));
   // console.log(sessionUser);
    if ((sessionUser) && (!sessionUser.FName)){
        localStorage.clear();
    }
    //console.log("sessionUser profile",sessionUser.FName);


    const [isLoggedIn] = useState(localStorage.getItem("token"));
    const [forSignedOutLinks] = useState(isLoggedIn === "undefined" || isLoggedIn === null ? true : false);

    // check if pws is set
    

    if (forSignedOutLinks) {
        if ( (history.location.pathname.includes("reset-password/")) || (history.location.pathname.includes("forgot-password/")) || (history.location.pathname.includes("/relations/validate/")) || (history.location.pathname.includes("/help")) ) {
            // do nothing
        }else{
            history.push("/sign-in");
        }
        
    } else {

        //if ((sessionUser) && (sessionUser.PwdSet === 'N')){
            //history.push("/user/password-reset/");
        //}

       // history.push("/");
    }

    return (
        <>
            {/* Logged out links */}
            {forSignedOutLinks &&
                <>
                    <Switch>
                        <RouteWithLoader exact path={Routes.DashboardOverview.path} component={Signin} />
                        <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
                        <RouteWithLoader exact path={Routes.GuestForgotPassword.path} component={GuestForgotPassword} />
                        <RouteWithLoader exact path={Routes.GuestResetPassword.path} component={GuestResetPassword} />
                        <RouteWithNavLoggedOut exact path={Routes.Registration.path} component={Registration} />
                        <RouteWithNavLoggedOut exact path={Routes.RegistrationWithToken.path} component={Registration} />
                        <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
                        <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />
                        <RouteWithNavLoggedOut exact path={Routes.Relations.validationNewUser} component={RelationValidationNewUser} />
                        <RouteWithNavLoggedOut exact path={Routes.HelpPage} component={HelpPage} />
                        <Redirect to={Routes.NotFound.path} />
                    </Switch>
                </>
            }

            {/* logged in links */}
            {!forSignedOutLinks &&
                <>
                    { showLogoutmessage && <Container><Row><Col className="text-danger text-center">Something is not right with current session. Please refresh page and try again.<br/> If you still experience issues logout and login again.</Col></Row></Container>}
                    <Switch>
                        <RouteWithNavLoggedOut exact path={Routes.Relations.validationNewUser} component={RelationValidationNewUser} />
                        
                        <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
                        <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
                        <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />
                        <Redirect exact path={Routes.Signin.path} to={Routes.DashboardOverview.path} />

                        {/* pages */}
                        <RouteWithNavbar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
                        <RouteWithNavbar exact path={Routes.Users.editpath} component={UserEdit} />
                        <RouteWithNavbar exact path={Routes.PasswordReset.path} component={PasswordReset} />
                        <RouteWithNavbar exact path={Routes.Relations.view} component={RelationEdit} />
                        <RouteWithNavbar exact path={Routes.Relations.pendingEmploymentView} component={RelationPendingEmploymentView} />
                        <RouteWithNavbar exact path={Routes.Relations.addnew} component={RelationAdd} />
                        <RouteWithNavbar exact path={Routes.Relations.activeConflicts} component={ConflictList} />
                        <RouteWithNavbar exact path={Routes.Relations.listBasedOnRules} component={ConflictList} />
                        <RouteWithNavbar exact path={Routes.Relations.pendingList} component={RelationListAll} />
                        <RouteWithNavbar exact path={Routes.Relations.listall} component={RelationListAll} />
                        <RouteWithNavbar exact path={Routes.HelpPage} component={HelpPage} />
                        <RouteWithNavbar exact path={Routes.FAQPage} component={HelpPage} />
                        <RouteWithNavbar exact path={Routes.ContactUsPage} component={ContactUsPage} />
                        
                        <Redirect to={Routes.Signin.path} />
                    </Switch>
                </>
            }
        </>
    )
}

export default HomePage;
