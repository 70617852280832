import React,{useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup, Alert} from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Routes } from "../../routes";

const ForgotPassword = (props) => {
    const { register, handleSubmit, setValue  } = useForm();
    const API_URL = process.env.REACT_APP_API_URL;
    const [successMessage,setSuccessMessage] = useState('');
    const [errMessage,setErrorMessage] = useState('');
    
    setValue("email","");

    const onSubmit = postedData => {
        
        let formData = new FormData();
        formData.append("email", postedData.email);
        
        fetch(API_URL + '/v1/web/user/password/request', {
            method: 'POST',
            body: formData  
        })
        .then(response => response.json())
        .then(jsondata => {
            if (jsondata.message) setSuccessMessage(jsondata.message); 
        }).catch((err) => {            
            setErrorMessage("Email could not be sent.");
        });
    
    }

    return (
        <main>
            <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center">
                <Container>
                
                    <Row className="justify-content-center">
                        <p className="text-center">
                            <Card.Link as={Link} to={Routes.Signin.path} className="text-gray-700">
                                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to sign in
                            </Card.Link>
                        </p>
                        <Col xs={12} className="d-flex align-items-center justify-content-center">
                            <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                <h3>Forgot your password?</h3>
                                <p className="mb-4">Don't fret! Just type in your email and we will send you a code to reset your password!</p>
                                {successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}            
                                {errMessage && <Alert variant="danger">{errMessage}</Alert>}
                                
                                <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}> 
                                    
                                    <Row>
                                        <Col md={12} className="mb-3">
                                            <Form.Group id="email" className="mb-4">
                                                <Form.Label htmlFor="email" className="required">Your Email</Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Text>
                                                        <FontAwesomeIcon icon={faEnvelope} />
                                                    </InputGroup.Text>
                                                    <Form.Control autoFocus required {...register("email")} type="email" placeholder="example@company.com" />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Button variant="primary" type="submit" className="animate-hover w-100">
                                        <FontAwesomeIcon icon={faUnlockAlt}  className="animate-left-3 me-3 ms-2" /> 
                                        Recover password
                                    </Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
};

export default ForgotPassword;