import React, { useState, useEffect, Fragment  } from "react";
import { Card , Button,  Alert, Spinner, Row, Col, Form  } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faEye} from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
//import { Routes } from "../../routes";
import Logout from "../../framework/utilities/Logout";
import { statusPill } from "./relations";
import ExpandableBlock from "../../framework/components/ExpandableBlock/ExpandableBlock";
import { Routes } from "../../routes";

const RelationListComponent = (props) => {

    
    
    //console.log("Inside list compoennt");
    
   // const [generateList,setGenerateList] = useState(props.generateList);
    //const conflictRules = (props.conflictRules) ? props.conflictRules : null;
   
    

    const editLink = "/relations/view";
    const editLinkPendingEmployment = "/pending-relations/view";

    const [listError, setListError] = useState();
    const [listReady, setListReady] = useState(false);
    const [relationTypesData, setRelationTypesData] = useState([]);
    const [relationInstitutionData, setRelationInstitutionData] = useState([]);
    const [relationGridViewData, setRelationGridViewData] = useState([]);
    const [totalRelations, setTotalRelations] = useState(0);
    const [viewType, setViewType] = useState(1);
    
    useEffect(()=>{
        
        const tmp = [];
        const coAutherRelationTypes = [3,6,7];
        const selectedRulesPendingEmp = (props.selectedRulesPendingEmp) ? props.selectedRulesPendingEmp : null;
        const selectedRulesInst = (props.selectedRulesInst) ? props.selectedRulesInst : null;
        const selectedRules = (props.selectedRules) ? props.selectedRules : null;

        // lets fetch the relations
        const API_URL = process.env.REACT_APP_API_URL;
        let formData = new FormData();

        // we will only send the selected rules to api
        formData.append("conflictRules", JSON.stringify(selectedRules));
        formData.append("conflictRulesPendingEmp", JSON.stringify(selectedRulesPendingEmp));
        formData.append("conflictInstiRules", JSON.stringify(selectedRulesInst));
        formData.append("pageName", props.pageName);
        formData.append("filterBy", ((props.filterBy) ? props.filterBy : ''));
        
        fetch(API_URL + '/v1/web/relations/data/list', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
            body: formData
        }).then(response => response.json())
        .then(jsondata => {
            //console.log(jsondata);
            if (jsondata === 'Unauthorized'){
                Logout();
            }
            //console.log("jsondata",jsondata);
            // converting relation types json to array format
            if (jsondata.errors){
                setListError(jsondata.errors);
                setRelationInstitutionData([]);
                setTotalRelations(0);
            }else{
                setListError('');
               // console.log(jsondata);
                if (jsondata.tot_relations) setTotalRelations(jsondata.tot_relations);
                if (jsondata.institution_list){
                    setRelationInstitutionData(jsondata.institution_list);
                }else{
                    setRelationInstitutionData([]);
                }
                if (jsondata.user_conflicts) setRelationGridViewData(jsondata.user_conflicts);
                if (jsondata.relation_types){
                    Object.keys(jsondata.relation_types).forEach(keyIndex => {
                        let key = jsondata.relation_types[keyIndex].id;
                        
                        // for co-authors we need to loop one more level
                        if (coAutherRelationTypes.includes(Number(key))) {                    
                            const tmp_rels = [];  
                            Object.keys(jsondata.relations[key]).forEach(key2 => {
                                tmp_rels.push({
                                    value: key2,
                                    publication: jsondata.relations[key][key2]
                                });
                                //console.log(tmp_rels[0]['publication']['publication_years_coauthors']);
                            });
        
                            tmp.push({
                                value: key,
                                relation_type_name: jsondata.relation_types[keyIndex].name,
                                relation_type_description: jsondata.relation_types[keyIndex].description,     
                                show_start_date_label:jsondata.relation_types[keyIndex].show_start_date_label,
                                show_end_date_label:jsondata.relation_types[keyIndex].show_end_date_label,                       
                                relations: tmp_rels
                            });
        
        
                        }else{
                            tmp.push({
                                value: key,
                                relation_type_name: jsondata.relation_types[keyIndex].name,
                                relation_type_description: jsondata.relation_types[keyIndex].description,
                                relations: jsondata.relations[key],
                                show_start_date:jsondata.relation_types[keyIndex].show_start_date,
                                show_end_date:jsondata.relation_types[keyIndex].show_end_date,
                                show_start_date_label:jsondata.relation_types[keyIndex].show_start_date_label,
                                show_end_date_label:jsondata.relation_types[keyIndex].show_end_date_label,
                                start_date_format:jsondata.relation_types[keyIndex].start_date_format,
                                end_date_format:jsondata.relation_types[keyIndex].end_date_format
                            });
                        }
                        
                    }); 
                }
                //console.log(tmp);
                setRelationTypesData(tmp);
                setListReady(true);
                
            }
            
        }).catch((err) => {
            console.log("Login error: ");
            console.log(err);
            //Logout();
            
        });
    },[props]);

    const toggleView = (viewSelected) => {
        switch (viewSelected){
            case 1:
                document.getElementById('listViewContainer').style.display = 'none';
                document.getElementById('gridViewContainer').style.display = 'block';               
            break;

            case 2:
                document.getElementById('listViewContainer').style.display = 'block';
                document.getElementById('gridViewContainer').style.display = 'none';
            break;
            default:
            break;

        }
    }

    //console.log(relationTypesData);

   const pubTitleArr = [ { 3: "Paper name" , 6: "Award name" , 7: "Award name" }];
   const pubCoAuthorTitleArr = [ { 3: "Co-Authors" , 6: "Co-awardee" , 7: "Co-awardee" }];
   

    
    return (
        <>
            {!listReady && !listError &&
                <Alert variant="info" className="mt-4"><Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{marginRight:10}}
                />Please wait while we get your active relations...</Alert>
            }
            {listError &&
               <Alert className="mt-4" variant="danger">{listError}</Alert>
            }
            {listReady &&
            <Card border="light" className="mt-4 mb-4">
                { (totalRelations > 0) && <Card.Body className="pb-5">
                    
                    
                        <>
                        <Card.Title>Listing {((props.filterBy === "PENDING") ? "pending" : "")} relations</Card.Title>
                        <Card.Subtitle className="fw-normal">You have {totalRelations} {((props.filterBy === "PENDING") ? "pending" : "")} relation(s) with other users. 
                        They are sorted below by name and type.{((props.filterBy === "PENDING") ? "" : " Expand each section to see the list of relations.")}</Card.Subtitle>
                        </>
                    

                    <Row className="mt-3 d-flex justify-content-end text-left">
                        
                        <Col sm={12} className="pb-2 p-md-0">
                        <Form.Label className="form-check-label px-2" >View by</Form.Label>
                        <Form.Check
                            inline
                            label="Name"
                            name="group1"
                            type="radio"
                            id={`inline-radio-2`}
                            value="1"
                            checked={Number(viewType)===1?true:false}
                            onChange={(e)=>{
                                setViewType(e.target.value);
                                toggleView(1);                                
                            }}

                        />
                        <Form.Check
                            inline
                            label="Type"
                            name="group1"
                            type="radio"
                            id={`inline-radio-2`}
                            value="2"
                            checked={Number(viewType)===2?true:false}
                            onChange={(e)=>{                                
                                setViewType(e.target.value);
                                toggleView(2);                
                            }}
                        />
                        
                        </Col>
                    </Row>

                    <div id="listViewContainer" style={{display:"none"}}>
                        <div className="wrapperExpandable mt-5">
                            {relationTypesData.map((row, index) => (
                                <ExpandableBlock key={`collap-types-${index}-${row.value}`} open={(props.filterBy === 'PENDING') ? true : false} title={<h5 className="mb-0">{row.relation_type_name}</h5>} >
                                        { ( ( (row.relation_type_description !== '') && (Number(row.value) !== 3)) || ((row.relation_type_description !== '') && (Number(row.value) === 3) && (props.pageName === 'relationListing') ) )  &&                                         
                                            <>
                                                <p>
                                                    <FontAwesomeIcon icon={faInfoCircle} className="animate-left-3 me-3 ms-2" />{row.relation_type_description}
                                            
                                                    
                                                </p>
                                            </>
                                        }

                                        { (Number(row.value) === 3) && <p><FontAwesomeIcon icon={faInfoCircle} className="animate-left-3 me-3 ms-2" />Missing publications? Consult the <a href={`#${Routes.FAQPage}`} >FAQ</a>.</p>}
                                        
                                        
                                        {/* For Co-authors of paper, umbrella funding and co-awardee */}
                                        { ( (Number(row.value) === 3) || (Number(row.value) === 6) ||  (Number(row.value) === 7) ) &&
                                            <>
                                                <div key={`table-family-${index}-${row.value}`} className="grid-table">
                                                    <div className="row row-header g-0">
                                                        <div className="col col-12 col-md-4 d-none d-md-block">
                                                            <div className="contWrap"> 
                                                                {pubTitleArr.map((rel_row, rel_index) => {
                                                                    return(<React.Fragment key={`pubTitleArr-${rel_index}`}>{rel_row[row.value]}</React.Fragment>)
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className="col col-12 col-md-2 d-none d-md-block">
                                                            <div className="contWrap">{row.show_end_date_label}</div>
                                                        </div>
                                                        <div className="col col-12 col-md-2 d-none d-md-block">
                                                            <div className="contWrap">Conflict reason</div>
                                                        </div>
                                                        <div className="col col-12 col-md-4 d-none d-md-block">
                                                            <div className="contWrap">                                                                
                                                                {pubCoAuthorTitleArr.map((rel_row, rel_index) => {
                                                                    return(<React.Fragment key={`pubCoAuthorTitleArr-${rel_index}`}>{rel_row[row.value]}</React.Fragment>)
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row-body">
                                                        {row.relations && row.relations.map((rel_row, rel_index) => (
                                                            <div className="row g-0" key={`family-${rel_index}`}>
                                                                <div className="col col-4 col-md-6 d-block d-md-none">
                                                                    <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>
                                                                        {pubTitleArr.map((rel_row, rel_index) => {
                                                                            return(<React.Fragment key={`pubTitleArr-${rel_index}`}>{rel_row[row.value]}</React.Fragment>)
                                                                        })}
                                                                    </div>
                                                                </div>
                                                                <div className="col col-8 col-md-4">
                                                                    {rel_row.publication.publication_name}
                                                                </div>
                                                     
                                                                { Object.keys(rel_row.publication.publication_years).map((yearKey,i,arr) => {
                                                                return (
                                                                    <React.Fragment key={`pub-row-${rel_index}-${i}`} >
                                                                        {(i > 0) &&
                                                                             <div className="col col-8 col-md-4">ssss</div>
                                                                        }
                                                                        <div className="col col-4 col-md-2 d-block d-md-none">
                                                                            <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>
                                                                                {row.show_end_date_label}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col col-8 col-md-2">
                                                                            {rel_row.publication.publication_years[yearKey]['date_end_year']}
                                                                        </div>

                                                                        <div className="col col-4 col-md-2 d-block d-md-none">
                                                                            <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>
                                                                                Conflict reason
                                                                            </div>
                                                                        </div>
                                                                        <div className="col col-8 col-md-2">
                                                                            {row.relation_type_name}<br/>
                                                                            {statusPill(rel_row.publication.publication_years[yearKey]['status'])}
                                                                        </div>

                                                                        <div className="col col-4 col-md-2 d-block d-md-none">
                                                                            <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>
                                                                                {pubCoAuthorTitleArr.map((rel_row, rel_index) => {
                                                                                    return(<React.Fragment key={`pubCoAuthorTitleArr-${rel_index}`}>{rel_row[row.value]}</React.Fragment>)
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col col-8 col-md-4">
                                                                            <>
                                                                            {
                                                                            rel_row.publication.publication_years[yearKey]['co_author_names'].map((authorKey,j) => {
                                                                                return(
                                                                                    <Fragment key={`author-list-${yearKey}${i}${j}`}>{authorKey}<br/></Fragment>
                                                                                )
                                                                            })
                                                                            }
                                                                            <br/>
                                                                            <Button variant="primary" as={Link} className="animate-hover btn btn-primary btn-primary-small-rounded"  to={`${editLink}/${rel_row.publication.publication_years[yearKey]['parent_id']}`} >
                                                                            <FontAwesomeIcon icon={faEye} className="animate-left-3 me-3 ms-2" /> View
                                                                            </Button>
                                                                            </>
                                                                        </div>

                                                                    </React.Fragment>
                                                                )})}
                                                                
                                                            </div>
                                                        ))}
                                                        
                                                    </div>
                                                </div>

                                            </>
                                                       
                                        }
                                        { ( ((Number(row.value) === 1) || (Number(row.value) === 2) || (Number(row.value) === 4) || (Number(row.value) === 5) ) ) && row.relations && (row.relations.length > 0)  &&   
                                            <>
                                                {/* For Family members, Direct acedemic, For Ongoing collaboration */}
                                                <div key={`table-family-${index}-${row.value}`} className="grid-table">
                                                    <div className="row row-header g-0">
                                                        <div className="col col-12 col-md-3 d-none d-md-block">
                                                            <div className="contWrap">Person name</div>
                                                        </div>
                                                        {(Number(row.show_start_date) === 1) && <div className="col col-12 col-md-2 d-none d-md-block">
                                                            <div className="contWrap">{row.show_start_date_label}</div>
                                                        </div>}
                                                        {(Number(row.show_end_date) === 1) && <div className="col col-12 col-md-2 d-none d-md-block">
                                                            <div className="contWrap">{row.show_end_date_label}</div>
                                                        </div>}
                                                        <div className="col col-12 col-md-2 d-none d-md-block">
                                                            <div className="contWrap">Status</div>
                                                        </div>
                                                        <div className="col col-12 col-md-3 d-none d-md-block">
                                                            <div className="contWrap">Action</div>
                                                        </div>
                                                    </div>

                                                    <div className="row-body">                                                        
                                                        {row.relations && row.relations.map((rel_row, rel_index) => (
                                                            <div className="row g-0" key={`family-${rel_index}`}>
                                                                <div className="col col-4 col-md-3 d-block d-md-none">
                                                                    <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>Person name</div>
                                                                </div>
                                                                <div className="col col-8 col-md-3">
                                                                    {rel_row.display_coauthor_name}<br/><small className="text-muted">{ (rel_row.co_author_status === 2 ) ? "(Deleted user)" : ""}</small>
                                                                </div>
                                                     
                                                                {(Number(row.show_start_date) === 1) && <>
                                                                <div className="col col-4 col-md-2 d-block d-md-none">
                                                                    <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>{row.show_start_date_label}</div>
                                                                </div>
                                                                <div className="col col-8 col-md-2">
                                                                    { ((Number(row.start_date_format) === 2) ? `${rel_row.start_date_month}/${rel_row.start_date}` : rel_row.start_date)}
                                                                </div></>}

                                                                {(Number(row.show_end_date) === 1) && <><div className="col col-4 col-md-2 d-block d-md-none">
                                                                    <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>{row.show_end_date_label}</div>
                                                                </div>
                                                                <div className="col col-8 col-md-2">
                                                                    { ((Number(row.end_date_format) === 2) ? `${rel_row.end_date_month}/${rel_row.end_date}` : rel_row.end_date)}
                                                                </div></>}

                                                                <div className="col col-4 col-md-2 d-block d-md-none">
                                                                    <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>Status</div>
                                                                </div>
                                                                <div className="col col-8 col-md-2">
                                                                    {statusPill(rel_row.status)}
                                                                </div>

                                                                <div className="col col-4 col-md-3 d-block d-md-none">
                                                                    <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>Action</div>
                                                                </div>
                                                                <div className="col col-8 col-md-3">
                                                                    {(rel_row.co_author_status !== 2 ) &&
                                                                    <Button variant="primary" as={Link} className="animate-hover btn btn-primary btn-primary-small-rounded"  to={`${editLink}/${rel_row.modify_id}`} >
                                                                        <FontAwesomeIcon icon={faEye} className="animate-left-3 me-3 ms-2" /> View
                                                                    </Button>}
                                                                </div>
                                                            </div>
                                                        ))}
                                                        
                                                    </div>

                                                </div>
                                                
                                                
                                            </>
                                        }

                                        { (Number(row.value) === 8) && row.relations && (row.relations.length > 0) && 
                                            <div key={`table-family-${index}-${row.value}`} className="grid-table">
                                                <div className="row row-header g-0">
                                                    <div className="col col-12 col-md-4 d-none d-md-block">
                                                        <div className="contWrap">Institution name</div>
                                                    </div>
                                                    {(Number(row.show_start_date) === 1) && <div className="col col-12 col-md-2 d-none d-md-block">
                                                        <div className="contWrap">{row.show_start_date_label}</div>
                                                    </div>}
                                                    {(Number(row.show_end_date) === 1) && <div className="col col-12 col-md-2 d-none d-md-block">
                                                        <div className="contWrap">{row.show_end_date_label}</div>
                                                    </div>}
                                                    <div className="col col-12 col-md-2 d-none d-md-block">
                                                        <div className="contWrap">Status</div>
                                                    </div>
                                                    <div className="col col-12 col-md-4 d-none d-md-block">
                                                        <div className="contWrap">Action</div>
                                                    </div>
                                                </div>

                                                <div className="row-body">
                                                    {row.relations && row.relations.map((rel_row, rel_index) => (
                                                        <div className="row g-0" key={`inst-row-${rel_index}`}>
                                                            <div className="col col-4 col-md-5 d-block d-md-none">
                                                                <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>Institution name</div>
                                                            </div>
                                                            <div className="col col-8 col-md-5">
                                                                {rel_row.institution_name}
                                                            </div>

                                                            {(Number(row.show_start_date) === 1) && <>
                                                            <div className="col col-4 col-md-2 d-block d-md-none">
                                                                <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>{row.show_start_date_label}</div>
                                                            </div>
                                                            <div className="col col-8 col-md-2">
                                                                { ((Number(row.start_date_format) === 2) ? `${rel_row.start_date_month}/${rel_row.start_date}` : rel_row.start_date)}
                                                            </div></>}

                                                            {(Number(row.show_end_date) === 1) && <><div className="col col-4 col-md-2 d-block d-md-none">
                                                                <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>{row.show_end_date_label}</div>
                                                            </div>
                                                            <div className="col col-8 col-md-2">
                                                                { ((Number(row.end_date_format) === 2) ? `${rel_row.end_date_month}/${rel_row.end_date}` : rel_row.end_date)}
                                                            </div></>}

                                                            <div className="col col-4 col-md-2 d-block d-md-none">
                                                                <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>Status</div>
                                                            </div>
                                                            <div className="col col-8 col-md-2">
                                                                {statusPill(rel_row.status)}
                                                            </div>

                                                            <div className="col col-4 col-md-3 d-block d-md-none">
                                                                <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>Action</div>
                                                            </div>
                                                            <div className="col col-8 col-md-3">
                                                                <Button variant="primary" as={Link} className="animate-hover btn btn-primary btn-primary-small-rounded"  to={`${editLinkPendingEmployment}/${rel_row.modify_id}`} >
                                                                    <FontAwesomeIcon icon={faEye} className="animate-left-3 me-3 ms-2" /> View
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    
                                                </div>
                                            </div>
                                        }
                                    
                                </ExpandableBlock>
                            ))}

                            {relationInstitutionData && (relationInstitutionData.length > 0) &&
                                <ExpandableBlock key="institutional-conflict-" title={<h5 className="mb-0">Institutional</h5>} open={(props.filterBy === 'PENDING') ? true : false}>
                                    <div key={`table-authors-institution`} className="grid-table">
                                        <div className="row row-header g-0">                                                    
                                            <div className="col col-12 col-md-6 d-none d-md-block">
                                                <div className="contWrap">Institution</div>
                                            </div>
                                            <div className="col col-12 col-md-2 d-none d-md-block">
                                                <div className="contWrap">Position</div>
                                            </div>
                                            <div className="col col-12 col-md-4 d-none d-md-block">
                                                <div className="contWrap">Year</div>
                                            </div>
                                        </div>
                                        <div className="row-body">
                                            {relationInstitutionData.map((rel_row, rel_index) => {
                                                return (
                                                    <div className="row g-0" key={`inst-row-${rel_index}`}>
                                                        

                                                        <div className="col col-4 col-md-4 d-block d-md-none">
                                                            <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>Institution</div>
                                                        </div>
                                                        <div className="col col-8 col-md-6">
                                                            {rel_row.institution_name}
                                                        </div>

                                                        <div className="col col-4 col-md-4 d-block d-md-none">
                                                            <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>Position</div>
                                                        </div>
                                                        <div className="col col-8 col-md-2">
                                                            {rel_row.type_of_position}
                                                        </div>

                                                        <div className="col col-4 col-md-4 d-block d-md-none">
                                                            <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>Year</div>
                                                        </div>
                                                        <div className="col col-8 col-md-4">
                                                            {rel_row.year}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                        
                                </ExpandableBlock>
                            }
                            
                        </div>
                    </div>    
                    <div id="gridViewContainer" style={{display:"block"}}>

                        <div className="grid-table">
                            <div className="row row-header g-0">
                                <div className="col col-12 col-md-4 d-none d-md-block">
                                    <div className="contWrap">Person name</div>
                                </div>
                                <div className="col col-12 col-md-8 d-none d-md-block">
                                    <div className="contWrap">Reason</div>
                                </div>
                            </div>
                            <div className="row-body">
                                {relationGridViewData && relationGridViewData.map((row, index) => {
                                    return (
                                        <div className="row g-0" key={`gridview-row-${index}`}>
                                            <div className="col col-4 col-md-4 d-block d-md-none">
                                                <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px"}}>Person name</div>
                                            </div>
                                            <div className="col col-8 col-md-4">
                                                {row.name}
                                            </div>
                                            <div className="col col-4 col-md-8 d-block d-md-none">
                                                <div className="contWrap fw-bolder text-uppercase" style={{ fontSize: "12px" }}>Reason</div>
                                            </div>
                                            <div className="col col-8 col-md-8">
                                                <ul>
                                                    {row.reason.map((reason_row, r_index) => {
                                                        return (
                                                            <li key={`gridview-row-reason-${index}${r_index}`}>
                                                                {reason_row}
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>          
                </Card.Body>}
            </Card>
            }
        </>
    );
}

export default RelationListComponent;