import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faKey} from "@fortawesome/free-solid-svg-icons";
import { faUserCircle} from "@fortawesome/free-regular-svg-icons";
import { Nav, Image, Navbar, Dropdown, Container } from '@themesberg/react-bootstrap';
import Logout from "../../framework/utilities/Logout";
import Profile3 from "../../assets/img/team/default.png";
import { Routes } from "../../routes";
import NavbarBrand from "./NavbarBrand";

let sessionUser = JSON.parse(localStorage.getItem("profile"));

const NavbarComponent = (props) => {
    return (

        <>
            <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="position-fixed w-100 shadow" style={{top:0, zIndex:9}}>
                
                <Container>
                    <NavbarBrand />
                    {/*<Navbar.Brand href={`/#${Routes.DashboardOverview.path}`} >ConflictDB</Navbar.Brand>*/}
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href={`/`}>Home</Nav.Link>
                            <Nav.Link href={`/#${Routes.Relations.listall}`}>List relations</Nav.Link>
                            <Nav.Link href={`/#${Routes.Relations.addnew}`}>Add relation</Nav.Link>
                            <Nav.Link href={`/#${Routes.Relations.activeConflicts}`}>View conflicts</Nav.Link>
                            {/*<NavDropdown title="Relations" id="collasible-nav-dropdown" className="nav-link-carot">
                                <NavDropdown.Item href={`/#${Routes.Relations.listall}`}>List all relations</NavDropdown.Item>
                                <NavDropdown.Item href={`/#${Routes.Relations.activeConflicts}`}>View active conflicts</NavDropdown.Item>
                                <NavDropdown.Item href={`/#${Routes.Relations.addnew}`}>Add new relation</NavDropdown.Item>
                            </NavDropdown>*/}
                        </Nav>
                        <Nav>
                            <Dropdown as={Nav.Item}>
                                <Dropdown.Toggle as={Nav.Link} className="d-flex align-items-center py-0 px-lg-0 px-2 text-color ml-2 d-none d-lg-flex">
                                    <span className="avatar" >
                                        <Image src={Profile3} className="user-avatar md-avatar rounded-circle" />
                                    </span>
                                    <span className="ml-2 leading-none">
                                        {sessionUser && <span className="mb-0 font-small fw-bold">{ sessionUser.FName } { sessionUser.SName }</span>}
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Toggle as={Nav.Link} className="d-flex align-items-center py-2 px-0 py-lg-0 text-color ml-2 d-flex d-lg-none">
                                    My account
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                                    <div className="fw-bold d-block d-lg-none" href={null} style={{ borderBottom: "1px solid #eaedf2", paddingLeft: "12px", paddingBottom: "12px" }} >
                                        <Image src={Profile3} style={{height: "20px", minHeight: "20px", width: "auto", minWidth: "auto", margin: "0 9px 0 0"}} className="user-avatar md-avatar rounded-circle d-inline-block" />
                                        <span className="ml-2 leading-none">
                                            {sessionUser && <span className="mb-0 font-small fw-bold">{sessionUser.FName} {sessionUser.SName}</span>}
                                        </span> 
                                    </div>

                                    <Dropdown.Item className="fw-bold" href={`/#${Routes.Users.editpath}`} >
                                        <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My Profile
                                    </Dropdown.Item>
                                    <Dropdown.Item className="fw-bold"  href={`/#${Routes.PasswordReset.path}`}  >
                                        <FontAwesomeIcon icon={faKey} className="me-2" /> Password Reset
                                    </Dropdown.Item>
                                    <Dropdown.Item className="fw-bold" onClick={Logout}>
                                        <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        
        </>
    );
};

export default NavbarComponent;