//import moment from "moment-timezone";

export const RefreshToken = (param) => {
    const API_URL = process.env.REACT_APP_API_URL;
    let refresh_token_time = process.env.REACT_APP_REFRESH_TOKENTIME;
    refresh_token_time = refresh_token_time * 60;
    //const API_KEY = process.env.REACT_APP_API_KEY;

    //let last = 0; // timestamp of the last render() call
    let t = 0;
    function renderr(now) {
        //console.log("calling: ",t,param,refresh_token_time);
        //refresh_token_time = 300;
        // each 2 seconds call the createNewObject() function
       // if (!last || now - last >= 1 * 1000) {
            //last = now;
            t = t + 1;
            //console.log(last, t, param,refresh_token_time,process.env.REACT_APP_REFRESH_TOKENTIME, moment().format('LLLL'));
            if (t > (param - refresh_token_time)) {
                console.log("Call refreshToken");
                t = 0;

                var formData = new FormData();
                formData.append("refresh_token", localStorage.getItem("refresh_token"));
                formData.append("api_key", process.env.REACT_APP_API_KEY);
                formData.append("action", "refresh");

                fetch(API_URL + '/v1/user/refresh', { method: 'POST', body: formData })
                    .then(response => response.json())
                    .then(jsondata => {
                        if (jsondata.error === 'invalid_request'){
                            //alert("Session timedout");
                            console.log("Insie utitlities.. need to logout");
                            return false;
                        }
                        localStorage.setItem("expires_in", jsondata.expires_in);
                        localStorage.setItem("token", jsondata.access_token);
                        localStorage.setItem("refresh_token", jsondata.refresh_token);
                        //console.log("successful", jsondata, localStorage.getItem("expires_in"), localStorage.getItem("token"), localStorage.getItem("refresh_token"));
                    }).catch((err) => {
                        console.log("Login error: " + err);
                        setTimeout(function () {
                            console.log("error");
                        }, 2000);
                    });

            }
        //}
        //requestAnimationFrame(renderr);
    }
    //renderr();
    setInterval(function(){
        renderr();
    }, 999);
}

/**
 * This function assumes 1= Active, 2=Deleted, 0=Inactive
 * If there are custom status then it needs to be passed as StatusInGrid({value:params.value,custom_status: [ {value:'3',label: 'Unverified'} ] });       
 * But never give 0,1 inside the custom_status. Anything other than the 0,1 values can be passed.
 */
export const StatusInGrid = (param) => {    
    
    let status = "";
    if (param.value === 1){
        status = '<span class="badge rounded-pill bg-success">Active</span>';
    }else if (param.value === 0){
        status = '<span class="badge rounded-pill bg-danger">Inactive</span>';
    }
    
    if (param.custom_status){
        param.custom_status.map((x, i) => {            
            if (x.value === param.value.toString()){
                status = '<span class="badge rounded-pill '+ ((x.className)? x.className : 'bg-danger') +'">'+ x.label +'</span>';                              
            }
            return status;
        });
    }
    return status;
}

export async function saveFormRecord(param){
    const API_URL = process.env.REACT_APP_API_URL;

   
    const json_response = await fetch(API_URL + param.url, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem("token"),
        },
        body: param.formData
    })
    .then(response => response.json())
    .then(jsondata => {
        console.log("success");
        //return jsondata;
        //console.log(jsondata);
        if ((jsondata.GID) && (jsondata.message === 'UPDATED')){

            //json_response = {'type':'success','msg':'Record updated successfully.'};  
            return {'type':'success_update','message':'Record updated successfully.','GID' : jsondata.GID};          
        }else if ((jsondata.GID) && (jsondata.message === 'CREATED')){
            //json_response = {'type':'success','msg':'Record created successfully.'};  
            return {'type':'success_create','message':'Record created successfully.','GID' : jsondata.GID};               
        }else if (jsondata.errors){            
           // json_response = {'type':'error','msg': jsondata.message + "\r\n" + JSON.stringify(jsondata.errors) };   
            return {'type':'error','message': jsondata.message + "\r\n" + JSON.stringify(jsondata.errors) };                 
        }                  
    }).catch((err) => {
        console.log("Login error: " + err);
        //json_response = {'type':'error','msg': 'Error saving data.' };  
        return {'type':'error','message': 'Error saving data.' };                
    });

   return json_response;
    
}

export function printGridResult(res) {
    console.log('---------------------------------------');
    if (res.add) {
      res.add.forEach(function (rowNode) {
        console.log('Added Row Node', rowNode);
      });
    }
    if (res.remove) {
      res.remove.forEach(function (rowNode) {
        console.log('Removed Row Node', rowNode);
      });
    }
    if (res.update) {
      res.update.forEach(function (rowNode) {
        console.log('Updated Row Node', rowNode);
      });
    }
  }

export const PasswordCheck = (param) => {    

    let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])');

    //1 capital, 1small case
    //1 special character
    // 1 number
    //length check for 8 to 15

    if(strongPassword.test(param)) 
    { 
        if(param.length >= 8 && param.length <= 50)
            return true;
        else
            return false;
    }
    else
    {
        return false;
    }
}

export const passRules = () => { 
    return (
    <>
        <strong>Password should contain:</strong><ul><li>minimum 8 characters, maximum 50 characters</li><li>atleast one lower case</li><li>atleast one upper case</li><li>atleast one number</li><li>atleast one special character</li><li>not be same as your email</li></ul>
    </>
    ) 
}

export const scrollToTop = () => { 
    const body = document.querySelector('#root');
    body.scrollIntoView({
        behavior: 'smooth'
    }, 500);
}

export const validEmail = (email) => {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(email)) {
        return false;
    }else{
        return true;
    }
}




