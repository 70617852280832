import RelationAddForm from './Form';
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';
import { Routes } from "../../routes";
import {  Alert } from '@themesberg/react-bootstrap';
import { useForm } from "react-hook-form";
import useFetch from "../../framework/hooks/useFetch";

const RelationAdd = () => {

    const heading = "Add new relation";
    const id = 0;

    const { register, handleSubmit, setValue, getValues, errors } = useForm();  
    const API_URL = process.env.REACT_APP_API_URL;
    const { data, isPending, isError } = useFetch(API_URL + '/v1/web/relation/data/addnew', 'GET', true);

    // console.log(data);

    
    setValue("GID", '');
    setValue("UId",'');
    setValue("rUserId", '');
    setValue("rUserName", '');
    setValue("relationType", 0);
    setValue("source", '');
    setValue("notes", '');
    setValue("paperType", 0);
    setValue("dateStartYear", null);
    setValue("dateStartMonth", null);
    setValue("dateEndYear", null);
    setValue("dateEndMonth", null);
    setValue("startDateMand",0);
    setValue("endDateMand",0);
    setValue("startDateLabel","");
    setValue("endDateLabel","");
    setValue("Status", 1); // by default we set state as Validated    
    setValue("relationTypes", data.relationTypes);
    setValue("relationTypesOrdered", data.relationTypesOrdered); 
    setValue("publicationId", 0);
    setValue("publicationName","");
    setValue("usersDrp", data.usersDrp);
    setValue("allowModify", true);
    setValue("coAuthId", '');
    setValue("coAuthLabel", '');
    setValue("modificationsComment","");
    setValue("rejectionComment","");
    setValue("institutionDrp",data.institutionDrop);
    setValue("rInstitutionId", '');
    setValue("rInstitutionName", '');
    setValue("publicationDBLPUrl",data.publicationDBLPUrl);
    setValue("DBLPImportedEntry", 0);
    setValue("communityPaper", 0);
    setValue("ongoingEmploymentCheck", 1);

    const showSave = true;
    
    // relation types dropdown setup
    let relationDrpTypes = [];
    let relationTypeName = '';
    if (data.relationTypesOrdered){
        Object.keys(data.relationTypesOrdered).forEach(key => { 
            
            if (data.relationTypesOrdered[key].id === 5){
                relationDrpTypes.push({
                    value: "INSTITUTION_AFF",
                    label: "Institutional"
                });
            }
            relationDrpTypes.push({
                value: data.relationTypesOrdered[key].id,
                label: data.relationTypesOrdered[key].type_name
            });
        });

        
    }
    let pubDrp = [];
    if (data.allPublications){
        pubDrp = data.allPublications;
    }
    //console.log(pubDrp);
    /*if (data.allPublications){
        allPublicationsDisabled.
        console.log(data.allPublicationsDisabled);
        //console.log(data.allPublicationsDisabled);
        Object.keys(data.allPublications).forEach(key => {    
            //console.log("key = ",key);
            if ((data.allPublicationsDisabled.length > 0) && (data.allPublicationsDisabled.includes(key))){

                console.log("present key",key);
                pubDrp.push({
                    value: key,
                    label: data.allPublications[key]
                });
            }
            
        });
    }*/
    let paperTypeDrp = [];
    if (data.paperTypes){
        Object.keys(data.paperTypes).forEach(key => {
            if (Number(key) === 0){
                setValue("paperTypeName",data.paperTypes[key]);
            }
            paperTypeDrp.push({
                value: key,
                label: data.paperTypes[key]
            });
        });
    }
    setValue("paperTypes", paperTypeDrp);
    setValue("relationTypesAll", data.relationTypes);
    setValue("relationDrpTypes", relationDrpTypes);
    setValue("relationTypeName", relationTypeName);
    setValue("allPublications", pubDrp);
    

    return (
        <>
            <ModuleBreadcrumb links={[
                { label:"List all relations", linkto: Routes.Relations.listall },                 
                { label: heading } ]}  />
            <h4>{heading}</h4> 

            {isPending && <div className="alert alert-info" role="alert">Loading form...Please wait..</div>}                    
            {isError && <Alert variant="danger">{isError}</Alert>} 
            {data.allPublications && 
                <RelationAddForm id={id}  setValue={setValue} getValues={getValues} register={register} handleSubmit={handleSubmit} errors={errors}  showSave={showSave}   />
            }
        </>
    );
}

export default RelationAdd;