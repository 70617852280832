import { Button } from '@themesberg/react-bootstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import {  statusPill } from "./relations";
import ConfirmDeleteModal from '../../project/components/ConfirmDeleteModal';
import { useState } from "react";

const DynamicCoauthor = (props) => {
    
    const getValues = props.getValues;
    const setInputList = props.setInputList;
    const inputList = props.inputList;
    const userActionRowData = props.userActionRowData;
   
    let [modalShow, setModalShow] = useState(false);
    //let [listIndex, setIndex] = useState('');
    let listIndex = '';
    const id = props.id;

    //let [delRecTitle, setDelRecTitle] = useState('');
    let delRecTitle = '';

    //console.log("inside dynamic",inputList);

    const handleCoAuthorChange = (author, index, vname) => {
        const list = [...inputList];
        if (author){
            list[index][vname] = author.value;
            list[index]['coauthor_id'] = author.value;
            list[index]['coauthor_name'] = author.label;
        }else{
            list[index][vname] = "";
            list[index]['coauthor_id'] = "";
            list[index]['coauthor_name'] = "";
        }
        setInputList(list);
        //console.log(inputList);
    }

    /*const deleteBtn=(e, params, index)=>{

        setModalShow(true);
        setDelRecTitle(params.coauthor_name);
        setIndex(index);
    }*/

     // handle click event of the Remove button
     const handleRemoveClick = (index) => {
        

        const list = [...inputList];
        list[listIndex]['status'] = 2;
        setInputList(list);
        setModalShow(false);

       /* const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);*/
    };

    const deleteRowBtn = (index) => {
    
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);

    };

   
    return (
        <>
            <ConfirmDeleteModal  modalShow={modalShow} handleModelClose={() => { setModalShow(false);}} moduleName="co author relation"  delRecTitle={delRecTitle}  handleOnDeleteSubmitted={handleRemoveClick} />

            {
            inputList.map((x, i) => {
                return (
                        
                        <tr className="mb-0"  key={i + "row"}>
                            <td align="right" valign="middle" >{i+1}</td>
                            <td><Select  
                                    name="CoAuthorDrp"
                                    key={`CoAuthordrop-${i}`} 
                                    options={getValues('usersDrp')}  
                                    value={{value: x.coauthor_name, label: x.coauthor_name}}
                                    closeOnScroll={true}                                    
                                    onChange={author => { 
                                        if (author === null){
                                        }
                                        handleCoAuthorChange(author,i,'CoAuthorDrp');
                                    }}
                                    filterOption={(option, searchText) => {
                                        if (
                                            option.data.label.toLowerCase().includes(searchText.toLowerCase()) ||
                                            ( (option.data.sec_email) && option.data.sec_email.toLowerCase().includes(searchText.toLowerCase()) )
                                        ){
                                            return true;
                                        }else{
                                            return false;
                                        }      
                                    }} 
                                    isDisabled={ ( (x.status === 2) || ((id !== undefined) && (id !== 0)))  ? true : false } 
                                /></td>
                            <td valign="middle" align="center">
                               {((x.status !== 1) && userActionRowData && userActionRowData[x.coauthor_id] && (userActionRowData[x.coauthor_id] === "ACCEPTED")) ?
                                    statusPill('ACCEPTED')
                                    :
                                    <>
                                      {((x.status !== 1) && userActionRowData && userActionRowData[x.coauthor_id] && (userActionRowData[x.coauthor_id] === "REJECTED")) ?
                                        
                                        statusPill('REJECTED')
                                        :
                                        <>
                                            { (x.status === 4) ?
                                                statusPill('PENDING')
                                                :
                                                statusPill(x.status)
                                            }
                                        </>
                                        
                                        }
                                    </>
                                    
                                }
                               
                            </td>
                            <td align="center"> 
                                {(x.alow_modify === 1)  && (x.relationId !== '' ) &&
                                    <>
                                    {/** we have commented this because we now let the admin make the changes */}
                                    {/*<Button className="animate-hover btn btn-sm btn-delete btn-small-rounded" name="status" onClick={e => deleteBtn(e, x, i)} ><FontAwesomeIcon icon={faTrashAlt} className="animate-left-3 me-3 ms-2" />Remove</Button>*/}
                                    </>
                                }  
                                {(x.alow_modify === 1)  && (x.relationId === '' ) &&
                                    <Button className="animate-hover btn btn-sm btn-delete btn-small-rounded" name="status" onClick={e => deleteRowBtn(i)} ><FontAwesomeIcon icon={faTrashAlt} className="animate-left-3 me-3 ms-2" />Remove</Button>
                                }                                 
                            </td>
                        </tr>
                        
                    
                );
               
            })}
            
        </>
    );
}

export default DynamicCoauthor;