
export const Routes = {
    // pages
    DashboardOverview: { path: "/" },

    Signin: { path: "/sign-in" },
    GuestForgotPassword: { path: "/forgot-password" },
    RegistrationWithToken: { path: "/registration/:token" },
    Registration: { path: "/registration" },
    GuestResetPassword: {
         path: "/reset-password/:token",
         },
    Lock: { path: "/lock" },
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },

    Users:{
        editpath: '/user/profile'
    },


    // logged in user rest pass feature
    PasswordReset:{
        path: "/user/password-reset",
    },

    Relations:{
        validationNewUser:"/relations/validate/new/:token" ,
        listBasedOnRules:"/relations/conflicts/:conferencename/:confid",
        pendingEmploymentView:"/pending-relations/:action/:id",
        view:"/relations/:action/:id",       
        addnew:"/relations/add",        
        activeConflicts: "/relations/conflicts",
        pendingList: "/relations/pending",
        listall: "/relations",        
    },

    

    HelpPage: "/help",
    FAQPage: "/help/faq",
    ContactUsPage: "/contact",
   
    
};