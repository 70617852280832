import { useState, useEffect } from 'react';
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';
import { Col, Row , Alert, Form , Card, Button} from '@themesberg/react-bootstrap';
import { useForm } from "react-hook-form";
import useFetch from "../../framework/hooks/useFetch";
import ModuleEditScreenButtons  from "../../project/components/ModuleEditScreenButtons";
import { Routes } from "../../routes";
import DynamicInstitutions from './DynamicInstitutions';
import moment from "moment-timezone";
import { InstitutionCheck } from "./Users";
import { scrollToTop } from "../../framework/utilities/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";


const UserEditForm = (props) => {
    const heading = "Update profile";
    
    const { register, handleSubmit, setValue} = useForm();
    
    /* Fetching data and setting the values to the form elements */
    const API_URL = process.env.REACT_APP_API_URL;
    const { data, isPending, isError } = useFetch(API_URL + '/v1/web/user/fullprofile', 'POST', true);
    const [startSave, setStartSave] = useState(false);
    const [successMessage,setSuccessMessage] = useState('');
    const [fakeUserLinking,setFakeUserLinking] = useState(false);
    const [fakeUserName,setFakeUserName] = useState('');
    const [fakeUserDBLP,setFakeUserDBLP] = useState('');

    useEffect(() => {
        if (data) {
            setValue("FName", data.FName);
            setValue("SName", data.SName);
            setValue("oName1", data.oName1 || '');
            setValue("oName2", data.oName2 || '');
            setValue("Email", data.Email);
            setValue("secEmail", data.secEmail || '');
            setValue("dblp_url", data.dblp_url || '');
        }
    }, [data, setValue]);
    
    const [inputList, setInputList] = useState([       
        { institutionId:"", institutionLabel: "",positionId:"", positionLabel: "", instOtherName: "", startDate: ''  , formatedStartDt:  new Date() , endDate: '' , formatedEndDt:  new Date() , current: 0, position: 0,  startDateLabel: "", endDateLabel: "" },        
    ]);

    const [errMessage,setErrorMessage] = useState('');
    
        

    useEffect(() => {
        const institutionAddData = [];
        const institutionData = data.institutionData || [];

        Object.keys(institutionData).forEach(key => {
            institutionAddData.push({
                id: institutionData[key]['id'],
                user_id: institutionData[key]['user_id'],
                institutionId: institutionData[key]['institutionId'],
                institutionLabel: institutionData[key]['institutionName'],
                source: institutionData[key]['source'],
                startDate: moment(institutionData[key]['startDate']).format("YYYY-MM-DD"),
                startDateLabel: moment(institutionData[key]['startDate']).toDate(),
                current: institutionData[key]['current'],
                position: institutionData[key]['position'],
                endDate: institutionData[key]['endDate'] ? moment(institutionData[key]['endDate']).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"),
                endDateLabel: institutionData[key]['endDate'] ? moment(institutionData[key]['endDate']).toDate() :  new Date(),
                positionId: institutionData[key]['positionId'],
                positionLabel: institutionData[key]['positionLabel'],
                institutionStatus: institutionData[key]['instStatus']

            });
        });

        if(!institutionAddData.length){
            institutionAddData.push(
                { id: "",
                user_id: "",
                institutionId: "",
                institutionLabel: "",
                source: "",
                startDate: "",
                startDateLabel: "",
                current: 0,
                position: 0,
                endDate: "",
                endDateLabel: "",
                positionId: "",
                positionLabel: "",
                institutionStatus: ""
            }
            );
        }
        
        if (institutionAddData)
            setInputList(institutionAddData);
    }, [data.institutionData]);

    const onSubmit = postedData => {
        setStartSave(true);
        setErrorMessage('');

        const validInstitutions = inputList.filter(
          (inst) =>  inst.institutionId !== ""
        );

        // Check if there is at least one valid institution affiliation
        if (validInstitutions.length === 0) {
          setStartSave(false);
          setErrorMessage(
            "At least one institutional affiliation is required."
          );
          scrollToTop();
          return false;
        }

        if(InstitutionCheck(validInstitutions))
        {
            setStartSave(false);
            setErrorMessage('Please ensure all required fields of the Institution affiliations is filled up.');
            scrollToTop();
            return false;  
        } 
      
       

        let formData = new FormData();
        formData.append("FName", postedData.FName);
        formData.append("Email", postedData.Email);
        formData.append("SName", postedData.SName);
        formData.append("secEmail", postedData.secEmail);
        formData.append("oName1", ((postedData.oName1) ? postedData.oName1 : ''));
        formData.append("oName2", ( (postedData.oName2) ? postedData.oName2 : '' ));
        formData.append("dblp_url", postedData.dblp_url);

        formData.append("source_type", 3);
        formData.append("institutionDetails", JSON.stringify(validInstitutions));

        fetch(API_URL + '/v1/web/user/data/save', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
            body: formData
        })
        .then(response => response.json())
        .then(jsondata => {
            //console.log(jsondata);
            if (jsondata.fake_user_link === 'Yes'){
                setFakeUserLinking(true);
                if (jsondata.fake_user_name !== '') setFakeUserName(jsondata.fake_user_name);
                setFakeUserDBLP(jsondata.fake_user_dblp);
            }
            if (jsondata.message === 'UPDATED'){
                setSuccessMessage("Record updated successfully.");
                setStartSave(false);

                setTimeout(function(){                    
                    window.location.reload();
                },1000);
            }
            if (jsondata.errors !== ''){
                setErrorMessage(jsondata.errors);
                scrollToTop();
            }
        }).catch((err) => {
            setStartSave(false);
            console.log("Error: " + err);
        });

    }
    
    const linkToFakeAccountWithDBLP = (dblpUrl) => {
       
        setErrorMessage('');
        setSuccessMessage('');
        setFakeUserLinking(false);
        setSuccessMessage('Please wait while we link your account...');

        let formData = new FormData();
        formData.append("DBLPUrl", dblpUrl);
        fetch(API_URL + '/v1/web/user/linkaccount', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
            body: formData
        })
        .then(response => response.json())
        .then(jsondata => {
            //console.log(jsondata);
            if (jsondata.message === 'UPDATED'){
                setSuccessMessage("Account has been linked.");
                setTimeout(function(){                    
                    window.location.reload();
                },1000);
            }else  if (jsondata.message === 'Error:'){
                setErrorMessage(jsondata.errors);
            }
        }).catch((err) => {            
            console.log("Error: " + err);
        });
    }

    return(
        <>
            <ModuleBreadcrumb links={[{ label: heading } ]}  />
            <h4>{heading}</h4>
            <Row>
                <Col xs={12}>        
                    {isPending && <div className="alert alert-info" role="alert">Fetching data. Please wait..</div>}                    
                    {isError && <Alert variant="danger">{isError}</Alert>} 
                    {errMessage && <Alert variant="danger">{errMessage}</Alert>}  
                    {successMessage && !isError && <Alert variant="success">{successMessage}</Alert>}   
                    { data.Email &&
                        <>
                            {fakeUserLinking && <Card className='mt-3'>
                                <Card.Body>
                                    DBLP crawled name: {fakeUserName}<br/>
                                    DBLP URL: {fakeUserDBLP} <br/><br/>
                                    Linking account will only update DBLP URL field of your profile. No other profile fields will be updated. If there are any publications attached to the DBLP account, they will be copied over and linked to your current account. If you have any publications / relations linked to current account those will be retained.<br/><br/>
                                    If still doubts please use the Contact us form to contact the administrator.<br/><br/>
                                    <Button className="mt-2 animate-hover btn btn-sm btn-addnew btn-small-rounded" name="link_fake_user" onClick={e => linkToFakeAccountWithDBLP(fakeUserDBLP)} ><FontAwesomeIcon icon={faLink} className="animate-left-3 me-3 ms-2" />Link account</Button>
                                </Card.Body>
                            </Card>}
                            <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col md={4} className="mb-3">
                                        <Form.Group id="FName">
                                            <Form.Label className="required">First name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter your first name" {...register("FName")}  required  />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} className="mb-3">
                                        <Form.Group id="SName">
                                            <Form.Label className="required">Last name</Form.Label>
                                            <Form.Control  type="text" placeholder="Enter your last name" {...register("SName")} required/>
                                        </Form.Group>
                                    </Col> 
                                    <Col md={4} className="mb-3">
                                        <Form.Group id="oName1">
                                            <Form.Label>Other name 1</Form.Label>
                                            <Form.Control  type="text" placeholder="" {...register("oName1")} />
                                        </Form.Group>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col md={4} className="mb-3">
                                        <Form.Group id="oName2">
                                            <Form.Label>Other name 2</Form.Label>
                                            <Form.Control type="text" placeholder=" " {...register("oName2")} />
                                        </Form.Group>
                                    </Col> 
                                    <Col md={4} className="mb-3">
                                        <Form.Group id="Email">
                                            <Form.Label className="required">Primary email</Form.Label>
                                            <Form.Control  type="email" placeholder="example@gmail.com" {...register("Email")} required/>
                                        </Form.Group>
                                    </Col> 
                                    <Col md={4} className="mb-3">
                                        <Form.Group id="secEmail">
                                            <Form.Label>Secondary email</Form.Label>
                                            <Form.Control type="email" placeholder="" {...register("secEmail")} />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={4} className="mb-3">
                                        <Form.Group id="dblp_url">
                                            <Form.Label>DBLP profile URL<br/>(e.g: https://dblp.org/pid/78/50)
                                            </Form.Label>
                                            <Form.Control  type="text" placeholder="" {...register("dblp_url")}  />
                                        </Form.Group>
                                    </Col> 
                                </Row>

                                <h5 className="mt-4 mb-0">Institutional affliations</h5>
                                <Row>
                                    <Col md={12} className="mt-3 mb-3">
                                        <p>Please list all institutional affiliations in the past 5 years.</p>
                                        <p className="text-danger">If the institution you want to link is not present in the list, type the new name in the box and click on Create option in the drill down list. Please list all possible names by which this institution might be referred to in a comma-separated list. E.g., “Advanced Micro Devices, AMD”. The new institution will be sent for approval to the administator.</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <DynamicInstitutions inputList={inputList} setInputList={setInputList} institutionData={inputList}/> 
                                    </Col>
                                </Row>
                                
                                <ModuleEditScreenButtons cancelPath={Routes.DashboardOverview.path} showSave={true} startSave={startSave}  /> 
                            </Form>
                        </>
                    }                    
                </Col>
            </Row>
        </>
    );
}

export default UserEditForm;