import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from "react-router-dom";

// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import './framework/scss/Main.scss';
//import "./scss/volt.scss";
import './project/scss/Project.scss';

import HomePage from "./pages/HomePage";
import ScrollToTop from "./framework/components/ScrollToTop";

ReactDOM.render(
    <HashRouter >
        <ScrollToTop />
        <HomePage />
    </HashRouter >,
    document.getElementById('root')
);
