import { Routes } from "../../routes";
import { Col, Row, Form, Button, Alert, Spinner,Card } from '@themesberg/react-bootstrap';
//import Select, { createFilter } from 'react-select';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus,faInfoCircle,faThumbsUp,faEdit,faSave,faPaperPlane, faThumbsDown,faEnvelope , faExternalLinkAlt,faTimes, faQuestionCircle, faUser } from "@fortawesome/free-solid-svg-icons";
import CreatableSelect from 'react-select/creatable';
import { useState,useEffect } from "react";
import moment from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import { validateForm, statusPill } from "./relations";
import { useHistory  } from "react-router-dom";
import { scrollToTop, validEmail } from "../../framework/utilities/utilities";
import DynamicCoauthor from './DynamicCoauthor';
import { Link } from 'react-router-dom';
import ModifyCommentList from './ModifyCommentList';
import useFetch from "../../framework/hooks/useFetch";
import MessageModal from "./MessageModal";


//import { WindowedMenuList } from 'react-windowed-select';
//import CustomOption from './SelectComponent';

const RelationEdit = (props) => {
    
  

    
    const onSubmit = postedData => {

        

        // START - This is done to reset posted data and sue the current state values
        postedData.paperType        = (postedData.paperType !== paperTypeId) ?  paperTypeId : postedData.paperType;
        postedData.publicationName  = (postedData.publicationName !== publicationName) ?  publicationName : postedData.publicationName;
        postedData.sourceUserUpdateComment = (postedData.sourceUserUpdateComment !== sourceUserUpdateComment) ? sourceUserUpdateComment : postedData.sourceUserUpdateComment;
        postedData.communityPaperId = (postedData.communityPaperId !== communityPaperId) ? communityPaperId : postedData.communityPaperId;
        postedData.rInstitutionId = (postedData.rInstitutionId !== rInstitutionId) ? rInstitutionId : postedData.rInstitutionId;
        if ( (showStartYear === 1) && ((startDate === null) || (postedData.dateStartYear === "Invalid date") || (postedData.dateStartYear === ""))){
            postedData.dateStartYear = null; 
            postedData.dateStartMonth = null;
        }else if ((showStartYear === 1) && (startDateFormat === 2)){
            postedData.dateStartYear  = (postedData.dateStartYear !== moment(startDate).format("YYYY/MM")) ?  moment(startDate).format("YYYY/MM") : postedData.dateStartYear;
            let str = postedData.dateStartYear.toString();  
            if (str.includes('/')){
                let dSY = postedData.dateStartYear.split('/');
                postedData.dateStartMonth =  (dSY[1] ? dSY[1] : 0);
                postedData.dateStartYear =  ((dSY[0]) ? dSY[0] : 0);                
            }else{
                postedData.dateStartMonth =  null;   
            }
        }else if (showStartYear === 1) {
            postedData.dateStartYear  = (postedData.dateStartYear !== moment(startDate).format("YYYY")) ?  moment(startDate).format("YYYY") : postedData.dateStartYear;
            postedData.dateStartMonth =  null;   
        }else{
            // if not shown then set it to null
            postedData.dateStartYear = null;
            postedData.dateStartMonth = null; 
        }

        if ((showEndYear === 1) && ((endDate === null) || (postedData.dateEndYear === "Invalid date") || (postedData.dateEndYear === ""))) {
            postedData.dateEndYear = null; 
            postedData.dateEndMonth = null; 
        }else if ( (showEndYear === 1) && (endDateFormat === 2)){
            postedData.dateEndYear  = (postedData.dateEndYear !== moment(endDate).format("YYYY/MM")) ?  moment(endDate).format("YYYY/MM") : postedData.dateEndYear;
            
            let str = postedData.dateEndYear.toString();  
            if (str.includes('/')){
                let dSY = postedData.dateEndYear.split('/');
                postedData.dateEndMonth =  (dSY[1] ? dSY[1] : 0);
                postedData.dateEndYear =  ((dSY[0]) ? dSY[0] : 0);                
            }else{
                postedData.dateEndMonth =  null;   
            }
        }else if (showEndYear === 1){
            postedData.dateEndYear  = (postedData.dateEndYear !== moment(endDate).format("YYYY")) ?  moment(endDate).format("YYYY") : postedData.dateEndYear;
            postedData.dateEndMonth =  null;   
        }else{
            postedData.dateEndYear  = null;
            postedData.dateEndMonth =  null;   
        }


        let source = getValues('source');
        source = (source !== '')? source : 'Entry by self - ' + sessionUser.FName + ' ' + sessionUser.SName + ' ('+ sessionUser.Email +')';

        setErrorMessage("");
        setStartSave(true);
        
   

        const errMesgs = validateForm(postedData, inputList,id );
        if (errMesgs) {
            setErrorMessage(errMesgs);
            setStartSave(false);
            scrollToTop();
            return false;
        }
       

        // incase the validation fails in relations.js we doublecheck if the comments are added in edit case
        if ((id !== undefined) && (id !== 0) && (postedData.sourceUserIsLoggedInUser === 1)  && (postedData.sourceUserUpdateComment === "") ) {
            setErrorMessage("Please leave a comment noting what changes are made.");
            setStartSave(false);
            scrollToTop();
            return false;
        }
       // console.log("postedData 1",postedData);
        // lets save the data
        let formData = new FormData();
        
        if ((id !== undefined) && (id !== 0)){
            formData.append("GID", id);
        }
        //formData.append("UId", 1);
        formData.append("relationType", postedData.relationType);
        formData.append("relationTypeName", postedData.relationTypeName);
        formData.append("rUserId", postedData.rUserId);
        formData.append("rUserName", postedData.rUserName);

        if (coAutherRelationTypes.includes(postedData.relationType)){
        
            saveUrl  = '/v1/web/relation/data/coauthor_save';

            formData.append("coAuthorDetails", JSON.stringify(inputList));
            formData.append("publicationName", postedData.publicationName);
            formData.append("publicationId", postedData.publicationId);
            formData.append("oldPublicationName", postedData.oldPublicationName);

            //return false;
        }else{
            formData.append("publicationId", 0);
        }

        if (postedData.relationType === 8) {
            saveUrl  = '/v1/web/relation/data/pending_employment_save';
            formData.append("rInstitutionId", postedData.rInstitutionId);
            formData.append("rInstitutionName", postedData.rInstitutionName);
        }

        formData.append("dateStartYear",  postedData.dateStartYear);
        formData.append("dateStartMonth",  postedData.dateStartMonth);
        formData.append("dateEndYear",  postedData.dateEndYear);
        formData.append("dateEndMonth", postedData.dateEndMonth);

        formData.append("source", source);
        formData.append("notes", ((postedData.notes) ? postedData.notes : ''));
        formData.append("sourceUserUpdateComment", ((postedData.sourceUserUpdateComment) ? postedData.sourceUserUpdateComment : ''));
        //formData.append("Status", 1); // set as active from front-end always
        // If its a co-author and paper type is not select we set it the default
        if (coAutherRelationTypes.includes(Number(postedData.relationType))){
            if ((postedData.relationType === 6) || (postedData.relationType === 7)){
                formData.append("paperType",0);                
            }else{
                formData.append("paperType", ((Number(postedData.paperType) === 0) ? 1 : postedData.paperType));
            }            
        }else{
            formData.append("paperType", 1);
        }
        formData.append("communityPaperId",postedData.communityPaperId);
        


        fetch(API_URL + saveUrl, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
            body: formData
        })
        .then(response => response.json())
        .then(jsondata => {            
            if ((jsondata.GID) && (jsondata.message === 'UPDATED')){
                let notify = new FormData();
                    notify.append("NotificationId", getValues('notificationId'));
                    fetch(API_URL + "/v1/web/mark_notification_read", {
                      method: "POST",
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                      body: notify,
                    }).then((response) => response.json())
                    .then(jsondata => {            
                        if (jsondata.message === "SUCCESS") {
                            scrollToTop();
                            setSuccessMessage("Relation updated successfully.");                
                            setTimeout(function(){                    
                                setSuccessMessage("");
                                window.location.reload();
                            },1000);
                        }        
                     }).catch((err) => {
                         console.log("Login error: " + err);            
                     }); 
            }else if ((jsondata.GID) && (jsondata.message === 'CREATED')){
                scrollToTop();
                setSuccessMessage("Relation saved successfully.");
                setTimeout(function(){
                    if (Number(postedData.relationType) === 8){
                        history.push(Routes.Relations.pendingEmploymentView.replace(':id',jsondata.GID).replace(':action',"view"));
                    }else{
                        history.push(Routes.Relations.view.replace(':id',jsondata.GID).replace(':action',"view"));
                    }                    
                },1000);        
            }else if ((jsondata.message === 'UPDATED COAUTHOR')){
                let notify = new FormData();
                notify.append("NotificationId", getValues('notificationId'));
                fetch(API_URL + "/v1/web/mark_notification_read", {
                  method: "POST",
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                  body: notify,
                }).then((response) => response.json())
                .then(jsondata => {            
                    if (jsondata.message === "SUCCESS") {
                        scrollToTop();
                        setSuccessMessage("Relation updated successfully.");
                        setTimeout(function(){
                           window.location.reload();
                        },1000); 
                    }        
                 }).catch((err) => {
                     console.log("Login error: " + err);            
                 });        
            }else if ((jsondata.message === 'DUPLICATE ENTRY')){
                scrollToTop();
                setSuccessMessage("This relation already exists.");
                setStartSave(false);
                return false;
               
            }
            else if (jsondata.errors){
                setErrorMessage(jsondata.message + "\r\n" + JSON.stringify(jsondata.errors));     
                scrollToTop();          
            }    
            setStartSave(false);               
        }).catch((err) => {
            console.log("Login error: " + err);
            setErrorMessage("Record could not be saved at the moment. Please try again.");       
            setStartSave(false);               
        });


        
    }

    const coAutherRelationTypes = [3,6,7];
    
    const register = props.register; // set by useForm
    const handleSubmit = props.handleSubmit; // set by useForm
    const setValue = props.setValue; // set by useForm
    const getValues = props.getValues; // set by useForm
    const API_URL = process.env.REACT_APP_API_URL;
    const history = useHistory();
    const id = props.id;

    const [relationTypeId,setRelationTypeId]        = useState(getValues('relationType'));
    

    
    
    let aYearFromNow = new Date();
    // for co-author type we will allow only +1 year from today. Anything further than that should be flagged as an error. 
    if (relationTypeId === 3){
        aYearFromNow.setDate(aYearFromNow.getDate() + 365);
    }else{
        aYearFromNow.setDate(aYearFromNow.getDate() + (365*20));
    }

    let aYearFromNowStartDate = new Date();
    // for co-author type we will allow only +1 year from today. Anything further than that should be flagged as an error. 
    if (relationTypeId === 3){
        aYearFromNowStartDate.setDate(aYearFromNow.getDate() + 365);
    }else if (relationTypeId === 4){
        aYearFromNowStartDate.setDate(aYearFromNow.getDate());
    }else{
        aYearFromNowStartDate.setDate(aYearFromNow.getDate() + (365*20));
    }
    
    let commentsObj = useFetch(API_URL + '/v1/web/relation_modify_request_comments_list?relationId='+ id+'&relationType='+getValues('relationType'), 'GET');
    const commentsRowData = commentsObj.data.data;   
    const userActionRowData = commentsObj.data.user_action_data;   

    

  // if (commentsRowData)
   // console.log(commentsObj.data.user_action_data[1]);
    //const coauthorActionData = commentsRowData.user_action_data || [];
    /*Object.keys(coauthorData).forEach(key => {
    }*/

    // if there are comments and status is pending, we will show Add comment button
    //const modifyBtnlabel = (commentsRowData && (getValues('Status') === 3) ) ? "Add comment" : "Request to modify";
    const modifyBtnlabel = "Add comment";

    let saveUrl = '/v1/web/relation/data/save';

    let startDt, endDt;
    if (getValues('dateStartYear')){
       if ((getValues('dateStartMonth') === null) || (getValues('dateStartMonth') === 'null')){
            startDt = new Date(getValues('dateStartYear'),0,1,0,0,0); 
       }else{
            startDt = new Date(getValues('dateStartYear'),getValues('dateStartMonth')-1,1,0,0,0); 
       }
       
    }else{
        startDt = null;
    }
    if (getValues('dateEndYear')){        
        if ( (getValues('dateEndMonth') === null) || (getValues('dateEndMonth') === 'null')) {
            endDt = new Date(getValues('dateEndYear'),0,1,0,0,0);
        }else{
            endDt = new Date(getValues('dateEndYear'),getValues('dateEndMonth')-1,1,0,0,0);
        }
        
    }else{
        endDt = null;
    }
    const [startDate, setStartDate] = useState(startDt); 
    const [endDate, setEndDate] = useState(endDt);
   // const [endMonth, setEndMonth] = useState(new Date(getValues('dateEndMonth'),getValues('dateEndMonth')-1,1,0,0,0));
    const [saveAuthorFlag, setSaveAuthorFlag] = useState(false);
    const [showCoauthorList, setShowCoauthorList] = useState(true);
    const [showCoauthorsBlock, setShowCoauthorsBlock] = useState(false);
    const [errMessage,setErrorMessage] = useState('');
    const [startSave,setStartSave] = useState(false);
    const [successMessage,setSuccessMessage] = useState('');
    

    
   
    // relation type
    const relationTypeDrpOptions    = getValues('relationDrpTypes');
    const relationTypesAll          = getValues('relationTypesAll');    
    const [relationTypeName,setRelationTypeName]    = useState(getValues('relationTypeName'));
    const [startDateLabel,setStartDateLabel]        = useState('Start date');   
    const [endDateLabel,setEndDateLabel]            = useState('End date');   
    const [showPublicationDrp,setShowPublicationDrp] = useState(getValues('publicationId'));    
    const [publicationName, setPublicationName] = useState(getValues('publicationName'));
    const [showPublicationType,setShowPublicationType] = useState( ( (getValues('paperType') > 0) ? 1 : 0 ) );  
    const [showStartYear ,setShowStartYear ] = useState(0);    
    const [showEndYear ,setShowEndYear ] = useState(0);    
    const [startDateFormat , setStartDateFormat ] = useState(0);
    const [endDateFormat , setEndDateFormat ] = useState(0);
    const [startDateMand,setStartDateMand] = useState(getValues('startDateMand'));   
    const [endDateMand,setEndDateMand] = useState(getValues('endDateMand'));   
    const [relUserName, setRelUserName] = useState(getValues('rUserName'));
    const [coAuthLabelName, setcoAuthName] = useState(getValues('coAuthLabel'));
    const [rInstitutionId, setRelInstitutionId] = useState(getValues('rInstitutionId'));
    const [rInstitutionName, setRelInstitutionName] = useState(getValues('rInstitutionName'));
    const [showPersonDrp,setShowPersonDrp] = useState(true);

    // publication dropdown setup
    //const paperTypes = getValues('paperTypes');
   // const [paperTypeName, setPaperTypeName] = useState(getValues('paperTypeName'));
    const [paperTypeId, setPaperTypeId] = useState(getValues('paperType'));
    const [communityPaperId, setCommunityPaperId] = useState(getValues('communityPaperId'));
   // const allPublications = getValues('allPublications');
    const [isRequestToModify, setIsRequestToModify] = useState(false);
    const [showRequestToModifyBox, setShowRequestToModifyBox] = useState(false);
    const [isContactAdmin, setIsContactAdmin] = useState(false);
    const [showContactAdminBox, setShowContactAdminBox] = useState(false);
    const [modifyErrMessage, setModifyErrMessage] = useState('');
    const [modifySuccessMessage, setModifySuccessMessage] = useState('');
    const [contactAdminErrMessage, setContactAdminErrMessage] = useState(''); 
    const [contactAdminSuccessMessage, setContactAdminSuccessMessage] = useState('');        
    const [charsLeft, setCharsLeft] = useState(0);
    const [commentCharsLeft, setCommentCharsLeft] = useState(0);    
    const [isRejectClicked, setIsRejectClicked] = useState(false);
    const [pubFieldLabel, setPubFieldLabel] = useState("Publication title");
    const [coauthorAddFieldLabelPlural, setCoauthorAddFieldLabelPlural] = useState("Co-authors");
    const [coauthorAddFieldLabelSingu, setCoauthorAddFieldLabelPSingu] = useState("Co-author");
    const [showSendingMessage, setShowSendingMessage] = useState(false);
    const [startAcceptProcess, setStartAcceptProcess] = useState(false);
    const [startRejectProcess, setStartRejectProcess] = useState(false);
    const [sourceUserUpdateComment, setSourceUserUpdateComment] = useState('');
    const [ongoingEmploymentCheck, setOngoingEmploymentCheck] = useState(getValues('ongoingEmploymentCheck'));
    const [ongoingEmploymentCheckShow, setOngoingEmploymentCheckShow] = useState(0);

    //let coauthorData;

    const [inputList, setInputList] = useState([       
                
    ]);
    

    

    useEffect(() => {
        let coauthorData = props.coauthorData;
        // If any errors are thorws, it used to reset the co-author list hence below is done to use the current state values
        if ((inputList) && (coauthorData) && (inputList.length > 0) && (inputList.length !== coauthorData.length )){
            coauthorData = inputList;
        }
        
        if (coauthorData)
            setInputList(coauthorData);
    }, [props, inputList, setInputList]);
    
    // Initially on edit screen we want to trigger the showhide of fields based on retailer type.
    useEffect(()=>{ 
        if (relationTypeId > 0){            
            showHideFields(relationTypeId)
        }       
    });

    /*const handleCurrentCheckChange = (e) => {
        if (e.target.checked){
            // we need to hide the end date field
        }
    }*/


    const contactAdminButton = () => {
        return (
            <Button className="animate-hover btn btn-sm btn-addnew" onClick={()=>{
                setContactAdminErrMessage(false);
                setIsContactAdmin(true);
                setShowContactAdminBox(true);   
                setIsRejectClicked(false); 
                setIsRequestToModify(false);           
            }} >
                <FontAwesomeIcon icon={faEnvelope} className="animate-left-3 me-3 ms-2" />
                Contact admin
            </Button>);
    }
    const onContactAdmin = (postedData) => {
        setShowSendingMessage(true);

        if (postedData.contactAdminComment === ""){
            setContactAdminErrMessage("Please enter your comments below.");        
            setShowSendingMessage(false);    
            return false;            
        }else if (postedData.relationTypeName === ""){
            setModifyErrMessage("Record could not be saved at the moment. Please try again.");            
            setShowSendingMessage(false);
            return false;     
        }else{
            setContactAdminErrMessage("");
        }
        setShowContactAdminBox(false);

        let formData = new FormData();
        if ((id !== undefined) && (id !== 0)){
            formData.append("GID", id);
        }
        formData.append("modificationsComment", postedData.contactAdminComment);
        formData.append("relationTypeId", postedData.relationType);
        formData.append("relationTypeName", postedData.relationTypeName);
        formData.append("changeRelationToPending", 0);
        formData.append("contactAdmin", 1);
        
        let api_url_contact = '/v1/web/relation_request_to_modify';
        if (postedData.relationType === 8){
            api_url_contact = '/v1/web/relation_request_to_modify_pending_emp' 
        }
        fetch(API_URL + api_url_contact, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
            body: formData
        })
        .then(response => response.json())
        .then(jsondata => {
            setShowSendingMessage(false);
            if (jsondata.message === "CREATED"){
                setContactAdminSuccessMessage("Your comments are sent. You will receive notification when someone replies.");
                setTimeout(function(){
                  window.location.reload();
                },2000);
            }else{
                setContactAdminErrMessage("Record could not be saved at the moment. Please try again.");        
            }            
        }).catch((err) => {
            setShowSendingMessage(false);
            console.log("Login error: " + err);
            setContactAdminErrMessage("Record could not be saved at the moment. Please try again.");           
        });

        return false;


    }

    const RelationDescription = ({ relationTypeId }) => {
        // Use the relationTypeId to find the matching relation type
        const relationType = relationTypesAll[relationTypeId];
      
        return (
          <div>
            {relationType ? (
              <Row className="mt-1">
                <Col className="callout callout-info">
                  <FontAwesomeIcon icon={faInfoCircle} className="animate-left-3 me-3 ms-2" />
                   {relationType.description}
                </Col>
              </Row>
            ) : (<></>)}
          </div>
        );
    }

    const SaveModification = (postedData) => {

        setShowSendingMessage(true);

        setModifyErrMessage("");
        
        if (postedData.modificationsComment === ""){
            setModifyErrMessage("Please enter your comments below.");  
            setShowSendingMessage(false);
            return false;            
        }
        if (postedData.relationTypeName === ""){
            setModifyErrMessage("Record could not be saved at the moment. Please try again.");    
            setShowSendingMessage(false);        
            return false;     
        }

        setShowRequestToModifyBox(false);

        let formData = new FormData();
        if ((id !== undefined) && (id !== 0)){
            formData.append("GID", id);
        }
        formData.append("modificationsComment", postedData.modificationsComment);
        formData.append("relationTypeId", postedData.relationType);
        formData.append("relationTypeName", postedData.relationTypeName);
        formData.append("changeRelationToPending",  ( (commentsRowData && (getValues('Status') === 3) ) ? 0 : 1)  );

        fetch(API_URL + '/v1/web/relation_request_to_modify', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
            body: formData
        })
        .then(response => response.json())
        .then(jsondata => {
            setShowSendingMessage(false);
            if (jsondata.message === "CREATED"){
                    let notify = new FormData();
                    notify.append("NotificationId", getValues('notificationId'));
                    fetch(API_URL + "/v1/web/mark_notification_read", {
                      method: "POST",
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                      body: notify,
                    }).then((response) => response.json())
                    .then(jsondata => {            
                        if (jsondata.message === "SUCCESS") {
                          setModifySuccessMessage("Your comments are sent. You will receive notification when someone replies.");
                          setTimeout(function () {
                            window.location.reload();
                          }, 2000);
                        }        
                     }).catch((err) => {
                         console.log("Login error: " + err);            
                     });     
            }else{
                setModifyErrMessage("Record could not be saved at the moment. Please try again.");        
            }            
        }).catch((err) => {
            setShowSendingMessage(false);
            console.log("Login error: " + err);
            setModifyErrMessage("Record could not be saved at the moment. Please try again.");           
        });

        return false;
    }

    const addCoAuthor = () => {

        if(!getValues('coAuthId'))
        {
            setErrorMessage("Please select co-author");
            scrollToTop();
            setStartSave(false);
            return false;
        }

        if((getValues('coAuthId') === getValues('coAuthLabel')) && !validEmail(getValues('coAuthLabel')))
        {
            setErrorMessage("Please enter email of co author");
            scrollToTop();
            setStartSave(false);
            return false;
        }

        setSaveAuthorFlag(true);
        setShowCoauthorList(false);
        setTimeout(function(){
            setSaveAuthorFlag(false);
            setShowCoauthorList(true);
        },1000);

        
        setInputList([...inputList, { relationId:"",relationParentId:id, coauthor_id: getValues('coAuthId'), coauthor_name: getValues('coAuthLabel'), status:3, alow_modify: 1 }  ]); 

        setValue("coAuthId", '');
        setValue("coAuthLabel", '');
        setcoAuthName('');
    }

    // based on the relation type we will be modifiying the field labels/ display state etc.
    const showHideFields = (type) => {
        
        if (type === 'INSTITUTION_AFF'){
            setErrorMessage('');
            setSuccessMessage('');            
        }else{
            type = Number(type);  
        
            if (type === 8){
                setShowPublicationDrp(0);
                setShowPublicationType(0);
                setShowCoauthorsBlock(false);
                setShowPersonDrp(false);
            }else if (coAutherRelationTypes.includes(type)){        
                if ( ((id === undefined) || (id === 0)) || (getValues('sourceUserIsLoggedInUser') === 1) || (getValues('relUserIsLoggedInUser') === 1)) {
                    setShowPublicationDrp(1);
                }else{
                    setShowPublicationDrp(0);
                }
                
                setShowCoauthorsBlock(true);
                setShowPersonDrp(true);
                if ((type===6) || (type===7)){
                    setPubFieldLabel('Award name');
                    setCoauthorAddFieldLabelPlural('Co-awardees');
                    setCoauthorAddFieldLabelPSingu('Co-awardee');
                    setShowPublicationType(0);
                }else{
                    setPubFieldLabel('Publication title');
                    setCoauthorAddFieldLabelPlural('Co-authors');
                    setCoauthorAddFieldLabelPSingu('Co-author');
                    setShowPublicationType(1);
                }
                
                //console.log(1);
            }else{
                setShowPublicationDrp(0);
                setShowPublicationType(0);
                setShowCoauthorsBlock(false);
                setShowPersonDrp(true);
            }
            //console.log(relationTypesAll);
            if (relationTypesAll[type].show_start_date_label !== '') setStartDateLabel(relationTypesAll[type].show_start_date_label);
            setEndDateLabel(relationTypesAll[type].show_end_date_label);
            setShowEndYear(relationTypesAll[type].show_end_date);
            setShowStartYear(relationTypesAll[type].show_start_date);
            setStartDateFormat(relationTypesAll[type].start_date_format);
            setEndDateFormat(relationTypesAll[type].end_date_format);
            
            setStartDateMand(relationTypesAll[type].start_date_mandatory);
            setEndDateMand(relationTypesAll[type].end_date_mandatory);
            setValue("startDateMand",relationTypesAll[type].start_date_mandatory);
            setValue("endDateMand",relationTypesAll[type].end_date_mandatory);
            setValue("startDateLabel",relationTypesAll[type].show_start_date_label );
            setValue("endDateLabel",relationTypesAll[type].show_end_date_label );
    
            if ((relationTypesAll[type].show_start_date === 1) && (relationTypesAll[type].start_date_mandatory === 1)  && (relationTypesAll[type].show_end_date === 1) && (Number(relationTypesAll[type].end_date_mandatory) === 0)){
                setOngoingEmploymentCheckShow(1);
                // if the ongoing is checked we will hide the end date
                if (ongoingEmploymentCheck){
                    setShowEndYear(0);
                }
                
            }else{
                setOngoingEmploymentCheckShow(0);
            }  
        }
        
        
        
       
        
    }

    // On save of form - control comes to this function.
    // postedData - data posted by the form
    let sessionUser = JSON.parse(localStorage.getItem("profile"));
    

    // this function is used for all types. Even though we send various form data fields, we only used and update whats needed
    const updateRelation = (actionURL,postedData) => {
        if ((id !== undefined) && (id !== 0)){
            let formData = new FormData();
            formData.append("GID", id); 
            formData.append("relationTypeId", postedData.relationType);                      
            formData.append("relationTypeName", postedData.relationTypeName);                      
            formData.append("rUserName", postedData.rUserName);
            formData.append("rejectionComment", postedData.rejectionComment); // will get added only when rejection api is called.
            formData.append("numberOfCoAuthors",((postedData.numberOfCoAuthors > 0) ? postedData.numberOfCoAuthors : 0));
            formData.append("coAuthorIds", (postedData.coAuthorIds) ? postedData.coAuthorIds : '');
            formData.append("publicationName", (postedData.publicationName) ? postedData.publicationName : '');
            
            fetch(API_URL + actionURL, {
              method: "POST",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              body: formData,
            })
              .then((response) => response.json())
              .then((jsondata) => {
                //console.log("success");
                //console.log(jsondata);
                setStartAcceptProcess(false);
                setStartRejectProcess(false);
                if (jsondata.GID && jsondata.message === "UPDATED") {
                    let notify = new FormData();
                    notify.append("NotificationId", getValues('notificationId'));
                    fetch(API_URL + "/v1/web/mark_notification_read", {
                      method: "POST",
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                      body: notify,
                    }).then((response) => response.json())
                    .then(jsondata => {            
                        if (jsondata.message === "SUCCESS") {
                            scrollToTop();
                            setSuccessMessage("Record updated successfully.");
                            setTimeout(function () {
                            setSuccessMessage("");
                            window.location.reload();
                            }, 1000);
                        }        
                     }).catch((err) => {
                         console.log("Login error: " + err);            
                     });  
                } else if (jsondata.errors) {
                  setErrorMessage(
                    jsondata.message + "\r\n" + JSON.stringify(jsondata.errors)
                  );
                }
              })
              .catch((err) => {
                setStartAcceptProcess(false);
                setStartRejectProcess(false);
                console.log("Login error: " + err);
                setErrorMessage(
                  "Record could not be saved at the moment. Please try again."
                );
              });
        }else{
            console.log("No id found");
            return false;
        }
    }

    const onCoAuthorAcceptClicked = postedData => {
      
        if ((!postedData.publicationName) && (getValues('relUserIsLoggedInUser') === 1)){
            postedData.publicationName = postedData.oldPublicationName;
        }
        
        //console.log(inputList);
        let authorIdArr = [];
        if (inputList && (inputList.length > 0)){
            inputList.forEach(element => {                
                authorIdArr.push(element['coauthor_id']);
            });
        }
        //console.log("relatioIdArr",relatioIdArr);
        //return false;
        postedData.numberOfCoAuthors = ((inputList && (inputList.length > 0)) ? inputList.length : 0);
        postedData.coAuthorIds = authorIdArr;
        if ((id !== undefined) && (id !== 0)){
            updateRelation('/v1/web/relation_accept_coauthor_relation',postedData);
        }else{
            console.log("No id found");
            return false;
        }
        return false;
    }

    /*const onCoAuthorRejectClicked = postedData => {
        //console.log("onCoAuthorAcceptClicked");
        //console.log(postedData); return false;
        if ((id !== undefined) && (id !== 0)){
            updateRelation('/v1/web/relation_reject_coauthor_relation',postedData);
        }else{
            console.log("No id found");
            return false;
        }
        return false;
    }*/

    const onAcceptClicked = postedData => {
        // we will not check for any form data here as we only need to update the status of the relation
        setStartAcceptProcess(true);
        if ((id !== undefined) && (id !== 0)){
            updateRelation('/v1/web/relation_accept_relation',postedData);
        }else{
            console.log("No id found");
            return false;
        }        
        
   }

   const onRejectClicked = postedData => {
        setStartRejectProcess(true);
       //console.log(postedData); return false;
        if ((!postedData.publicationName) && (getValues('relUserIsLoggedInUser') === 1)){
            postedData.publicationName = postedData.oldPublicationName;
        }
        setModifyErrMessage("");
        
        if (postedData.rejectionComment === ""){
            setModifyErrMessage("Please enter reason for rejection.");   
            setStartRejectProcess(false);         
            return false;            
        }
        if (postedData.relationTypeName === ""){
            setModifyErrMessage("Record could not be saved at the moment. Please try again.");   
            setStartRejectProcess(false);              
            return false;     
        }
       
        if ((id !== undefined) && (id !== 0)){

            // if co-author type then call a different api endpoint
            if (coAutherRelationTypes.includes(Number(postedData.relationType))){
                updateRelation('/v1/web/relation_reject_coauthor_relation',postedData);
            }else{
                updateRelation('/v1/web/relation_reject_relation',postedData);
            }
            
        } else{
            console.log("No id found");
            return false;
        }       
        return false;
   }
   
   
   /*const onRespondToNotification = postedData => {

       //console.log("comes here modify",postedData);

       setErrorMessage("");

       if ((Number(postedData.startDateMand) === 0) && ( (postedData.dateStartYear === "Invalid date")  )){
            postedData.dateStartYear = new Date().getFullYear();
        }
        if ((Number(postedData.endDateMand) === 0) && ( (postedData.dateEndYear === "Invalid date")  )){
            postedData.dateEndYear = new Date().getFullYear();
        }

        const errMesgs = validateForm(postedData);
        if (errMesgs) {
            //console.log(postedData);
            setErrorMessage(errMesgs);
            setStartSave(false);
            return false;
        }
        
        
        //console.log("comes here to save... its pending");
        return false;
    
    }*/

    

    const cancelButton = () => {
        if  ( ((id !== undefined) || (id !== 0)) && (getValues('sourceUserIsLoggedInUser') === 1) && (props.action === "view")){
            return false;
        }else{
            return (
                <Button className="animate-hover btn btn-sm btn-secondary" onClick={()=>{
                    if (history.length > 0){
                        history.goBack();
                    }else{
                        history.push(Routes.Relations.listall);
                    }
                }} >
                    <FontAwesomeIcon icon={faTimes} className="animate-left-3 me-3 ms-2" />
                    Cancel
                </Button>);
        }
        
    }

    const saveRelationButton = () => {

        // if the source user is logged in and the action is view... we will show the Modify button
        if  ( ((id !== undefined) || (id !== 0)) && (getValues('sourceUserIsLoggedInUser') === 1) && (props.action === "view")){
            
            let modifyLink = (relationTypeId === 8) ? "/pending-relations/modify/" + id : "/relations/modify/"+id;
            return (<Button variant="primary" as={Link} className="animate-hover"  to={`${modifyLink}`} >
            <FontAwesomeIcon icon={faEdit} className="animate-left-3 me-3 ms-2" /> Modify
            </Button>);

        }else{
            const saveLabel = ((id === undefined) || (id === 0)) ? "Create the relation" : ( (getValues('sourceUserIsLoggedInUser') === 1) ? "Save changes" : "Save");

             return (
                 <>
                     { (startSave) ? 
                         <Button variant="primary" type="button"  className="animate-hover" ><Spinner
                         as="span"
                         animation="grow"
                         size="sm"
                         role="status"
                         aria-hidden="true"
                         style={{marginRight:10}}
                         />Please wait...</Button> 
                         :
                         <Button variant="primary" type="submit"  className="animate-hover" ><FontAwesomeIcon icon={faSave} className="animate-left-3 me-3 ms-2" />{saveLabel}</Button>
                     }
                 </>            
             );
        }

        
    }

    

    const requestModifyOrCommentButton = (btnLabel) => {

        if (getValues('sourceUserIsLoggedInUser') === 1){
            return false;
        }else{
            return (<Button  className="animate-hover btn btn-sm btn-warning" onClick={handleSubmit(() => {
                setIsRejectClicked(false);
                setIsContactAdmin(false);
                setIsRequestToModify(true);
                setShowRequestToModifyBox(true);
                setModifySuccessMessage(false);
                setModifyErrMessage(false);
                return false;
    
                })} >
                <FontAwesomeIcon icon={faEdit} className="animate-left-3 me-3 ms-2" />
                {btnLabel}
            </Button>);
        }
        
    }

    const rejectionButton = () => {
        return (<Button  className="animate-hover btn btn-sm btn-delete" onClick={()=>{
            //console.log("reject clicked");
            setIsRejectClicked(true);
            setIsRequestToModify(false);
            setIsContactAdmin(false);
            }}>
            <FontAwesomeIcon icon={faThumbsDown} className="animate-left-3 me-3 ms-2" />
            Reject
        </Button>);
    }
      
    const acceptButtonForCoAuthor = () => {
        return (<Button  className="animate-hover btn btn-sm btn-primary" onClick={handleSubmit(onCoAuthorAcceptClicked)}   >
        <FontAwesomeIcon icon={faThumbsUp} className="animate-left-3 me-3 ms-2" />
        Accept
        </Button> );
    }

    let [messageModalShow, setMessageModalShow] = useState(false);

    const handleOngoingCheckChange = (event) => {
        if (event.target.checked){
            setOngoingEmploymentCheck(1);
            setShowEndYear(0);
        }else{
            setOngoingEmploymentCheck(0);
            setShowEndYear(1);
        }
    }

    return (
        <> 
            
            {successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}    
            {errMessage && <Alert variant="danger">{errMessage}</Alert>}    
            <MessageModal modalShow={messageModalShow} handleModelClose={() => { setMessageModalShow(false);}} heading="Paper type" moduleName="paperTypeHelpMessage"  />
          
            <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>  
                { ((id !== undefined) && (id !== 0)) ? 
                    <>
                        { (getValues('Status') !== 1) && (getValues('sourceUserIsLoggedInUser') === 1) && (Number(relationTypeId) !== 8) && 
                            <Row className="mt-1" >
                                <Col className="callout callout-info">
                                <FontAwesomeIcon icon={faInfoCircle} className="animate-left-3 me-3 ms-2" />All users listed on the relation have been notified and asked to confirm the information. The user(s) will be asked to Accept, Reject or Request a modification. <br/> 
                                Whenever an action is taken by any user listed on the relation, you will receive a notification.<br/> 
                                You can view the status of the relation at any time by navigating to it via "List relations" in the top menu bar.
                                </Col>
                            </Row>
                        }

                        { ( (Number(relationTypeId) === 8) && (getValues('Status') !== 1)) && 
                        <Row className="mt-1" >
                            <Col className="callout callout-danger">
                            <FontAwesomeIcon icon={faInfoCircle} className="animate-left-3 me-3 ms-2" />This institution you linked is not in our approved list. Once approved, the relation status will become validated.
                            </Col>
                        </Row>}

                        { (getValues('DBLPImportedEntry') === 1) ?
                            <Row className="mt-1" >
                                <Col className="callout callout-warning">
                                <FontAwesomeIcon icon={faInfoCircle} className="animate-left-3 me-3 ms-2" />This relation was automatically created from DBLP. To request modifications to it, please contact the admin using the "Contact admin" button below.
                                </Col>
                            </Row>
                            :
                            <Row className="mt-1" >
                                <Col className="callout callout-info">
                                    <FontAwesomeIcon icon={faInfoCircle} className="animate-left-3 me-3 ms-2" />
                                    
                                        { ( (getValues('sourceUserIsLoggedInUser') === 1) && (getValues('DBLPImportedEntry') === 0)  ) && 
                                            <>As the person who created the relation, you can directly make modifications to it. When you do, please leave a comment noting what you have done. You can also leave a comment as a response to other users.</>
                                        }
                                        
                                        {(getValues('relUserIsLoggedInUser') === 1) && <>
                                        To request modification for any of the read-only fields, leave a comment by clicking "Add Comment" button. The request will go to the user who created the relation, who can then make the modifications. If in doubt, you can also contact the admin.</>}

                                    
                                </Col>
                            </Row>
                        }                        
                        </>
                    :
                    <>
                    
                    { [1,2,4,5].includes(Number(relationTypeId)) &&
                        <Row className="mt-1" >
                            <Col className="callout callout-danger">
                                <FontAwesomeIcon icon={faInfoCircle} className="animate-left-3 me-3 ms-2" /> If the person you want to assign is not present in the dropdown below, you can type the email address of the person and click on <strong><em>Create "email"</em></strong> in the dropdown.
                            </Col>
                        </Row>
                    }
                    { [8].includes(Number(relationTypeId)) &&
                        <Row className="mt-1" >
                            <Col className="callout callout-danger">
                                <FontAwesomeIcon icon={faInfoCircle} className="animate-left-3 me-3 ms-2" /> If the institution you want to assign is not present in the dropdown below, you can type the name of the institution and click on <strong><em>Create "institution"</em></strong> in the dropdown. <br/> 
                                For example: ABC University <br/>
                               Our team will validate the institution name
                            </Col>
                        </Row>
                    }
                    <RelationDescription relationTypeId={relationTypeId} />
                    </>
                } 

                
                <Row className="mt-4">
                    <Col md={4} className="mb-3">
                        <Form.Group id="paperType">
                            <Form.Label className="required">Relation type</Form.Label>
                            <div className="col-sm-10"> 
                                <Select name="relationType" 
                                options={relationTypeDrpOptions}  
                                value={{value: relationTypeId, label:  relationTypeName }} 
                                isDisabled={((id !== undefined) && (id !== 0)) ? true : false}
                                onChange={event => {                                                            
                                        setRelationTypeName(event.label);
                                        setRelationTypeId(event.value);
                                        setValue("relationType",event.value);
                                        showHideFields(event.value);
                                        }}
                                
                                />
                            </div>
                        </Form.Group>
                    </Col>
                    
                    { (relationTypeId !== 'INSTITUTION_AFF') && <>
                        { (!coAutherRelationTypes.includes(Number(relationTypeId))) && (showPersonDrp) &&
                            <Col md={4} className="mb-3">
                                <Form.Group>
                                    <Form.Label className="required">Person name</Form.Label> 
                                    {((id !== undefined) && (id !== 0)) ? 
                                        <CreatableSelect 
                                            name="rUserId" 
                                            key={"PersonUserIdSelect"}                                 
                                            value={{value: getValues('rUserId'), label: relUserName}}
                                            isClearable
                                            closeOnScroll={true}
                                            isDisabled={true}                                                                                              
                                        />
                                        :
                                        <CreatableSelect 
                                            name="rUserId" 
                                            options={getValues('usersDrp')}  
                                            key={"PersonUserIdSelect"}                                 
                                            value={{value: getValues('rUserId'), label: relUserName}}
                                            isClearable
                                            closeOnScroll={true}
                                            isDisabled={false}
                                            onChange={e => {                                    
                                                    if (e === null){
                                                        setValue("rUserId",0);
                                                        setValue("rUserName",''); 
                                                        setRelUserName('');
                                                    }else if (e.value === e.label){                                           
                                                        setValue("rUserId",e.value);
                                                        setValue("rUserName",e.value); 
                                                        setRelUserName(e.label);                                           
                                                    }else{
                                                        setValue("rUserId",e.value);
                                                        setValue("rUserName",e.label); 
                                                        setRelUserName(e.label);
                                                    }                       
                                                }
                                            }
                                            filterOption={(option, searchText) => {
                                                if (
                                                    option.data.label.toLowerCase().includes(searchText.toLowerCase()) ||
                                                    ( (option.data.sec_email) && option.data.sec_email.toLowerCase().includes(searchText.toLowerCase()) )
                                                ){
                                                    return true;
                                                }else{
                                                    return false;
                                                }      
                                            }}                                                        
                                        />
                                    }
                                    
                                </Form.Group>
                            </Col>
                        }

                        {(Number(relationTypeId) === 8) &&
                        <Col md={4} className="mb-3">
                            <Form.Group>
                                <Form.Label className="required">Institution</Form.Label> 
                                { ( ((id !== undefined) && (id !== 0)) && (props.action === "view")) ? 
                                    <CreatableSelect 
                                        name="InstId" 
                                        key={"InstitutionSelect"}                                 
                                        value={{value: getValues('rInstitutionId'), label: rInstitutionName}}
                                        isClearable
                                        closeOnScroll={true}
                                        isDisabled={true}                                                                                              
                                    />
                                    :
                                    <CreatableSelect 
                                        name="InstId" 
                                        options={getValues('institutionDrp')}  
                                        key={"InstitutionSelect"}                                 
                                        value={{value: getValues('rInstitutionId'), label: rInstitutionName}}
                                        isClearable
                                        closeOnScroll={true}
                                        isDisabled={false}
                                        onChange={e => {                                    
                                                if (e === null){
                                                    setValue("rInstitutionId",0);
                                                    setValue("rInstitutionName",''); 
                                                    setRelInstitutionName('');
                                                    setRelInstitutionId(0);
                                                }else if (e.value === e.label){                                           
                                                    setValue("rInstitutionId",e.value);
                                                    setValue("rInstitutionName",e.value); 
                                                    setRelInstitutionName(e.value);   
                                                    setRelInstitutionId(e.value);                                        
                                                }else{
                                                    setValue("rInstitutionId",e.value);
                                                    setValue("rInstitutionName",e.label); 
                                                    setRelInstitutionName(e.label);
                                                    setRelInstitutionId(e.value);
                                                }                       
                                            }
                                        }                                                        
                                    />
                                }
                                
                            </Form.Group>
                        </Col>
                        }

                        { (showPublicationType > 0) &&
                            <Col md={6} className="mb-3">
                                <Form.Group id="paperType">
                                    <Form.Label onClick={() => {
                                            setMessageModalShow(true);
                                        }} >Paper type
                                        
                                            <FontAwesomeIcon icon={faQuestionCircle} className="me-3 ms-2 pe-auto" style={{cursor:"pointer"}} />
                                        
                                    </Form.Label>
                                    <div className="col-sm-10">
                                        <Form.Check                                            
                                                label="Not a research paper"
                                                inline
                                                name="paperTypeReadOnlyGroup"
                                                type={`checkbox`}
                                                value="2"
                                                checked={(2 === paperTypeId) ? true : false}
                                                id={`paperTypeReadOnlyGroup-inline-radio-1`}
                                                key={`paperTypeReadOnlyGroup-inline-radio-1`}
                                                onChange={event => {
                                                    if (event.target.checked){
                                                        setPaperTypeId(2);
                                                        //setPaperTypeName("Not a research paper");
                                                        setValue("paperType",2);
                                                    }else{
                                                        setPaperTypeId(1);
                                                        //setPaperTypeName("Research paper");
                                                        setValue("paperType",1);
                                                    }
                                                    
                                                }}
                                                disabled ={ ( ((id !== undefined) || (id !== 0)) && (getValues('relUserIsLoggedInUser') === 1) ) ||   ( ((id !== undefined) || (id !== 0)) && (getValues('sourceUserIsLoggedInUser') === 1) && (props.action === "view")) ? true : false}
                                            />

                                        <Form.Check                                            
                                                label="Community paper"
                                                inline
                                                name="paperTypeReadOnlyGroup"
                                                type={`checkbox`}
                                                value="1"
                                                checked={(1 === communityPaperId) ? true : false}
                                                id={`paperTypeReadOnlyGroup-inline-radio-2`}
                                                key={`paperTypeReadOnlyGroup-inline-radio-2`}
                                                disabled ={ ( ((id !== undefined) || (id !== 0)) && (getValues('relUserIsLoggedInUser') === 1) ) ||   ( ((id !== undefined) || (id !== 0)) && (getValues('sourceUserIsLoggedInUser') === 1) && (props.action === "view")) ? true : false}
                                                onChange={event => {
                                                    if (event.target.checked){
                                                        setCommunityPaperId(1);
                                                        setValue("communityPaperId",1);
                                                    }else{
                                                        setCommunityPaperId(0);
                                                        setValue("communityPaperId",0);
                                                    }
                                                    
                                                }}
                                            />
                                    </div>
                                </Form.Group>
                            </Col>
                        }
                        {/*
                        { (showPublicationType > 0) &&
                            <Col md={4} className="mb-3">
                                <Form.Group id="paperType">
                                    <Form.Label className="required" >Paper type</Form.Label>
                                    <div className="col-sm-10"> 
                                    { ((id === undefined) || (id === 0)) || (getValues('sourceUserIsLoggedInUser') === 1) ? 
                                        <Select name="paperType" options={paperTypes}  value={{value: paperTypeId, label:  paperTypeName }}  
                                            onChange={event => {
                                                setPaperTypeId(event.value);
                                                setPaperTypeName(event.label);
                                                setValue("paperType",event.value);
                                            }}
                                            
                                            />  
                                        :
                                        <>                                   
                                        {paperTypes.map((type, index) =>{ 
                                            return (
                                                <Form.Check
                                                inline
                                                label={type.label}
                                                name="paperTypeReadOnlyGroup"
                                                type={`radio`}
                                                value={type.value}
                                                checked={(Number(type.value) === paperTypeId) ? true : false}
                                                disabled
                                                id={`paperTypeReadOnlyGroup-inline-radio-1`}
                                                key={`paperTypeReadOnlyGroup-inline-radio-1${index}`}
                                            />
                                            )
                                        })}
                                        </>
                                    }
                                    
                                        
                                    </div>
                                </Form.Group>
                            </Col>
                        }*/}

                        </>}
                </Row>

                { (relationTypeId === 'INSTITUTION_AFF') && <>
                    <p className="mt-4">Please enter institutional affiliations on the Profile page</p>
                    <Button as={Link} to={Routes.Users.editpath} variant="primary"  className="animate-hover" ><FontAwesomeIcon icon={faUser} className="animate-left-3 me-3 ms-2" />Go to profile page</Button>
                </>}

                { (relationTypeId !== 'INSTITUTION_AFF') && <>

                    { (showPublicationDrp > 0) &&
                        <>
                        <Row className="mt-3">
                        <Col className="mb-3">
                            <Form.Group>
                                <Form.Label className="required">{pubFieldLabel}</Form.Label>
                                <div className="col-sm-14">
                                    {/*<CreatableSelect 
                                        filterOption={createFilter({ ignoreAccents: false })}
                                        captureMenuScroll={false}
                                        classNamePrefix="custom-select"

                                        name="publicationId" 
                                        options={allPublications}  
                                        key={"publicationSelect"}                                 
                                        value={{value: getValues('publicationId'), label: publicationName}}
                                        isClearable
                                        components={{ Option: CustomOption, MenuList: WindowedMenuList }}
                                        formatCreateLabel={userInput => `Create new - ${userInput}`}
                                        closeMenuOnSelect={true}
                                        closeOnScroll={true}
                                        isDisabled={((id !== undefined) && (id !== 0)) ? true : false}
                                        onChange={e => {
                                            if (e === null){
                                                setValue('publicationId','');
                                                setValue('publicationName','');
                                                setPublicationName('');  
                                            }else{
                                                setValue('publicationId',e.value);
                                                setValue('publicationName',e.label);
                                                setPublicationName(e.label);  
                                            }
                                                                                
                                            }
                                        }                                                        
                                    />*/}

                                    {(getValues('relUserIsLoggedInUser') === 1) ?
                                        <Form.Control type="text"  {...register("publicationName")} disabled   />
                                        :
                                        <>
                                        {
                                            ( ((id === undefined) || (id === 0)) || ((getValues('sourceUserIsLoggedInUser') === 1) && (props.action === "modify") )) ?
                                                <Form.Control type="text"  value={publicationName}  onChange={e => {
                                                    setPublicationName(e.target.value);
                                                }}                                    
                                                />
                                                :
                                                <Form.Control type="text"  value={publicationName} disabled  onChange={e => {
                                                    setPublicationName(e.target.value);
                                                }}                                    
                                                />

                                        }
                                        </>
                                        
                                    }


                                </div>
                                
                            </Form.Group>
                        </Col> 
                        </Row>

                        { (id !== undefined) && (id !== 0) && ( getValues('publicationDBLPUrl') && (getValues('publicationDBLPUrl') !== '') ) && 
                            <Row className="mt-3">
                                <Col className="mb-3">
                                    <Form.Group>
                                        <Form.Label className="required">Publication DBLP URL</Form.Label>
                                        
                                        <div className="col-sm-14">
                                            <>
                                                {getValues('publicationDBLPUrl')}
                                                <a href={getValues('publicationDBLPUrl')} target="_blank" rel="noreferrer" >
                                                    <FontAwesomeIcon icon={faExternalLinkAlt} className="animate-left-3 me-3 ms-2" />
                                                </a>
                                            </> 
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                        }
                        </>            
                    }
                

                    <Row className="mt-3">
                        
                        { (showStartYear === 1) &&
                            <Col md={4} className="mb-3">
                            {startDateMand ? <Form.Label className="required">{startDateLabel}</Form.Label> : <Form.Label>{startDateLabel}</Form.Label>}           
                            
                            {  ( ((id === undefined) || (id === 0)) || ((getValues('sourceUserIsLoggedInUser') === 1) && (props.action === "modify") ))  ?
                            
                                <div className="col-sm-10">
                                    {(startDateFormat === 2) ?
                                        <DatePicker
                                        className="form-control"
                                        name="dateStartYear"
                                        key={"startDate"}
                                        selected={startDate}
                                        isClearable
                                        closeOnScroll={true}
                                        showMonthYearPicker
                                        maxDate={aYearFromNowStartDate}
                                        dateFormat="MM/yyyy"  
                                        autoComplete='off' 
                                        disabled={ ( ((id !== undefined) && (id !== 0)) && (getValues('sourceUserIsLoggedInUser') !== 1)) ? true : false}
                                        onChange={(date) => {
                                            setErrorMessage('');
                                            if (!date && startDateMand) setErrorMessage(startDateLabel + " is a required field.");
                                            setStartDate(date);
                                            setValue("dateStartYear",moment(date).format("YYYY"));

                                        }}               
                                        />
                                        :
                                        <DatePicker
                                            className="form-control"
                                            name="dateStartYear"
                                            key={"startDate"}
                                            selected={startDate}
                                            isClearable
                                            closeOnScroll={true}
                                            showYearPicker
                                            dateFormat="yyyy"
                                            yearItemNumber={10}
                                            maxDate={aYearFromNowStartDate}
                                            autoComplete='off'
                                            disabled={ ( ((id !== undefined) && (id !== 0)) && (getValues('sourceUserIsLoggedInUser') !== 1)) ? true : false}
                                            onChange={(date) => {
                                                setErrorMessage('');
                                                if (!date && startDateMand) setErrorMessage(startDateLabel + " is a required field.");
                                                setStartDate(date);
                                                setValue("dateStartYear",moment(date).format("YYYY"));
                                            }}               
                                        />

                                    }                            
                                </div>
                                :
                                <div className="col-sm-10">
                                    { (endDateFormat === 2) ?
                                        <Form.Control type="text" value={getValues('dateStartMonth')+"/"+getValues('dateStartYear')} disabled  />
                                        :
                                        <Form.Control type="text" value={getValues('dateStartYear')} disabled  />
                                    }
                                </div>
                            }
                            </Col>
                        }

                        { (showEndYear === 1) &&
                            <>
                            <Col md={4} className="mb-3"  >
                            {endDateMand ? <Form.Label className="required" id="div-label-endDate" >{endDateLabel}</Form.Label> : <Form.Label>{endDateLabel}</Form.Label>}
                            
                            { ( ((id === undefined) || (id === 0)) || ((getValues('sourceUserIsLoggedInUser') === 1) && (props.action === "modify") ))  ?
                                <div className="col-sm-10" id="div-endDate">
                                    { (endDateFormat === 2) ?
                                        <DatePicker
                                        className="form-control"
                                        name="dateEndYear"
                                        key={"endDate"}
                                        selected={endDate}
                                        isClearable
                                        closeOnScroll={true}
                                        showMonthYearPicker
                                        dateFormat="MM/yyyy"
                                        maxDate={aYearFromNow}
                                        yearItemNumber={10}
                                        autoComplete='off'
                                        disabled={(((id !== undefined) && (id !== 0)) && (getValues('sourceUserIsLoggedInUser') !== 1)) ? true : false}
                                        onChange={(date) => {
                                        setErrorMessage('');
                                            if (!date && endDateMand) setErrorMessage(endDateLabel + " is a required field.");
                                            setEndDate(date);
                                            setValue("dateEndYear",moment(date).format("YYYY/MM"));
                                        }}               
                                    />
                                    :
                                        <DatePicker
                                            className="form-control"
                                            name="dateEndYear"
                                            key={"endDate"}
                                            selected={endDate}
                                            isClearable
                                            closeOnScroll={true}
                                            showYearPicker
                                            dateFormat="yyyy"
                                            yearItemNumber={10}
                                            maxDate={aYearFromNow}
                                            autoComplete='off'
                                            disabled={ ( ((id !== undefined) && (id !== 0)) && (getValues('sourceUserIsLoggedInUser') !== 1)) ? true : false}
                                            onChange={(date) => {
                                                setErrorMessage(''); 
                                                if (!date && endDateMand) setErrorMessage(endDateLabel + " is a required field.");                 
                                                setEndDate(date);
                                                setValue("dateEndYear",moment(date).format("YYYY"));
                                            }}               
                                        />


                                    }
                                </div>
                                :
                                <div className="col-sm-10">
                                    { (endDateFormat === 2) ?
                                        <Form.Control type="text" value={getValues('dateEndMonth')+"/"+getValues('dateEndYear')} disabled  />
                                        :
                                        <Form.Control type="text" value={getValues('dateEndYear')} disabled  />
                                    }
                                </div>
                            }
                            </Col>

                            
                            </>
                        }

                        {(ongoingEmploymentCheckShow === 1) &&
                            <Col md={2} className="mb-3">
                                <Form.Label>&nbsp;</Form.Label>                         
                                <Form.Group >
                                    <div className="form-check mt-2">
                                        <Form.Label className="form-check-label" htmlFor="same-address">Ongoing?</Form.Label>
                                        <input type="checkbox" name="ongoing_employment"  className="form-check-input"      value="1" 
                                            checked={(ongoingEmploymentCheck === 1) ? true : false} 
                                            disabled={( ((id === undefined) || (id === 0)) || ((getValues('sourceUserIsLoggedInUser') === 1) && (props.action === "modify") ))? false : true}

                                            onChange={e => handleOngoingCheckChange(e)} />
                                    </div>
                                </Form.Group>
                            </Col>
                        }

                        {/* ( relationTypeId === 4) &&
                            <Col md={4} className="mb-3" >
                                <Form.Label>&nbsp;</Form.Label>                         
                                <Form.Group >
                                    <div className="form-check mt-4">
                                        <Form.Label className="form-check-label" htmlFor="same-address">Current?</Form.Label>
                                        <input type="checkbox" name="current_employment"  className="form-check-input" onChange={e => handleCurrentCheckChange(e)}  />
                                    </div>
                                </Form.Group>
                            </Col>
                        */}
                    </Row>
                
                    {(Number(getValues('relationType')) === 8) ? 
                        <>
                        { ((id === undefined) || (id === 0)) ?

                            <Row className="mb-4">
                                <Col className="col-lg-6 col-md-12" >
                                <Form.Group>
                                    <Form.Label htmlFor="inputSName" className="col col-form-label">Notes</Form.Label>
                                    <textarea className="form-control" id="notes" rows="1" {...register("notes")}  />
                                </Form.Group>
                                </Col>
                            </Row>
                            :
                            <input type="hidden" id="notes"  {...register("notes")}  />
                        }
                        </>
                    :
                        <Row className="mb-4">
                            <Col className="col-lg-6 col-md-12" >
                            <Form.Group>
                                { ((id === undefined) || (id === 0)) && <Form.Label htmlFor="inputSName" className={`col col-form-label ${relationTypeId === 5 ? 'required' : ''}`}>{relationTypeId === 5 ? "Comments" : "Comments (Optional)"}</Form.Label>}

                                { ((id === undefined) || (id === 0)) ? 
                                    <>
                                    { ( ([1,2,4,5].includes(relationTypeId)) && (getValues('sourceUserIsLoggedInUser') === 1) ) ?
                                        <textarea className="form-control" id="notes" rows="1" {...register("notes")}  />
                                        :
                                        <textarea className="form-control" id="notes" rows="1" {...register("notes")} disabled={ ( ((id === undefined) || (id === 0)) ||  (getValues('sourceUserIsLoggedInUser') === 1) ) ? false : true}  />
                                    }                            
                                    {((id === undefined) || (id === 0))  && (showPublicationDrp > 0) && (relationTypeId === 3) &&   <small><em>If the paper is listed on DBLP, please add the URL. If not, but if the paper is elsewhere online, please add the URL to the paper in the comments box.</em></small>}
                                    </> 
                                    : 
                                    <>
                                    <input type="hidden" id="notes"  {...register("notes")}  />
                                    </>
                                }
                                
                            </Form.Group>
                            </Col>
                        </Row> 
                    }
                
                    { showCoauthorsBlock ? 
                        <>
                            <h4>{ ((id === undefined) || (id === 0)) ? `Adding ${coauthorAddFieldLabelPlural}` : `${coauthorAddFieldLabelPlural}`  }</h4>
                            
                            
                            
                            { ( ((id === undefined) || (id === 0)) || ( (getValues('sourceUserIsLoggedInUser') === 1) && (props.action === "modify") ) ) &&
                            <>
                                
                                <Row className="mt-1" >
                                    <Col className="callout callout-danger">
                                        <FontAwesomeIcon icon={faInfoCircle} className="animate-left-3 me-3 ms-2" /> If the person you want to assign is not present in the dropdown below, you can type the email address of the person and click on <strong><em>Create "email"</em></strong> in the dropdown.
                                    </Col>
                                </Row>
                                <Row className="mb-2 mt-2">
                                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label">{coauthorAddFieldLabelSingu}</label>
                                    <div className="col-sm-6">
                                    <CreatableSelect 
                                            name="userId" 
                                            options={getValues('usersDrp')}  
                                            key={"userIdSelect"}                                 
                                            value={{value: getValues('coAuthId'), label: coAuthLabelName }}
                                            isClearable
                                            closeOnScroll={true}
                                            onChange={e => {

                                                    if (e === null){
                                                        setValue("coAuthId",0);
                                                        setValue("coAuthLabel",''); 
                                                        setcoAuthName('');
                                                    }else if (e.value === e.label){                                           
                                                        setValue("coAuthId",e.value);
                                                        setValue("coAuthLabel",e.value); 
                                                        setcoAuthName(e.label);                                           
                                                    }else{
                                                        setValue("coAuthId",e.value);
                                                        setValue("coAuthLabel",e.label); 
                                                        setcoAuthName(e.label);
                                                    }       
                                                }
                                            }
                                            filterOption={(option, searchText) => {
                                                if (
                                                    option.data.label.toLowerCase().includes(searchText.toLowerCase()) ||
                                                    ( (option.data.sec_email) && option.data.sec_email.toLowerCase().includes(searchText.toLowerCase()) )
                                                ){
                                                    return true;
                                                }else{
                                                    return false;
                                                }      
                                            }}                                                        
                                        />
                                    </div>
                                    <div className="col-sm-4 text-align-left">
                                        <Button className="animate-hover btn btn-sm btn-addnew" onClick={()=>addCoAuthor()}  >
                                            <FontAwesomeIcon icon={faPlus} className="animate-left-3 me-3 ms-2" />
                                            Add {coauthorAddFieldLabelSingu} 
                                        </Button>
                                    </div>
                                </Row>
                            </>
                            }

                            {saveAuthorFlag && (!showCoauthorList) &&
                                <Alert variant="info" className="mt-4"><Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{marginRight:10}}
                                />Please wait while we add the new {coauthorAddFieldLabelSingu}...</Alert>
                            }
                    
                            {showCoauthorList && inputList && (inputList.length > 0) &&
                                <Row className=" pt-1" >
                                    <Col>

                                    <table className="table table-striped personnames-table">
                                        <thead>
                                            <tr>
                                            <td align="right">#</td>
                                            <td width="50%" >{coauthorAddFieldLabelSingu}</td>
                                            <td align="center" >Status</td>
                                            <td align="center"  ></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <DynamicCoauthor id={id} getValues={getValues} inputList={inputList} setInputList={setInputList} coauthorData={props.coauthorData}  userActionRowData={userActionRowData} />
                                            { ((id !== undefined) && (id !== 0)) &&
                                                <tr className="mb-0" >
                                                    <td align="right" valign="middle">{(Object.keys(inputList).length + 1)}</td>
                                                    <td><Select value={ { value: getValues('LoggedInUserFullName'), label: getValues('LoggedInUserFullName')}} isDisabled={true}   /></td>
                                                    <td valign="middle" align="center" >
                                                        {/** If the related user has accepted it and its in other state */}
                                                        { ((getValues('Status') !== 1) && (Number(commentsObj.data.accepted) === 1)) ?
                                                        statusPill('ACCEPTED')
                                                        :
                                                        <>
                                                            {(getValues('Status') === 4) && (Number(commentsObj.data.rejected) === 1) ?
                                                                statusPill('REJECTED')
                                                                :
                                                                <>
                                                                {(getValues('Status') === 4) ?
                                                                    statusPill('PENDING')
                                                                    :
                                                                    statusPill(getValues('Status'))
                                                                }
                                                                </>
                                                            }
                                                        </>
                                                        }
                                                    
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            }
                                        
                                        </tbody>
                                    </table>  
                                        
                                    </Col>
                                </Row>
                            }
                        
                            {/*<p>{JSON.stringify(inputList)}</p>*/}
                            
                        </>
                        :
                        <>
                        { (id !== undefined) && (id !== 0) && [1,2,4,5].includes(Number(getValues('relationType'))) && 
                            <Row className="mb-4 pt-1" >
                                <Col>
                                    <table className="table table-striped personnames-table">
                                        <thead>
                                            <tr>
                                            <td align="right" valign="middle">#</td>
                                            <td width="50%" >Person name</td>
                                            <td align="center" >Status</td>
                                            <td align="center"  ></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td align="right" valign="middle">1</td>
                                                <td>{getValues('LoggedInUserFullName')}</td>
                                                <td valign="middle" align="center" >
                                                        {/** If the related user has accepted it and its in other state */}
                                                    
                                                        { ((getValues('Status') !== 1) && (Number(commentsObj.data.accepted) === 1)) ?
                                                        statusPill('ACCEPTED')
                                                        :
                                                        <>
                                                            {(getValues('Status') === 4) && (Number(commentsObj.data.rejected) === 1) ?
                                                                statusPill('REJECTED')
                                                                :
                                                                <>
                                                                {(getValues('Status') === 4) ?
                                                                    statusPill('PENDING')
                                                                    :
                                                                    <>
                                                                    {(getValues('Status') === 1) ?
                                                                        statusPill('ACCEPTED')
                                                                        :
                                                                        statusPill(getValues('Status'))
                                                                    }
                                                                    </>
                                                                    
                                                                }
                                                                </>
                                                            }
                                                        </>
                                                        }
                                                    
                                                    </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td align="right" valign="middle">2</td>
                                                <td>{relUserName}</td>
                                                <td valign="middle" align="center" >
                                                        
                                                    { ( userActionRowData && userActionRowData[getValues('rUserId')] === 'ACCEPTED') ?
                                                        statusPill('ACCEPTED')
                                                        :
                                                        <>
                                                            {( userActionRowData && userActionRowData[getValues('rUserId')] === 'REJECTED') ?
                                                                statusPill('REJECTED')
                                                                :
                                                                <>
                                                                {(getValues('Status') === 4) ?
                                                                    statusPill('PENDING')
                                                                    :
                                                                    <>
                                                                    {(getValues('Status') === 1) ?
                                                                        statusPill('ACCEPTED')
                                                                        :
                                                                        statusPill(getValues('Status'))
                                                                    }
                                                                    </>
                                                                }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                    
                                                    
                                                    </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        }
                        </>
                    }

                    {/** Only for the source user we will show the comments box and force user to fill it before clickign save */}
                    { (getValues('sourceUserIsLoggedInUser') === 1) && (props.action === "modify") && 
                        <>
                            <Row className="mb-5">
                                <Col className="col" >
                                    <Form.Group>
                                        <Form.Label htmlFor="sourceUserUpdateComment" className="col col-form-label required">Comment <small>(Max: 1000 characters)</small></Form.Label>
                                        <textarea className="form-control" id="sourceUserUpdateComment" maxLength="1000" rows="5" onKeyUp={(e)=>{
                                            if (e.target.value !== ''){
                                                setCommentCharsLeft(1000 - Number(e.target.value.length));
                                                setValue("sourceUserUpdateComment",e.target.value);
                                                setSourceUserUpdateComment(e.target.value);
                                            }else{
                                                setCommentCharsLeft(0);
                                                setValue("sourceUserUpdateComment","");
                                                setSourceUserUpdateComment("");
                                            }
                                        }}  />
                                        <small>Characters left: {commentCharsLeft}</small>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </>
                    }
                
                    <Card.Footer className="mb-0"> 
                        { ((id === undefined) || (id === 0)) && 
                            <Row><Col className="mt-3 callout callout-danger">Once you click "Create the relation", all users listed on the relation will be notified and asked to confirm the information. The user(s) will be able to Accept, Reject or request a modification by adding a comment to the relation.
                            </Col></Row> 
                        }
                        <Row className="mb-2">
                            <Col>
                                
                                { (id !== undefined) && (id !== 0) ?
                                    <>
                                    {(startSave) ?
                                        <div className="text-right"><Button variant="primary" type="button"  className="animate-hover" ><Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{marginRight:10}}
                                        />Please wait...</Button> </div>
                                    :
                                
                                    
                                        <>
                                            {(!isContactAdmin) && (!isRejectClicked) && (!isRequestToModify) &&

                                                <>
                                            

                                        {/* If the relation type is pending employment */}
                                        { (Number(getValues('relationType')) === 8) && (getValues('sourceUserIsLoggedInUser') === 1) &&
                                            <Row>
                                                <Col>
                                                    <div className="mt-3 text-right" >
                                                        {requestModifyOrCommentButton("Add comment")}
                                                        {saveRelationButton()}
                                                        {cancelButton()}
                                                        {contactAdminButton()}
                                                    </div>
                                                </Col>
                                            </Row>
                                        }

                                        {/* If the relation type is co-author */}
                                        { (coAutherRelationTypes.includes(Number(getValues('relationType')))) && (Number(getValues('relationType')) !== 8) &&
                                            <> 
                                                {/* If the source user has logged in. we will allow to further modify the relation */}
                                                {(getValues('sourceUserIsLoggedInUser') === 1) &&
                                                <Row>
                                                    <Col>
                                                        <div className="mt-3 text-right" >                                                
                                                            {requestModifyOrCommentButton("Add comment")}
                                                            {saveRelationButton()}  
                                                            {cancelButton()}
                                                            {contactAdminButton()}
                                                        </div>
                                                    </Col>
                                                </Row>                                 
                                                }

                                                {/* If the related user has logged in */}
                                                {(getValues('relUserIsLoggedInUser') === 1) &&
                                                <>

                                                    {/** If the related user has accepted it and its in other state */}
                                                    {(getValues('Status') !== 1) && (Number(commentsObj.data.accepted) === 1) && 
                                                        <Row>
                                                            <Col className="text-success text-right">You have accepted this relation.</Col>
                                                        </Row>
                                                    }

                                                    {/** If the related user has rejected it and its in disputed state */}
                                                    {(getValues('Status') === 4) && (Number(commentsObj.data.rejected) === 1) && 
                                                        <Row>
                                                            <Col className="text-danger text-right">You have rejected this relation.</Col>
                                                        </Row>
                                                    }

                                                    {/* pending state / disputed */}
                                                    { ((getValues('Status') === 3) || (getValues('Status') === 4)) && 
                                                        <Row>
                                                            <Col>
                                                                <div className="mt-3 text-right" >                                                        
                                                                    {/** In case disputed, we need to allow the otehr user to accept again. */}
                                                                    {((getValues('Status') === 4) && (Number(commentsObj.data.accepted) === 0)) && acceptButtonForCoAuthor()}
                                                                    
                                                                    {/** In case status is pending, we conly show the button if not accepted before. */}
                                                                    {(getValues('Status') === 3) && commentsObj && commentsObj.data && (Number(commentsObj.data.accepted) === 0) && acceptButtonForCoAuthor()}                                                
                                                                    {requestModifyOrCommentButton(modifyBtnlabel)}

                                                                
                                                                    {commentsObj && commentsObj.data && (Number(commentsObj.data.rejected) === 0) && rejectionButton()}

                                                                    {cancelButton()}
                                                                    {contactAdminButton()}
                                                                </div>
                                                            </Col>
                                                        </Row> 
                                                    }

                                                    {/** Validated relation , related user */}
                                                    {(getValues('Status') === 1) && 
                                                        <Row>
                                                            <Col>
                                                                <div className="mt-3 text-right" > 
                                                                    {requestModifyOrCommentButton(modifyBtnlabel)}
                                                                    {cancelButton()}
                                                                    {contactAdminButton()}
                                                                </div>
                                                            </Col>
                                                        </Row> 
                                                    }
                                                </>
                                                }
                                            </>
                                        }

                                        {/* If the related user has logged in */}
                                        {(getValues('relUserIsLoggedInUser') === 1) && (Number(getValues('relationType')) !== 8)  && (!coAutherRelationTypes.includes(Number(getValues('relationType'))))  && 
                                            <>
                                                {/* disputed/rejected state */}
                                                {(getValues('Status') === 4) && 
                                                    <>
                                                        <div className="text-danger text-right">
                                                            This relation request was rejected by you.                                             
                                                        </div>
                                                    </>
                                                }

                                                {/* pending state */}
                                                { ((getValues('Status') === 3) || (getValues('Status') === 4)) && 
                                                <>
                                                    <div className="mt-3 text-right" >
                                                        {(startAcceptProcess) ? 
                                                            <Button variant="primary" type="button"  className="animate-hover" ><Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                            style={{marginRight:10}}
                                                            />Please wait...</Button> 
                                                            :
                                                            <Button  className="animate-hover btn btn-sm btn-primary" onClick={handleSubmit(onAcceptClicked)}  >
                                                                <FontAwesomeIcon icon={faThumbsUp} className="animate-left-3 me-3 ms-2" />
                                                                Accept
                                                            </Button>
                                                        }
                                                        
                                                        {requestModifyOrCommentButton(modifyBtnlabel)}
                                                        {(getValues('Status') === 3) && rejectionButton()}
                                                        {cancelButton()}
                                                        {contactAdminButton()}
                                                    </div>
                                                </>
                                                }

                                                


                                                {/* validated state */}
                                                { (getValues('Status') === 1)  && 
                                                <> 
                                                {/* we will not allow to further modify the relation */}
                                                {/**     <Button variant="primary" type="submit"  className="animate-hover" ><FontAwesomeIcon icon={faSave} className="animate-left-3 me-3 ms-2" />Save</Button>*/}
                                                {/** If any modifications are needed they need to request the admin. */}
                                                <Row>
                                                    <Col>
                                                        <div className="mt-3 text-right" >
                                                            {requestModifyOrCommentButton(modifyBtnlabel)}
                                                            {cancelButton()}
                                                            {contactAdminButton()}
                                                        </div>
                                                    </Col>
                                                </Row>                                    
                                                </>
                                                }
                                            </>
                                        }

                                        {/* If the source user has logged in and not co-auther type*/}
                                        {(getValues('sourceUserIsLoggedInUser') === 1) && (Number(getValues('relationType')) !== 8) && (!coAutherRelationTypes.includes(Number(getValues('relationType')))) && 
                                            <>

                                            {(getValues('coAuthorStatus') === 2) ? 
                                                <Row>
                                                    <Col>
                                                        <div className="mt-3 text-right" >
                                                            {cancelButton()}
                                                            {contactAdminButton()}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            :
                                            <>  
                                            {/* pending state and if first time the relation is added */}
                                            {(getValues('Status') === 3)  && 
                                                <>
                                                    {(!commentsRowData) && 
                                                        <Row>
                                                            <Col>
                                                                <div className="text-danger text-right">
                                                                    Your relation request has been sent. Awaiting the other user to respond to this relation. 
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    
                                                    <Row>
                                                        <Col>
                                                            <div className="mt-3 text-right" >
                                                                {requestModifyOrCommentButton("Add comment")}
                                                                {/** For source user, family type there are only two fields and both disabled.. so no point showing save btn */}
                                                                {saveRelationButton()}                                                   
                                                                {cancelButton()}
                                                                {contactAdminButton()}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </>
                                            }

                                            {/* disputed/rejected state */}
                                            {(getValues('Status') === 4) && 
                                                <>
                                                    <div className="text-danger text-right">
                                                        Your relation request has been rejected by the other user.     
                                                    </div>
                                                    <div className="mt-3 text-right" >
                                                        {requestModifyOrCommentButton("Add comment")}
                                                        {saveRelationButton()}   
                                                        {cancelButton()}
                                                        {contactAdminButton()}
                                                    </div>
                                                </>
                                            }

                                            {/* validated state, family type and other binary */}
                                            {(getValues('Status') === 1) && 
                                                <> 
                                                {/* we will not allow to further modify the relation */}
                                                {/**     <Button variant="primary" type="submit"  className="animate-hover" ><FontAwesomeIcon icon={faSave} className="animate-left-3 me-3 ms-2" />Save</Button>*/}
                                                {/** If any modifications are needed they need to request the admin. */}
                                                
                                                <Row>
                                                    <Col>
                                                        <div className="mt-3 text-right" >
                                                            {requestModifyOrCommentButton("Add comment")}
                                                            {saveRelationButton()}                                          
                                                            {cancelButton()}
                                                            {contactAdminButton()}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                
                                                </>
                                            }

                                            </>
                                            
                                            }

                                            

                                            
                                            
                                            </>
                                        }

                                                </>
                                            }
                                        </>

                                    }
                                    </>
                                :

                                <>
                                {/* Add case we will show save */}
                                <Row>
                                    <Col>
                                        <div className="mt-3 text-right" >
                                            {saveRelationButton()}
                                            {cancelButton()}
                                        </div>
                                    </Col>
                                </Row>
                                </>
                                }

                                
                            </Col>
                        </Row>
                    </Card.Footer>   
                
                    {isRejectClicked && 
                        <>
                            {modifyErrMessage && <Alert variant="danger">{modifyErrMessage}</Alert>}
                            <h4>Add your comment</h4>
                            <div>Add your comments in the box below and mention in brief why you are rejecting this relation.</div>
                            <Row className="mb-2">
                                <Col className="col" >
                                    <Form.Group>
                                        <Form.Label htmlFor="rejectionComment" className="col col-form-label required">Reason for rejection <small>(Max: 1000 characters)</small></Form.Label>
                                        <textarea className="form-control" id="rejectionComment" maxLength="1000" rows="5" onKeyUp={(e)=>{
                                            if (e.target.value !== ''){
                                                setCharsLeft(1000 - Number(e.target.value.length));
                                                setValue("rejectionComment",e.target.value);
                                            }else{
                                                setCharsLeft(0);
                                                setValue("rejectionComment","");
                                            }
                                        }}  />
                                        <small>Characters left: {charsLeft}</small>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-4 text-right">
                                <Col className="col" >
                                    {startRejectProcess ?
                                        <Button variant="primary" type="button"  className="animate-hover" ><Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{marginRight:10}}
                                        />Please wait...</Button>
                                        :
                                        <>
                                        <Button variant="primary" type="submit"  className="animate-hover" onClick={handleSubmit(onRejectClicked)} ><FontAwesomeIcon icon={faPaperPlane} className="animate-left-3 me-3 ms-2" />Submit</Button>
                                        <Button className="animate-hover btn btn-sm btn-secondary" onClick={()=>{
                                            setIsRejectClicked(false);
                                            setIsRequestToModify(false);
                                            setIsContactAdmin(false);
                                            }} >
                                            <FontAwesomeIcon icon={faTimes} className="animate-left-3 me-3 ms-2" />
                                            Cancel
                                        </Button>                                    
                                        </>
                                    }
                                </Col>
                            </Row>
                        </>
                    }

                    {showSendingMessage && 
                        <Alert variant="info" className="mt-4"><Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{marginRight:10}}
                        />Please wait and do not close browser...</Alert>
                    }

                    {isRequestToModify && 
                        <>

                            {modifySuccessMessage && !modifyErrMessage && <Alert variant="success">{modifySuccessMessage}</Alert>}    
                            {modifyErrMessage && <Alert variant="danger">{modifyErrMessage}</Alert>}
                            {showRequestToModifyBox && 
                            <>
                            <h4>Add your comment</h4>
                            <div>Add your comments in the box below and mention in brief what changes are needed. A mail will be sent to all the users involved in this relation.</div>
                                <Row className="mb-2">
                                    <Col className="col" >
                                        <Form.Group>
                                            <Form.Label htmlFor="modificationsComment" className="col col-form-label required">Comments <small>(Max: 1000 characters)</small></Form.Label>
                                            <textarea className="form-control" id="modificationsComment" maxLength="1000" rows="5" onKeyUp={(e)=>{
                                                if (e.target.value !== ''){
                                                    setCharsLeft(1000 - Number(e.target.value.length));
                                                    setValue("modificationsComment",e.target.value);
                                                }else{
                                                    setCharsLeft(0);
                                                    setValue("modificationsComment","");
                                                }
                                            }}  />
                                            <small>Characters left: {charsLeft}</small>
                                        
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mb-4 text-right">
                                    <Col className="col" >
                                        <Button variant="primary" type="submit"  className="animate-hover" onClick={handleSubmit(SaveModification)} ><FontAwesomeIcon icon={faPaperPlane} className="animate-left-3 me-3 ms-2" />Send</Button>
                                        <Button className="animate-hover btn btn-sm btn-secondary" onClick={()=>{
                                            setIsRejectClicked(false);
                                            setIsContactAdmin(false);
                                            setIsRequestToModify(false);
                                            setShowRequestToModifyBox(false);
                                            setModifySuccessMessage(false);
                                            setModifyErrMessage(false);
                                            }} >
                                            <FontAwesomeIcon icon={faTimes} className="animate-left-3 me-3 ms-2" />
                                            Cancel
                                        </Button>                                
                                    </Col>
                                </Row>
                            </>
                            }
                            
                        </>
                    }


                    {isContactAdmin && 
                        <>                    
                            {contactAdminSuccessMessage && !contactAdminErrMessage && <Alert variant="success">{contactAdminSuccessMessage}</Alert>}  
                            {contactAdminErrMessage && <Alert variant="danger">{contactAdminErrMessage}</Alert>}                        
                            {showContactAdminBox && 
                            <>
                                <h4>Contact admin</h4>
                                <div>Add your comments in the box below and mention in brief what changes are needed. Use this feature only if you need to send a mail to administrator. No mail will be sent to the other users in this relation.</div>
                                <Row className="mb-2">
                                    <Col className="col" >
                                        <Form.Group>
                                            <Form.Label htmlFor="contactAdminComment" className="col col-form-label required">Comments <small>(Max: 1000 characters)</small></Form.Label>
                                            <textarea className="form-control" id="contactAdminComment" maxLength="1000" rows="5" onKeyUp={(e)=>{
                                                if (e.target.value !== ''){
                                                    setCharsLeft(1000 - Number(e.target.value.length));
                                                    setValue("contactAdminComment",e.target.value);
                                                }else{
                                                    setCharsLeft(0);
                                                    setValue("contactAdminComment","");
                                                }
                                            }}  />
                                            <small>Characters left: {charsLeft}</small>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mb-4 text-right">
                                    <Col className="col" >
                                    <Button variant="primary" type="submit"  className="animate-hover" onClick={handleSubmit(onContactAdmin)} ><FontAwesomeIcon icon={faPaperPlane} className="animate-left-3 me-3 ms-2" />Send</Button>
                                    <Button className="animate-hover btn btn-sm btn-secondary" onClick={()=>{
                                        setContactAdminErrMessage(false);
                                        setIsContactAdmin(false);
                                        setShowContactAdminBox(false);   
                                        setIsRejectClicked(false); 
                                        setIsRequestToModify(false); 
                                        }} >
                                        <FontAwesomeIcon icon={faTimes} className="animate-left-3 me-3 ms-2" />
                                        Cancel
                                    </Button>                                
                                    </Col>
                                </Row>
                            </>
                            }
                            
                        </>
                    }
                </>
                }
            </Form>
            {((id !== undefined) && (id !== 0))  && commentsObj && <ModifyCommentList rowData={commentsRowData} obj={commentsObj} />}
        </>
    );
}
export default RelationEdit;