import { Col, Row,  Form,   Alert} from '@themesberg/react-bootstrap';
import ModuleEditScreenButtons  from "../../project/components/ModuleEditScreenButtons";
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';
import { Routes } from "../../routes";
import { useForm } from "react-hook-form";
import React,{useState} from "react";
import { PasswordCheck, passRules } from "../../framework/utilities/utilities";

const PasswordResetForm = (props) => {
    const heading = "Password Reset";
    const { register, handleSubmit, setValue  } = useForm();
    const [startSave,setStartSave] = useState(false);
    
    const [successMessage,setSuccessMessage] = useState('');
    const [errMessage,setErrorMessage] = useState('');
    const API_URL = process.env.REACT_APP_API_URL;
    setValue("password","");
    setValue("confirmPassword","");


    const onSubmit = postedData => {
        setStartSave(true);
        
        let sessionUser = JSON.parse(localStorage.getItem("profile"));

        console.log(postedData.password);

        // lets check if password and confirm password is same and also validate email
        if ( (!PasswordCheck(postedData.password))  )
        {
            setStartSave(false);                
            setErrorMessage(passRules());
            return false;
        }else if (postedData.password !== postedData.confirmPassword){
            setErrorMessage("Password and confirm password should match."); 
            setStartSave(false);         
            return false;
        }else{
            setErrorMessage('');
        }

        let formData = new FormData();
        
        
        formData.append("email", sessionUser.Email);
        
        formData.append("password", postedData.password);
        formData.append("confirmPassword", postedData.confirmPassword);
        
        fetch(API_URL + '/v1/web/user/loggedin-password-reset', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
            body: formData
        })
            .then(response => response.json())
            .then(jsondata => {
                if (jsondata.id){
                    setSuccessMessage("Password updated successfully.");
                }else{
                    setErrorMessage("Password could not be updated.");                                      
                }                
                setStartSave(false); 
               
            }).catch((err) => {
                console.log("Login error: " + err);
                setErrorMessage("Password could not be updated.");
                setStartSave(false); 
            });
            
    
    }

    return (
        <>
            <ModuleBreadcrumb links={[{ label:heading } ]}  />
            {successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}            
            {errMessage && <Alert variant="danger">{errMessage}</Alert>} 
            
            <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
               
                <Row className="mb-4">
                    <Col md={6} sm={12}>
                        <Row>
                            <Col className="mb-3">
                                <Form.Group id=" ">
                                    <Form.Label className="required">New Password</Form.Label>
                                    <Form.Control required  {...register("password")} name="password" type="password" placeholder="" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3">
                                <Form.Group id=" ">
                                    <Form.Label className="required">Confirm password</Form.Label>
                                    <Form.Control required  {...register("confirmPassword")} name="confirmPassword" type="password" placeholder="" />
                                    
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="password-rules-box" style= { {marginLeft: 10} }>{passRules()}</Col>
                </Row>
                <ModuleEditScreenButtons  showSave={true}  startSave={startSave} cancelPath={Routes.DashboardOverview.path} />
                    
            </Form>
                
        </>
     );
}
 
export default PasswordResetForm;