import React,{ useState } from 'react';
import ModuleBreadcrumb from '../project/components/ModuleBreadcrumb';
import { Col, Row, Form,Button, Alert, Spinner} from '@themesberg/react-bootstrap';
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import { scrollToTop } from "../framework/utilities/utilities";
import Logout from "../framework/utilities/Logout";
import MessageModal from '../project/components/MessageModal';

const ContactUsPage = () => {
    const heading = "Contact us";

    const { register, handleSubmit, setValue } = useForm();
    const [errMessage,setErrorMessage] = useState('');
    const [startSave,setStartSave] = useState(false);
    const [showForm,setShowForm] = useState(true);
    const API_URL = process.env.REACT_APP_API_URL;
    const [messageModalShow, setMessageModalShow] = useState(false);
    
    function validateForm(postedData){
        let errors = {};
        let formIsValid = true;
        if (postedData.Subject === ""){
            errors["Subject"] = "Please enter the subject";
            formIsValid = false;
        }
        if (postedData.Message === ""){
            errors["Message"] = "Please enter the message";
            formIsValid = false;
        }
        if (!formIsValid){
            return (
                <>
                    <strong>Please check below fields:</strong><ul>
                    {
                        Object.keys(errors).map(key => {                                               
                            return(<li key={`error-${key}`}>{errors[key]}</li>)
                        })
                    }
                    </ul>
                </>
            ) 
        }else{
            return false;
        }
    }

    const onSubmit = postedData => {
        //console.log(postedData);
        setErrorMessage("");
        setStartSave(true);
        setShowForm(true);
        setMessageModalShow(false);

        const errMesgs = validateForm(postedData);
        if (errMesgs) {
            setErrorMessage(errMesgs);
            setStartSave(false);
            scrollToTop();
            return false;
        }

        let formData = new FormData();
        formData.append("Subject",postedData.Subject);
        formData.append("Message",postedData.Message);

        fetch(API_URL + "/v1/web/contactus", {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
            body: formData
        })
        .then(response => response.json())
        .then(jsondata => {
            if (jsondata === "Unauthorized"){
                Logout();
            }else if (jsondata.message === "SUCCESS"){
                setValue("Subject","");
                setValue("Message","");
                setStartSave(false);
                setMessageModalShow(true);
                //setShowForm(false);      
            }else{
                setErrorMessage("Sorry! Your message could not be sent. Please try after some time.") 
            }
              
        }).catch((err) => {
            setErrorMessage("Sorry! Your message could not be sent. Please try after some time.") 
            console.log("Login error: " + err);           
        });

       

    }

    return (
        <>
            <MessageModal modalShow={messageModalShow} handleModelClose={() => { setMessageModalShow(false);}} heading="Thank you!" message="An email has been sent to the admin team. A response (if any) will be sent to your primary email address."  />
            <ModuleBreadcrumb links={[ { label: heading } ]}  />
            <h4>{heading}</h4> 
            {errMessage && <Alert variant="danger">{errMessage}</Alert>}  
            
            { showForm && <Form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
                <p className="mt-4">Use this form to email the admin team. A response (if any) will be sent to your primary email address. This interaction will not be logged in ConflictDB.</p>
                <Row className="mt-4">
                    <Col md={6}>
                        <Form.Group id="Subject">
                            <Form.Label className="required">Subject</Form.Label>
                            <Form.Control type="text" {...register("Subject")} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-4 mb-4">
                    <Col md={6} className="mb-3">
                        <Form.Group>
                            <Form.Label htmlFor="Message" className="col col-form-label required">Message</Form.Label>
                            <textarea className="form-control" id="Message" rows="4" {...register("Message")}  />
                        </Form.Group>
                    </Col>
                </Row>
                
                <Row>   
                    <Col md={6}>
                        <div className="mt-3 d-flex justify-content-end">
                            { (startSave) ? 
                                <Button variant="primary" type="button"   ><Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{marginRight:10}}
                                />Please wait...</Button>
                                :
                                <Button variant="primary" type="submit"  className="animate-hover" >
                                    <FontAwesomeIcon icon={faPaperPlane} className="animate-left-3 me-3 ms-2" />
                                Send    
                                </Button>
                            }
                            
                        </div>
                    </Col>                    
                </Row>
                
            </Form>}
        </>
    )
}

export default ContactUsPage;
