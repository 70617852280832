
import RelationListComponent from './ListComponent';
import {  Row, Col, Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faListAlt, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Routes } from "../../routes";
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';

const RelationListAll = () => {

    const { pathname } = useLocation();
    const history = useHistory();

    let filterBy = "";
    
    if (pathname.includes("/relations/pending")){
        filterBy = 'PENDING';
    }else{
        filterBy = '';
    }
   

    return (
        <>  
            { (filterBy === "PENDING") ? 
                <ModuleBreadcrumb links={[ { label:"List all relations", linkto: Routes.Relations.listall }, {label: "Pending relations"} ]}  />
                :
                <ModuleBreadcrumb links={[ { label: "List relations" } ]}  />
            }
             
            <Row className="text-right">
                <Col>
                    { (filterBy === "PENDING") ? 
                        <Button className="animate-hover btn btn-sm btn-secondary" onClick={()=>{
                            if (history.length > 0){
                                history.goBack();
                            }else{
                                history.push(Routes.Relations.listall);
                            }
                        }} >
                            <FontAwesomeIcon icon={faArrowLeft} className="animate-left-3 me-3 ms-2" />
                            Back to all relations
                        </Button>
                        :
                        <>
                        <Button as={Link} className="animate-hover btn btn-sm" to={Routes.Relations.pendingList}  >
                            <FontAwesomeIcon icon={faListAlt} className="animate-left-3 me-3 ms-2" />
                            List Pending relations
                        </Button>
                        <Button as={Link} className="animate-hover btn btn-sm btn-addnew" to={Routes.Relations.addnew}  >
                            <FontAwesomeIcon icon={faPlus} className="animate-left-3 me-3 ms-2" />
                            Add new relation
                        </Button>
                        </>
                    }                    
                </Col>
            </Row>
            <RelationListComponent  pageName={`relationListing`} filterBy={filterBy} />            
        </>
    );
}

export default RelationListAll;