import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';
import { Routes } from "../../routes";
import { Col, Row,  Alert, Button } from '@themesberg/react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import useFetch from "../../framework/hooks/useFetch";
import { statusPill } from "./relations";
import { useHistory  } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import RelationAddForm from './Form';

const RelationEdit = () => {
    
    
    const { action, id } = useParams();
    const history = useHistory();

    const { register, handleSubmit, setValue, getValues ,formState: { errors } } = useForm();

    

    /* Fetching data and setting the values to the form elements */
    const API_URL = process.env.REACT_APP_API_URL;
    const { data, isPending, isError } = useFetch(API_URL + '/v1/web/relation/data/view/' + id, 'GET', true);

   //   console.log(data);

    // set the values fetched from database
    setValue("GID", data.GID);
    setValue("UId", data.UId);
    setValue("rUserId", data.rUserId);   
    setValue("rUserName", data.coAuthorName);   
    setValue("relationType", data.relationType);
    setValue("source", data.source);
    setValue("notes", data.notes);
    setValue("paperType", data.paperType);
    setValue("dateStartYear", data.dateStartYear);
    setValue("dateStartMonth", data.dateStartMonth);
    setValue("dateEndYear", data.dateEndYear);
    setValue("dateEndMonth", data.dateEndMonth);
    setValue("startDateMand",0);
    setValue("endDateMand",0);
    setValue("startDateLabel","");
    setValue("endDateLabel","");
    setValue("Status", data.Status);
    setValue("allowModify", ((data.allowModify === 1) ? true : false));
    setValue("sourceUserIsLoggedInUser",data.sourceUserIsLoggedInUser);
    setValue("relationTypes", data.relationTypes);
    setValue("relationTypesOrdered", data.relationTypesOrdered);    
    setValue("publicationId", data.publicationId);   
    setValue("publicationName", data.publicationName);
    setValue("oldPublicationName", data.publicationName);
    setValue("usersDrp", data.usersDrp);
    setValue("coAuthId", '');
    setValue("coAuthLabel", '');
    setValue("relUserIsLoggedInUser", data.relUserIsLoggedInUser);
    setValue("modificationsComment","");
    setValue("rejectionComment","");
    setValue("contactAdminComment","");
    setValue("coAuthorStatus",data.coauthor_status);
    setValue("institutionDrp",data.institutionDrop);
    setValue("publicationDBLPUrl",data.publicationDBLPUrl);
    setValue("DBLPImportedEntry", data.DBLPImportedEntry);
    setValue("sourceUserUpdateComment", "");
    setValue("LoggedInUserFullName", data.LoggedInUserFullName);
    setValue("communityPaperId", data.communityPaper);
    setValue("ongoingEmploymentCheck", ( ((data.dateEndYear !== null) && (data.dateEndYear !== '')) ? 0 : 1 ));
    setValue("notificationId", data?.latest_unread_notification_id);
    
    const coauthorData = data.coAuthors || [];

   // console.log('coauthorData');
   // console.log(coauthorData);

    const coauthorAddData = [];

    Object.keys(coauthorData).forEach(key => {
        coauthorAddData.push({
            relationId: coauthorData[key]['relation_id'],
            relationParentId: coauthorData[key]['relation_parent_id'],
            coauthor_id: coauthorData[key]['coauthor_id'],
            coauthor_name: coauthorData[key]['coauthor_name'],
            alow_modify: coauthorData[key]['allowModify'],
            status: coauthorData[key]['status']
        });
    });

    /*if(!coauthorData.length){
        coauthorAddData.push(
            { 
            relationId: "",
            coauthor_id: "",
            coauthor_name: "",
            alow_modify:"",
            status: ""
         }
        );
    }*/
   
   // console.log(data.usersDrp);


    // relation types dropdown setup
    let relationDrpTypes = [];
    let relationTypeName = '';
    if (data.relationTypesOrdered){
        Object.keys(data.relationTypesOrdered).forEach(key => {           
            if (Number(data.relationType) === Number(data.relationTypesOrdered[key].id)){
                relationTypeName = data.relationTypesOrdered[key].type_name;
            }
            relationDrpTypes.push({
                value: data.relationTypesOrdered[key].id,
                label: data.relationTypesOrdered[key].type_name
            });
        });
    }
    


    let pubDrp = [];
    if (data.allPublications){
        pubDrp = data.allPublications;
    }
    /*if (data.allPublications){
        Object.keys(data.allPublications).forEach(key => {           
            pubDrp.push({
                value: key,
                label: data.allPublications[key]
            });
        });
    }*/
    let paperTypeDrp = [];
   
    if (data.paperTypes){
        Object.keys(data.paperTypes).forEach(key => {
            if (Number(key) === data.paperType){
                setValue("paperTypeName",data.paperTypes[key]);
            }
            paperTypeDrp.push({
                value: key,
                label: data.paperTypes[key]
            });
        });
    }
    setValue("paperTypes", paperTypeDrp);
    setValue("relationTypesAll", data.relationTypes);
    setValue("relationDrpTypes", relationDrpTypes);
    setValue("relationTypeName", relationTypeName);
    setValue("allPublications", pubDrp);
    

    let showSave = ((data.GID && (data.Status !== 3))? true : false );
    
    const cancelButton = () => {
        return (
        <Button className="animate-hover btn btn-sm btn-secondary" onClick={()=>{
            if (history.length > 0){
                history.goBack();
            }else{
                history.push(Routes.Relations.listall);
            }
        }} >
            <FontAwesomeIcon icon={faArrowLeft} className="animate-left-3 me-3 ms-2" />
            Back
        </Button>);
    }

    return (
        <>
            <ModuleBreadcrumb links={[
                { label:"List all relations", linkto: Routes.Relations.listall },
                { label: (action === "modify") ? "Modify relation" : "View relation" } ]}  />
            <Row className="mt-4">
                <Col lg={9} className="mb-3">
                    <h4>Relation - {relationTypeName}</h4>
                    {statusPill(data.Status)}
                </Col>
                <Col className="text-right">{cancelButton()}</Col>
                
            </Row>
            
            {isPending && <div className="alert alert-info" role="alert">Fetching data. Please wait..</div>}                    
            {isError && <Alert variant="danger">{isError}</Alert>} 
            {data.allPublications && 
                <RelationAddForm id={id} action={action} setValue={setValue} getValues={getValues} register={register} handleSubmit={handleSubmit} errors={errors}  showSave={showSave} coauthorData={coauthorAddData}   />
            }
        </>
    );
}
export default RelationEdit;