import React, { useEffect } from "react";
import './ExpandableBlock.scss';

const ExpandableBlock = ({ title, children, open=false }) => {
    
    const [isOpen, setOpen] = React.useState(open);
    useEffect(()=>{
        setOpen(open);
    },[open]);
    return (
        <div className="accordion-wrapper">

            <div
                className={`accordion-title ${isOpen ? "open" : ""}`}
                onClick={() => setOpen(!isOpen)}
            >
                {title}
            </div>
            <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
                <div className="accordion-content">{children}</div>
            </div>
        </div>
    );
};

export default ExpandableBlock;