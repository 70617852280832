import { Col, Row, Card, Button } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus,faTimes,faSave } from "@fortawesome/free-solid-svg-icons";

const ModuleEditScreenButtons = (props) => {

    const startSave = props.startSave;
   
    return (
        <> 
           <Card.Footer>  
            <Row>   
                <Col>
                    <div className="mt-3 d-flex justify-content-end">
                        {props.showSave && <Button variant="primary" type="submit"  className="animate-hover" ><FontAwesomeIcon icon={faSave} className="animate-left-3 me-3 ms-2" />{startSave ? "Please wait..." : "Save"}</Button>}                        
                        
                        {props.cancelPath &&                             
                            <Button as={Link} className="animate-hover btn btn-sm btn-secondary" to={props.cancelPath}>
                                <FontAwesomeIcon icon={faTimes} className="animate-left-3 me-3 ms-2" />
                                Cancel
                            </Button>
                        }
                       
                        {props.addnewPath && 
                            <Button as={Link} className="animate-hover btn btn-sm btn-addnew" to={props.addnewPath}>
                                <FontAwesomeIcon icon={faPlus} className="animate-left-3 me-3 ms-2" />
                                Add new
                            </Button>
                        }

                        

                    </div>
                </Col>                    
            </Row>
            </Card.Footer>            
        </>
    );
}

export default ModuleEditScreenButtons;
