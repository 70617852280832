import React,{useEffect, useState} from "react";
import { Col, Row, Form, Alert, Card, Button,InputGroup } from '@themesberg/react-bootstrap';
import { useForm } from "react-hook-form";
import { passRules, PasswordCheck } from "../../framework/utilities/utilities";
import { validateForm } from "./RegistrationValidation";
import { Link } from 'react-router-dom';
import { Routes } from "../../routes";
import ModuleBreadcrumb from '../../project/components/ModuleBreadcrumb';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes,faUser,faArrowLeft , faLock, faSignInAlt} from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { scrollToTop } from "../../framework/utilities/utilities";

const Registration = () => {

    const {token} = useParams();
    const [emailAddress, setEmailAddress] = useState('');
    const { register, handleSubmit, setValue , getValues } = useForm();
    
    useEffect(()=>{
        if ((token !== '') && (token !== undefined)){
            const API_URL = process.env.REACT_APP_API_URL;
            fetch(API_URL + '/v1/web/user/data/validate_token_for_request_response/' + token, {
                method: 'GET'        
            })
            .then(response => response.json())
            .then(jsondata => {    
                console.log("comes here");    
                if (jsondata.message === "SUCCESS"){
                    if (jsondata.account_active === 1){
                        setAlreadyAccountActive(1);
                        setAlreadyAccountActiveMsg(jsondata.additional_msg);
                    }
                    setValue("Email",jsondata.userData.email);
                    setEmailAddress(jsondata.userData.email);
                }           
            }).catch((err) => {
                console.log("Login error: " + err);
               
            });
        }
        
    },[token,setValue]);
    

    //console.log(token);

    
    const [alreadyAccountActive, setAlreadyAccountActive] = useState(0);
    const [alreadyAccountActiveMsg, setAlreadyAccountActiveMsg] = useState('');
    const [startSave, setStartSave] = useState(false);
    const [errMessage,setErrorMessage] = useState('');
    const [successMessage,setSuccessMessage] = useState('');
    const [requestLogged, setRequestLogged] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL;

    const onSubmit = postedData => {
        
        postedData.optedChecked = getValues('optedIn');
        postedData.Email = (postedData.Email) ? postedData.Email : ((emailAddress) ? emailAddress : '');

        setStartSave(true);
        
        const errMesgs = validateForm(postedData);
        if (errMesgs) {
            setErrorMessage(errMesgs);
            setStartSave(false);
            scrollToTop();
            return false;
        }
        
        // lets check if password and confirm password is same and also validate email
        if ( (!PasswordCheck(postedData.password))  )
        {
            setStartSave(false);                
            setErrorMessage(passRules());
            scrollToTop();
            return false;
        }else if (postedData.password !== postedData.confirmPassword){
            setErrorMessage("Password and confirm password should match."); 
            setStartSave(false);       
            scrollToTop();  
            return false;
        }else{
            setErrorMessage('');
        }
        //console.log(postedData);
        //return false;
        let formData = new FormData();
        formData.append("FName", postedData.FName);
        formData.append("Email", postedData.Email);
        formData.append("SName", postedData.SName);
        formData.append("dblp_url", postedData.dblp_url);
        formData.append("userToken", (token) ? token : '');

        formData.append("password", postedData.password);
        formData.append("confirmPassword", postedData.confirmPassword);


        fetch(API_URL + '/v1/web/user-registration/save', {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(jsondata => {
            //console.log(jsondata);
            if ((jsondata.GID) && (jsondata.status === 'CREATED')){
                setSuccessMessage(jsondata.message);                
                setRequestLogged(true);
                scrollToTop();
                /*setTimeout(function(){                    
                    window.location.reload();
                },1000);*/
            }else if ( (jsondata.GID) && (jsondata.message !== '')){
                // this could be when the user submits the form again
                setSuccessMessage(jsondata.message);                
                setRequestLogged(true);
                scrollToTop();
            } else if (jsondata.message && !(jsondata.errors)){
                setErrorMessage(jsondata.message + "\r\n");   
                scrollToTop();                    
            } 
            else if (jsondata.errors){
                setErrorMessage(jsondata.message + "\r\n" + JSON.stringify(jsondata.errors));  
                scrollToTop();                     
            } 
            setStartSave(false);
        }).catch((err) => {
            setStartSave(false);
            console.log("Error: " + err);
        });
            
    
    }

    return (  
        <>  
        <br/>
        <ModuleBreadcrumb links={[ { label: "Sign up" } ]}  />
        <Row className="mt-4">
            <Col lg={9} className="mb-3">
                <h4>{(!requestLogged) ? `Sign up` : `Thank you!`}</h4>                
            </Col>
        </Row>
        
        {(alreadyAccountActive === 1) ? 
            <>{alreadyAccountActiveMsg}<br/>
            <Button as={Link} className="animate-hover btn btn-sm btn-primary mt-2" to={Routes.Signin.path}>
                <FontAwesomeIcon icon={faSignInAlt} className="animate-left-3 me-3 ms-2" />Sign in
            </Button>
            </> 
        : 
            <>
                
                {successMessage && !errMessage && <Alert variant="success">{successMessage}</Alert>}   
                {errMessage && <Alert variant="danger">{errMessage}</Alert>} 
                {!requestLogged &&
                    <>
                    <Form className="mt-2" onSubmit={handleSubmit(onSubmit)}>                           
                        <Row>
                            <Col md={4} className="mb-3">
                                <Form.Group id="FName">
                                    <Form.Label className="required">First name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter your first name" {...register("FName")} />
                                </Form.Group>
                            </Col>
                            <Col md={4} className="mb-3">
                                <Form.Group id="SName">
                                    <Form.Label className="required">Last name</Form.Label>
                                    <Form.Control  type="text" placeholder="Enter your last name" {...register("SName")} />
                                </Form.Group>
                            </Col> 
                            <Col md={4} className="mb-3">
                                <Form.Group id="Email">
                                    <Form.Label className="required">Email</Form.Label>
                                    <Form.Control  type="email" placeholder="example@gmail.com" {...register("Email")} disabled={ (token) ? true : false} />
                                </Form.Group>
                            </Col>
                        </Row> 
                        <Row>
                            <Col md={12} className="mb-3">
                                <Form.Group id="dblp_url">
                                    <Form.Label className="required">DBLP profile URL (e.g: https://dblp.org/pid/78/50)</Form.Label>
                                    <Form.Control  type="text" placeholder=""  {...register("dblp_url")} />
                                    <br/>
                                    <div className="form-check"><Form.Label className="form-check-label" htmlFor="no_dblp">I do not have a DBLP URL.</Form.Label>
                                        <input type="checkbox" name="no_dblp" className="form-check-input" value="Y" 
                                        onChange={(e)=>{
                                            if (e.target.checked){
                                                setValue('noDBLP','Y')
                                            }else{
                                                setValue('noDBLP','N')
                                            }
                                        }}
                                        />
                                    </div>
                                </Form.Group>
                            </Col> 
                        </Row>  
                        <Row>
                            <Col md={4} className="mb-3" >
                                <Row>
                                    <Col className="mb-3" >
                                        <Form.Group id="password" className="mb-4">
                                            <Form.Label className="required">Password</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faLock} />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    required
                                                    type="password"                                            
                                                    id="pwd"
                                                    name="pwd"
                                                    {...register("password")}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    
                                    </Col>                            
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group id="confirm_pwd" >
                                            <Form.Label className="required">Confirm password</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faLock} />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    required
                                                    type="password"                                            
                                                    id="confirm_pwd"
                                                    name="confirm_pwd"
                                                    {...register("confirmPassword")}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>                            
                                </Row>
                            </Col>
                            <Col md={4} className="mb-3 password-rules-box" style= { { marginLeft: 10} } >{passRules()}</Col>
                        </Row >
                        <Row className="mb-5">
                            <Col>
                                <Form.Group id="inst">
                                    <div className="form-check">
                                        <Form.Label className="form-check-label required" htmlFor="opt_in">I agree to become a member of ConfictDB. I consent to the use of cookies and receipt of automated mails.</Form.Label>
                                        <input type="checkbox" name="opt_in" className="form-check-input" value="Y" 
                                        onChange={(e)=>{
                                            if (e.target.checked){
                                                setValue('optedIn','Y')
                                            }else{
                                                setValue('optedIn','N')
                                            }
                                        }}
                                        />
                                    </div>
                                </Form.Group> 
                            </Col>
                        </Row>
                        <Card.Footer>  
                            <Row>   
                                <Col>
                                    <div className="mt-3 d-flex justify-content-end">
                                        <Button variant="primary" type="submit"  className="animate-hover" ><FontAwesomeIcon icon={faUser} className="animate-left-3 me-3 ms-2" />{startSave ? "Please wait..." : "Create account"}</Button>
                                                                
                                        <Button as={Link} className="animate-hover btn btn-sm btn-secondary" to={`/`}>
                                            <FontAwesomeIcon icon={faTimes} className="animate-left-3 me-3 ms-2" />
                                            Cancel
                                        </Button>
                                    </div>
                                </Col>                    
                            </Row>
                        </Card.Footer>  
                    </Form>
                
                    <div className="mt-3 mb-4 text-center">
                        <span className="fw-normal">or</span>
                    </div>
                    <div className="d-flex justify-content-center align-items-center mt-4 mb-5">
                        <span className="fw-normal">
                            Already have an account?
                            <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">
                                {` Login here `}
                            </Card.Link>
                        </span>
                    </div>
                    </>
                }

                {(requestLogged) &&     
                    <Button as={Link} className="animate-hover btn btn-sm btn-secondary mt-2" to="/">
                        <FontAwesomeIcon icon={faArrowLeft} className="animate-left-3 me-3 ms-2" />
                        Back
                    </Button>
                }
                
            </>
        
        }
            

        </>
    );
};

export default Registration;