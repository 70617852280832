import { Card, Col, Row, Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye , faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useHistory  } from "react-router-dom";

const RelationNewRequests = (props) => {

    const isNewUser = props.newUser;
    let sessionUser = JSON.parse(localStorage.getItem("profile"));
    const history = useHistory();

    const onViewNotification = (linkTo) => {
        history.push(linkTo);        
    }

    const onHideNotification = (notficationId, linkTo) => {
        //console.log(notficationId, linkTo);
        // lets make a call to update the status of the notification to read
        const API_URL = process.env.REACT_APP_API_URL;
        let formData = new FormData();
        formData.append("NotificationId", notficationId);
        fetch(API_URL + '/v1/web/mark_notification_read' , {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
            body: formData
        })
        .then(response => response.json())
        .then(jsondata => {            
           if (jsondata.message === 'SUCCESS')
                window.location.reload();          
        }).catch((err) => {
            console.log("Login error: " + err);            
        });
        
    }


    return (
        <>
            { ( (sessionUser) && (sessionUser.Notifications) && (sessionUser.Notifications.length > 0)) ?
            <>
                <Row>
                    <Col><div className="callout callout-info">
                        {isNewUser ? 
                            <>You have received new requests. You must complete your profile to respond to requests.</>
                            :
                            <>You have received new requests. Please review and respond to them.</>
                        }                    
                    </div></Col>
                </Row>
                <Card border="light" className=" mb-4">
                    <Card.Header as="h5">Notifications</Card.Header>
                    <Card.Body className="pb-3">
                        {sessionUser.Notifications.map((notification, index) =>{ 
                            return (                                                       
                                <Card border="light" className=" mb-4" key={`notification-${index}`}>
                                    <Card.Body className="pb-4">                                       
                                        <>
                                            <h6>{notification['data']['notify_message']}</h6>
                                            <strong>Type: </strong>{notification['data']['relation_type_name']}<br/> 
                                            {(notification['data']['publication_name'] && (notification['data']['publication_name'] !== '') && (Number(notification['data']['rel_type_id']) !== 8) ) && 
                                                <><strong>Title: </strong>{notification['data']['publication_name']}<br/> </>
                                            }                                            
                                            {(notification['data']['reject_reason'] && (notification['data']['reject_reason'] !== '') && (Number(notification['data']['rel_type_id']) !== 8) ) && 
                                                <><strong>Reason: </strong>{notification['data']['reject_reason']}<br/> </>
                                            }
                                            {((notification['formated_dt']) && (notification['formated_dt'] !== '')) && <><small className="mb-1" style={{color:"#ccc"}}>{notification['formated_dt']}</small><br/></> }                                       
                                            { (!isNewUser) && (Number(notification['data']['relation_parent_id']) > 0) && 
                                            <>
                                                <br/>
                                                {(Number(notification['data']['rel_type_id']) === 8) ?

                                                <Button type="button" className="animate-hover btn btn-sm btn-primary" onClick={()=>{
                                                    onViewNotification(`/pending-relations/view/${notification['data']['relation_parent_id']}`);
                                                    }}  >
                                                    <FontAwesomeIcon icon={faEye} className="animate-left-3 me-3 ms-2" />
                                                    View 
                                                </Button>

                                                :

                                                <Button type="button" className="animate-hover btn btn-sm btn-primary" onClick={()=>{
                                                    onViewNotification(`/relations/view/${notification['data']['relation_parent_id']}`);
                                                    }}  >
                                                    <FontAwesomeIcon icon={faEye} className="animate-left-3 me-3 ms-2" />
                                                    View 
                                                </Button>
                                                
                                                }

                                                <Button type="button" className="animate-hover btn btn-sm btn-secondary" onClick={()=>{
                                                    onHideNotification(notification['id'],`/pending-relations/view/${notification['data']['relation_parent_id']}`);
                                                    }}  >
                                                    <FontAwesomeIcon icon={faEyeSlash} className="animate-left-3 me-3 ms-2" />
                                                    Dismiss
                                                </Button>
                                            </>                                        
                                            }
                                        </>
                                        
                                    </Card.Body>
                                </Card>                            
                            )
                        })}
                        
                    </Card.Body>
                </Card>
            </>
            :
            <>
                { (!isNewUser) && <>No new notifications.</> }            
            </>
            }
        </>
    );
}

export default RelationNewRequests;