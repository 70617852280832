import React from "react";
import moment from "moment-timezone";
import { Row, Col, Container, Image } from '@themesberg/react-bootstrap';
import sigarchLogo from "../../assets/img/brand/sigarch_logo_rgb.png";
import acmLogo from "../../assets/img/brand/logo_footer_acm.png";
import { Routes } from "../../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion, faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
    const currentYear = moment().get("year");
    
    let sessionUser = JSON.parse(localStorage.getItem("profile"));

    return (
        <Container>
            <footer className="footer section py-2 px-2 px-md-3 px-lg-4 mt-5">
                <Row className="align-items-center text-center">
                    <Col md={12} lg={5} className="">
                        <p className="my-2 text-lg-left link-dark">
                            { ((sessionUser) && (sessionUser.Email !== '')) && 
                                <>
                                <a href={`/#${Routes.ContactUsPage}`} className="link-dark"  ><FontAwesomeIcon icon={faEnvelope} /> Contact us</a>
                                <span  style={{paddingLeft:"10px",paddingRight:"10px"}}>|</span>
                                </>
                            }
                            <a href={`/#${Routes.HelpPage}`} className="link-dark" ><FontAwesomeIcon icon={faQuestion} /> Help</a>
                        </p>
                    </Col>
                    <Col md={12} lg={4} className="text-md-right d-flex justify-content-center justify-content-lg-end align-items-center">
                        <p className="my-2 text-md-left link-dark">
                            <span>Copyright © {`${currentYear} `} <a href="https://www.sigarch.org/" target="_blank" rel="noreferrer" className="link-dark" >ACM SIGARCH</a></span>
                        </p>
                    </Col>
                    <Col md={12} lg={3} className="text-lg-right d-flex justify-content-center justify-content-lg-end align-items-center">
                        <a href="https://www.sigarch.org/" target="_blank" rel="noreferrer" className=" mx-1" >
                            <Image src={sigarchLogo} style={{ maxHeight: "50px", paddingRight:"10px" }} className="m-2 m-lg-0" alt="" />
                        </a>

                        <a href="https://www.sigarch.org/" target="_blank" rel="noreferrer" className=" mx-1" >
                            <Image src={acmLogo} style={{ maxHeight: "50px" }} className="m-2 m-lg-0" alt="" />
                        </a>
                        
                    </Col>           
                </Row>
            </footer>
        </Container>
    );
};

export default Footer;
