export function validateForm(postedData){

    let errors = {};
    let formIsValid = true;
    
    if ((!postedData.FName)){  
        errors["FName"] = "Please enter first name";
        formIsValid = false;  
    }
    if ((!postedData.SName)){  
        errors["SName"] = "Please enter last name";
        formIsValid = false;  
    }
    if (!postedData.Email){ 
        errors["Email"] = "Please enter valid email address";
        formIsValid = false;  
    }

    if ((postedData.dblp_url !== '') && (!postedData.dblp_url.includes('/dblp.org/pid/'))){ 
        errors["dblp_url"] = "Please enter valid DBLP URL";
        formIsValid = false;  
    }
    if ((postedData.dblp_url === "") && ( (postedData.noDBLP === undefined) || (postedData.noDBLP === 'N') || (postedData.noDBLP === '') )) {
        errors["dblp_url"] = "Please enter valid DBLP URL";
        formIsValid = false;  
    }
    if (!postedData.password){ 
        errors["password"] = "Please enter password";
        formIsValid = false;  
    }
    if ((postedData.optedChecked === 'N') || (postedData.optedChecked === undefined)){ 
        errors["password"] = "Please tick the terms checkbox";
        formIsValid = false;  
    }

    if (!formIsValid){
        return (
            <>
                <strong>Please check below fields:</strong><ul>
                {
                    Object.keys(errors).map(key => {
                        return(<li key={`error-${key}`}>{errors[key]}</li>)
                    })
                }
                </ul>
            </>
        ) 
    }else{
        return false;
    }
}