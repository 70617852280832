const Logout = () => {
    const API_URL = process.env.REACT_APP_API_URL + '/v1/user/logout';
    const API_TOKEN = localStorage.getItem("token");

    //console.log('Logout', localStorage.getItem("expires_in"), localStorage.getItem("token"), localStorage.getItem("refresh_token"));

    fetch(API_URL, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + API_TOKEN,
        },
    })
        .then(res => {
            if (!res.ok) {                
                if (res.status === 401){
                    console.log("401 detected");
                    localStorage.clear();
                    window.location.href = "/";
                }else{
                    throw Error('Could not fetch the data from that resource.');
                }                
            }
            return res.json();
        })
        .then(jsondata => {
            console.log(jsondata);
            localStorage.clear();
            window.location.href = "/";
        }).catch((err) => {
            console.log("Login error: " + err);
            localStorage.clear();
            window.location.href = "/";
        });
}

export default Logout;