
import React from "react";
import { Col, Row } from '@themesberg/react-bootstrap';
import RelationNewRequests from "../relations/NewRequests";

let sessionUser = JSON.parse(localStorage.getItem("profile"));

const DashboardOverview = () => {
    return (
        <>
            <Row className="justify-content-start  py-4">
                <Col xs={12} sm={6} xl={4} className="mb-2">
                    {sessionUser && <h2>Hi, { sessionUser.FName }</h2>}
                </Col>
            </Row>
            <RelationNewRequests newUser={false} />
        </>
    );
};

export default DashboardOverview;