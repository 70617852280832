export const InstitutionCheck = (param) => {    

    let status = false;
    if (param.length){
        param.map((x, i) => { 
           
            if(x.institutionId !== '')
            {
                console.log(x.startDate);
                if(x.startDate  === ''  ||  (x.current === 0 && x.endDate === ''))
                {
                    status = true;
                    return status;
                }
            }
            return status;
        });
       
    }
    return status;
  
}
