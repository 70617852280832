import React from "react";
import LogoImage from "../../assets/img/brand/conflict-logo.png";

const NavbarBrand = () => {
    
    return (
        <>
        <div style={{height:"52px", marginRight: "30px", lineHeight: "48px"}}>
            <a href="/"><img src={LogoImage} alt="ConflictDB" title="ConflictDB" style={{height:"67%"}} /></a>
        </div>        
        </>
    );
};

export default NavbarBrand;