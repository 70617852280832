import React,  { useEffect , useRef } from 'react';
import ModuleBreadcrumb from '../project/components/ModuleBreadcrumb';
import { useLocation } from "react-router-dom";

const useScroll = () => {
    const elRef = useRef(null);
    const executeScroll = () => elRef.current.scrollIntoView();
    return [executeScroll, elRef];
};

const HelpPage = () => {
    const heading = "Help";

    const [executeScroll, anchorFaqRef] = useScroll();
    const { pathname } = useLocation();
    useEffect(() => {
        if (pathname.includes("/help/faq")){
            executeScroll();
        }     
    }, [pathname,executeScroll]);

    return (
        <>
            <ModuleBreadcrumb links={[
            { label: heading } ]}  />
                        
            <h1 className='h4'>Why ConflictDB?</h1>
            <p className="mt-3">      
            Until now, conflicts of interest in our community have been specified at the point of a paper’s submission by entering them into the conference reviewing software, such as HotCRP. For each submitted paper, an author would specify the name and affiliation of every person they believe to be conflicted with. Reviewers would do the same, and an algorithm would then identify the set of reviewers who are conflicted with a given author, and hence should not review their paper(s).</p>

            <p>This process works great if two people list each other as conflicts. Unfortunately, what often happens is that a person would list another person as a conflict but not the other way around, resulting in so-called one-sided conflicts. There exist a number of potential reasons for one-sided conflicts. Sometimes, when two people start a new collaboration, one of them forgets to list the other collaborator as a conflict. Other times, a conflict had sunset, meaning that enough time has passed to render it no longer a conflict, yet one of the parties, by inertia, continues to list the other as a conflict. Yet other times, people have different views on whether their interaction constitutes a conflict of interest.</p>

            <p>A PC chair of a conference can spend much time resolving one-sided conflicts, likely needing to contact the involved individuals. But there is no good mechanism to propagate the resolved conflict lists across conferences, meaning that the effort of resolving one-sided conflicts repeats anew for each conference. Moreover, if rules governing what constitutes a conflict differ between conferences, authors and reviewers face the burden of updating their conflict lists to comply with the rules for a particular submission – an error-prone process likely to result in omitted or over-stated conflicts.</p>

            <p>ConflictDB is a database to track conflicts-of-interest for reviewing purposes. Its goal is to fix the above problems through three attributes. First, the information inside ConflictDB is validated, meaning that all involved sides have agreed on its accuracy or have explicitly disagreed. Secondly, the information is persistent and exists independently of conferences. Third, determination of whether a past interaction constitutes a conflict is automated based on configurable rules.</p>

            <p>In addition to addressing the problems outlined above, ConflictDB is also a tool to simplify life for authors and reviewers by eliminating the need to enter conflicts from scratch for every paper submitted to every conference. While reviewers will still need to enter new relations into ConflictDB and manually indicate when a research collaboration has come to an end, sunsetting of most conflict types will happen automatically.</p>

            <h1 className='h4'>ConflictDB overview</h1>
            <p>An easy way to think of ConflictDB is as a graph where vertices are the individual researchers and edges represent relations between them. A relation can be a co-authored publication, jointly funded grant proposal, on-going collaboration, etc. ConflictDB combines two sources of information to discover relations: publicly available co-authorship information from DBLP and user-declared information such as advisor/advisee relationships and jointly funded grant proposals.</p>

            <p>Whether a relation constitutes a conflict of interest is determined by the specified policy. For instance, SIGARCH guidelines state that a jointly-authored publication results in a conflict-of-interest between its co-authors for 4 years after the date of publication. Thus, a paper co-authored by Alice and Bob four years ago causes a conflict of interest between them now, but not a year from now. This is why ConflictDB uses the term relation instead of conflict to refer to an edge between two individuals – relations are entries in the database, but not all entries constitute conflicts of interest.</p>

            <p>A key aspect of ConflictDB is that all relations are validated. Thus, if Alice adds a new entry stating that she and Bob have a jointly-funded grant proposal, Bob will receive a notification asking him to approve the entry. If Bob disagrees with some of the information entered by Alice, he can suggest a modification using a built-in interface. All interactions between Alice and Bob are logged. If Alice and Bob cannot come to an agreement, the entry will remain unvalidated, and a PC chair using ConflictDB will (1) be alerted to a potential conflict between Alice and Bob if the information in the entry matches a specific rule defining a conflict, and (2) be able to view the log, which should help her/him determine whether the relation constitutes a conflict of interest for the given conference. The PC chair can optionally update ConflictDB to either resolve the issue (e.g., by validating or deleting the relation) or leave a log entry with additional information to help future PC chairs.</p>

            <h2 className='h4'>User Interface</h2>

            <p>Once a user’s profile has been setup, most interactions with ConflictDB happen on four screens:</p>
            <p><em>Home (dashboard):</em> the dashboard shows notifications, if any, informing the user of new relation requests or addition of comment(s) to an existing relation.</p>
            <p><em>List relations:</em> view the user’s existing relations grouped by type (e.g., co-authored publications, joint grant proposals) or name.</p>
            <p><em>Add relation:</em> self-evident. Note that some relation types allow multiple individuals to be named (e.g., co-awardees on a grant proposal) while others are limited to just two individuals (e.g., advisor/advisee).</p>
            <p><em>View conflicts:</em> allow the user to generate their conflict list using rules based on SIGARCH/TCCA’s Recommended Best Practices for Conference Reviewing Process.</p>

            <h2 className='h4 mt-4' ref={anchorFaqRef} >FAQ:</h2>

            <h3 className='h6 mt-4'>My publication is missing from ConflictDB yet shows up on DBLP. Why?</h3>
            <p>If you have a new ConflictDB account, it’s likely that we haven’t yet crawled DBLP to get all of your publications, and the ones included in ConflictDB are due to your co-authors establishing their accounts and having their DBLP crawled. </p>
            <p>If you have had your account for a while, it’s likely that new publications were added to DBLP recently. Nothing to worry about - these will be picked up the next time we crawl DBLP.</p>

            <h3 className='h6 mt-4'>Can a relation be modified?</h3>
            <p>Yes, by the person who created the relation. If another person named on the relation wants to request a modification, they can leave a comment requesting the change. They can also contact the admin with the request.</p>

            <h3 className='h6 mt-4'>Can a relation be disputed/rejected?</h3>
            <p>If Alice is named on a relation that she disagrees with (e.g., Bob claims to have an on-going collaboration with her while Alice feels that it was just a one-time chat over a cup of coffee that does not form a conflict of interest), she can Reject the relation and leave a comment explaining her reasoning. If Bob convinces Alice otherwise, she can change her mind and Accept the relation at a later point.</p>

            <h3 className='h6 mt-4'>What happens with disputed relations?</h3>
            <p>Disputed relations are treated as normal relations for the purpose of conflict checking. That is, if a PC chair using ConflictDB wants to check whether Alice and Bob have a conflict of interest, a disputed relation between them that matches a conflict rule will come up as a provisional conflict. If another, validated relation exists between Alice and Bob that also results in a conflict, the disputed relation will be ignored, since a conflict is established through a validated relation. In the absence of a validated relation establishing a conflict, the PC chair is alerted of the provisional nature of the identified conflict. At this point, she/he can access the disputed relation and attempt to resolve it by reading the comments left by Alice and Bob, and if need be, by contacting them for additional information.</p>
            <p>
            While the resolution of a disputed relation resembles that of a one-sided conflict today, there are several important differences. First, the PC chair has access to the previous interactions between the involved parties as recorded in ConflictDB. Secondly, the PC chair can permanently resolve the dispute by validating or deleting the relation. Lastly, if the relation is truly a tricky one and must be treated on a case-by-case basis for each conference, the PC chair can add further comments to the relation, thus making the job easier for future PC chairs.</p>

            
            
        </>
    )
}

export default HelpPage;
