

import React from "react";
import { Modal, Button } from '@themesberg/react-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MessageModal = (props) => {
    return (
    <Modal
        show={props.modalShow}            
        onHide={props.handleModelClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        { (props.heading !== '') && <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
               {props.heading}
            </Modal.Title>
        </Modal.Header>}
        <Modal.Body>            
            <p>
               {props.message}           
            </p>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={props.handleModelClose}  className="animate-hover btn btn-sm btn-secondary" >
                <FontAwesomeIcon icon={faTimes} className="animate-left-3 me-3 ms-2" />
                Close
            </Button>             
        </Modal.Footer> 
    </Modal>
    );
}

export default MessageModal;