import { Col, Row, Button, Alert } from '@themesberg/react-bootstrap';
import RelationNewRequests from "../relations/NewRequests";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser,faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Routes } from "../../routes";

const RelationValidationNewUser = () => {

    const { token } = useParams();
    const [errMessage, setErrMessage] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [addMsg, setAddMsg] = useState('');
    const [showSignIn, setShowSignIn] = useState(false);

    useEffect(() => {
        const API_URL = process.env.REACT_APP_API_URL;
        fetch(API_URL + '/v1/web/user/data/validate_token_for_request_response/' + token, {
            method: 'GET'        
        })
        .then(response => response.json())
        .then(jsondata => {        
            if (jsondata.message === "SUCCESS"){
                setShowForm(true);
                setAddMsg(jsondata.additional_msg);
                setShowSignIn(jsondata.show_sign_in);
            }else{
                setErrMessage("Something went wrong! Please try again later.");           
            }            
        }).catch((err) => {
            console.log("Login error: " + err);
            setErrMessage("Something went wrong! Please try again later.");           
        });
    },[token]);
    

    /*
    const { data, isPending, isError } = useFetch(API_URL + '/v1/web/user/data/validate_token/' + token_id, 'GET', true);

    console.log(data);*/

    return (
        <>
            <h4 className="mt-4">Respond to relation</h4>
            {errMessage && <Alert variant="danger">{errMessage}</Alert>} 
            
                {showForm && 
                    <>
                        <Row className="mt-4">
                            <Col ><div className="callout callout-danger">{addMsg}<br/>
                                {showSignIn === 'Y' 
                                    ? 
                                    <Button as={Link} className="animate-hover btn btn-sm btn-primary mt-3" to="/" >
                                        <FontAwesomeIcon icon={faSignInAlt} className="animate-left-3 me-3 ms-2" />
                                        Sign In
                                    </Button>
                                    :
                                    <Button as={Link} className="animate-hover btn btn-sm btn-primary mt-3" to={Routes.RegistrationWithToken.path.replace(':token',token)} >
                                        <FontAwesomeIcon icon={faUser} className="animate-left-3 me-3 ms-2" />
                                        Create profile 
                                    </Button>
                                    }                                
                            </div>
                            </Col>
                        </Row>
                        <RelationNewRequests newUser={true} />
                    </>
                }
            
            
        </>
    );
}

export default RelationValidationNewUser;