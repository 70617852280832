import { useEffect, useState, useRef } from "react";

const useFetch = (url, method = "GET", headers = true, formData = false) => {
    const [data, setData] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const [isError, setIsError] = useState(false);

    const options = useRef({});

    options.current = '';
    options.current = {
        method: method,
    }

    if (headers) {
        options.current = {
            ...options.current,
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            }
        }
    }

    if (formData) {
        options.current = {
            ...options.current,
            body: formData
        }
    }

    useEffect(() => {
        const abortCont = new AbortController();

        options.current = {
            ...options.current,
            signal: abortCont.signal
        }

        fetch(url, options.current).then(res => {
            if (!res.ok) {                
                if (res.status === 401){
                    console.log("401 detected. Need to logout.");
                    localStorage.clear();
                    window.location.href = "/";
                }else{
                    throw Error('Could not fetch the data from that resource.');
                }                
            }
           
            return res.json();
        }).then(data => {
            if (data === 'Unauthorized.'){
                console.log("inside unautorised");
            }                        
            setData(data);
            setIsPending(false);
            setIsError(null);
        }).catch(err => {
            if (err.name === 'AbortError') {
                console.warn('Aborting the fetch request.');
            } else {
                setIsError(err.message);
                setIsPending(false);
            }
        });

        return () => abortCont.abort();
    }, [url]);

    return { data, isPending, isError }
}

export default useFetch;