

import React from "react";
import { Modal, Button } from '@themesberg/react-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MessageModal = (props) => {
    return (
    <Modal
        show={props.modalShow}            
        onHide={props.handleModelClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        { (props.heading !== '') && <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
               {props.heading}
            </Modal.Title>
        </Modal.Header>}
        <Modal.Body>            
            <p>
                { (props.moduleName === "paperTypeHelpMessage")  && 
                    <><strong>Not a research paper</strong> – a collaborative work that would never trigger a conflict of interest for reviewing purposes. For instance, introduction to a special issue of a journal by the issue's co-editors. 
                    <br/><br/>
                    <strong>Community paper</strong> – A work presenting a survey, compendium, tool, or artifact to which multiple authors contribute without engaging in an actual project collaboration (e.g., a paper describing an open-source software framework to which the authors have contributed different modules). Example: "The Gem5 Simulator: Version 20.0+" 
                    </>
                }               
            </p>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={props.handleModelClose}  className="animate-hover btn btn-sm btn-secondary" >
                <FontAwesomeIcon icon={faTimes} className="animate-left-3 me-3 ms-2" />
                Close
            </Button>             
        </Modal.Footer> 
    </Modal>
    );
}

export default MessageModal;