import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt, faUser, faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import LogoImage from "../../assets/img/brand/conflict-logo-small.png";

const Signin = () => {
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [isPending, setIsPending] = useState(false);
    const [isError, setIsError] = useState();
    const API_URL = process.env.REACT_APP_API_URL;
    const API_KEY = process.env.REACT_APP_API_KEY;

    const errorMessageForLogin = "Error while sign in process.";

    const profileDetails = () => {
        fetch(API_URL + '/v1/web/user/profile', {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token"),
            },
        })
        .then(response => {
            if (!response.ok) {                
                if (response.status === 401){
                    // we need to clean up the local storage
                    localStorage.clear();
                    // let us show the sign in error
                    setIsError(errorMessageForLogin);                        
                }
            }
            return response.json();
        })
        .then(jsondata => {
            //console.log("sucecss");     
            sessionStorage.setItem("pwdset",jsondata.PwdSet);            
            localStorage.setItem("profile", JSON.stringify(jsondata));
            window.location = "/";
        }).catch((err) => {
            console.log("Login error: " + err);
        });
    }

    const onSubmit = (e) => {
        e.preventDefault();

        setIsPending(true);

        var formData = new FormData();
        formData.append("action", "login");
        formData.append("api_key", API_KEY);
        formData.append("username", email);
        formData.append("password", pass);

        fetch(API_URL + '/v1/user/login', { method: 'POST', body: formData })
            .then(response => response.json())
            .then(jsondata => {
                if (jsondata.ERROR === "ACCOUNT_IS_LOCKED"){
                    setIsError(jsondata.MSG);                    
                    setIsPending(false);
                }else if (jsondata.ERROR === "LOGIN_FAILED"){
                    setIsError(jsondata.MSG); 
                    setIsPending(false);                   
                }else{
                    setIsPending(false);
                    setIsError('');
                    localStorage.setItem("expires_in", jsondata.expires_in);
                    localStorage.setItem("token", jsondata.access_token);
                    localStorage.setItem("refresh_token", jsondata.refresh_token);                
                    profileDetails();
                }            
                
            }).catch((err) => {
                console.log("Login error: " + err);
                setTimeout(function () {
                    setIsPending(false);
                    setIsError(errorMessageForLogin);
                    console.log("error");
                }, 2000);
            });
    }

    return (
        <main>
           
            <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5 position-relative">
                <Container className="pt-6">
                    <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
                        <Col xs={12} className="d-flex align-items-center justify-content-center">
                            <div className="bg-white shadow-soft-web border rounded border-light p-4 p-lg-4 w-100 fmxw-500">
                                <div className="text-center text-md-center mb-4 mt-md-0">
                                    <a href="/"><img src={LogoImage} alt="ConflictDB" title="ConflictDB" /></a>
                                    <h3 className="mb-0 mt-4">Sign in to our platform</h3>
                                </div>
                                <Form className="mt-4" onSubmit={onSubmit}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Your Email</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <FontAwesomeIcon icon={faEnvelope} />
                                            </InputGroup.Text>
                                            <Form.Control
                                                autoFocus
                                                required
                                                type="email"
                                                placeholder="example@company.com"
                                                id="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Your Password</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faUnlockAlt} />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    required
                                                    type="password"
                                                    placeholder="Password"
                                                    id="password"
                                                    value={pass}
                                                    onChange={(e) => setPass(e.target.value)}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <div className="d-flex justify-content-end mb-4">                                            
                                            <Card.Link as={Link} to={Routes.GuestForgotPassword.path} className="small text-end">Forgot password?</Card.Link>
                                        </div>                                        
                                    </Form.Group>
                                    {isError &&<div className="d-flex justify-content-center mb-1 mt-1"><p className="text-danger text-center">{isError}</p></div>}
                                    <div className="d-flex justify-content-center mb-4 mt-1">                                          
                                        {!isPending && <Button variant="primary" type="submit" className="w-100" style={{paddingLeft:0, marginLeft:0}}><FontAwesomeIcon icon={faSignInAlt} className="animate-left-3 me-3 ms-2" />Sign in</Button>}
                                    </div>
                                    <div className="d-flex justify-content-center mb-0 mt-4">                                            
                                            <Card.Link as={Link} to={Routes.Registration.path} className="small text-end"><FontAwesomeIcon icon={faUser} /> New user? Sign up</Card.Link>
                                    </div>     
                                    {isPending && <Button variant="primary" type="submit" className="w-100" disabled >signing in...</Button>}
                                       
                                </Form>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
};

export default Signin;