import React from 'react';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const ModuleBreadcrumb = (props) => {

    const renderLinks = () => {
        if ((props.type !== "list") && (props.links)) {
            return (<>
                {props.links.map((linkObj, index) => (  
                    linkObj.linkto ?
                        <Breadcrumb.Item key={index} href={"/#" + linkObj.linkto} >{linkObj.label}</Breadcrumb.Item>                   
                    : 
                        <Breadcrumb.Item key={index} active >{linkObj.label}</Breadcrumb.Item>         
                ))}
            </>);
            
        } 
    }

    return (
        <> 
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item href="/#/" ><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>                        
                        {renderLinks()}
                    </Breadcrumb>                    
                </div>
            </div>
            
        </>
    );
}
 
export default ModuleBreadcrumb;