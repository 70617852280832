import React from "react";

import { Navbar,  Container } from '@themesberg/react-bootstrap';
import NavbarBrand from "./NavbarBrand";


const NavbarComponent = () => {
    
    return (

        <>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="position-fixed w-100 shadow" style={{top:0, zIndex:9}}>
            <Container>
                <NavbarBrand />

            </Container>
        </Navbar>
        
        </>
    );
};

export default NavbarComponent;